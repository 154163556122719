import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import theme from 'assets/themes/main.theme'
import Router from 'routes/Router'
import MainLoading from 'containers/MainLoading'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
      <MainLoading />
    </ThemeProvider>
  )
}

export default App
