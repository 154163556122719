import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import BaseModal from "components/modal/BaseModal";
import InlineSpaceText from "components/form/InlineSpaceText";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";

import { editMemberStatus } from "redux/actions/member";
import { selectMasterDataMemberStatus } from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .card-wrapper {
    text-align: center;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 10px;
    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
    .appove-panel {
      display: flex;
      align-items: stretch;
      column-gap: 10px;
    }
  }
  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const StyleCardWrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
`;
const PopupChangeStatusMember = ({ open, onClose, selectList }) => {
  const dispatch = useDispatch();
  const masterDataStatusMember = useSelector(selectMasterDataMemberStatus);

  const [statusChangeList, setStatusChangeList] = useState(
    selectList.map((it) => {
      return { member_id: it?.id, name: it?.name, status: it?.status };
    })
  );

  useEffect(() => {
    console.log("statusChangeList", statusChangeList);
  }, [statusChangeList]);

  const submitEditMember = () => {
    dispatch(
      editMemberStatus(
        statusChangeList.map((it) => {
          return { member_id: it?.member_id, status: it?.status };
        })
      )
    );
    onClose();
  };

  const onChangeForm = (index, status) => {
    let newArr = statusChangeList.map((it) => it);
    newArr[index] = {
      ...newArr[index],
      status: +status,
    };
    setStatusChangeList(newArr);
  };

  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <div className="form-wrapper">
          <div className="title">เปลี่ยนสถานะผู้ใช้งาน</div>
          {statusChangeList.map((item, index) => (
            <InlineSpaceText
              label={`${item?.name}`}
              key={`change-status:${item?.id}`}
            >
              <Select
                items={masterDataStatusMember}
                value={item?.status}
                onChange={(e) => onChangeForm(index, e.target.value)}
                className="field-status-member"
                id={`field-status-member-${item?.id}`}
                defaultValue="เลือกสถานะผู้ใช้งาน"
              />
            </InlineSpaceText>
          ))}
        </div>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Close{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitEditMember()}>
            Save
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupChangeStatusMember;
