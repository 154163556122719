import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

const SecureRoute = (props) => {
  const history = useHistory();
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    if (!accessToken) {
      history.replace("/login");
    }
  }, [accessToken, history]);

  return accessToken ? <Route {...props} /> : null;
};

export default SecureRoute;
