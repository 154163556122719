import React from "react";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";

import {
  approveDocumentMember,
  requestDocumentMember,
  getMemberById,
} from "redux/actions/member";

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .card-wrapper {
    text-align: center;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 10px;
    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
    .appove-panel {
      display: flex;
      align-items: stretch;
      column-gap: 10px;
    }
  }
  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const StyleCardWrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
`;

const PopupPreviewImage = ({ open, onClose, maxWidth = "xl", path }) => {
  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Preview Image</div>
          <StyleCardWrapper className="card-wrapper">
            <img src={path} />
          </StyleCardWrapper>
        </div>
        <div className="button-wrapper">
          <Button className="btn-close-preview" onClick={onClose} outlined>
            Close{" "}
          </Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupPreviewImage;
