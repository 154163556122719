import {
  ADD_MEMBER,
  GET_MEMBER_LIST,
  GET_MEMBER_BY_ID,
  APPROVE_DOCUMENT_MEMBER,
  REQUEST_DOCUMENT_MEMBER,
  ADD_SEGMENT,
  GET_SEGMENT_LIST,
  PATCH_SEGMENT,
  DELETE_SEGMENT_ITEM,
  ADD_USER_SEGMENT,
  GET_SEGMENT_BY_ID,
  DELETE_USER_SEGMENT_ITEM,
} from "redux/actionTypes";
import {} from "utils/common";

const mapMemberItems = (v) => ({
  id: v.member_id,
  firstName: v.first_name,
  lastName: v.last_name,
  email: v.email,
  phone: v.phone,
  nationality: v.nationality,
  idCard: v.id_card,
  bankAccount: v.bank_account,
  bankAccountNo: v.bank_account_no,
  bankAccountName: v.bank_account_name,
  memberType: v.member_type,
  memberStatus: v.member_status,
  memberStatusFormat: v.member_status_formatted,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
  approveStatusFormatted: v.approve_status_formatted,
});

const mapSegmentItems = (v) => ({
  id: v.segment_id,
  nameTh: v.name_th,
  tag: v.tag,
  status: v.status,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
  memberCount: v.memberCount,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  items: [],
  memberData: {},
  segmentData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_MEMBER.REQUEST:
      return { ...state };
    case ADD_MEMBER.SUCCESS:
      return { ...state };
    case ADD_MEMBER.FAILURE:
      return { ...state };
    case GET_MEMBER_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_MEMBER_LIST.SUCCESS: {
      const { total, limit, skip, rows } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: rows
          .sort((a, b) => b?.member_id - a?.member_id)
          .map(mapMemberItems),
      };
    }
    case GET_MEMBER_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_MEMBER_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_MEMBER_BY_ID.SUCCESS: {
      return {
        ...state,
        memberData: action.payload,
      };
    }
    case GET_MEMBER_BY_ID.FAILURE:
      return {
        ...state,
      };
    case APPROVE_DOCUMENT_MEMBER: {
      return {
        ...state,
      };
    }
    case REQUEST_DOCUMENT_MEMBER: {
      return {
        ...state,
      };
    }
    case ADD_SEGMENT.REQUEST:
      return { ...state };
    case ADD_SEGMENT.SUCCESS:
      return { ...state };
    case ADD_SEGMENT.FAILURE:
      return { ...state };
    case ADD_USER_SEGMENT.REQUEST:
      return { ...state };
    case ADD_USER_SEGMENT.SUCCESS:
      return { ...state };
    case ADD_USER_SEGMENT.FAILURE:
      return { ...state };
    case GET_SEGMENT_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_SEGMENT_LIST.SUCCESS: {
      const { total, limit, skip, rows } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: rows.map(mapSegmentItems),
      };
    }
    case GET_SEGMENT_LIST.FAILURE:
      return {
        ...state,
      };
    case PATCH_SEGMENT.REQUEST:
      return {
        ...state,
      };
    case PATCH_SEGMENT.SUCCESS:
      return {
        ...state,
      };
    case PATCH_SEGMENT.FAILURE:
      return {
        ...state,
      };
    case DELETE_SEGMENT_ITEM.SUCCESS: {
      return {
        ...state,
        items: state.items.filter((v) => v.id !== action.payload),
      };
    }
    case DELETE_USER_SEGMENT_ITEM.SUCCESS: {
      return {
        ...state,
        items: state.items.filter((v) => v.id !== action.payload),
      };
    }
    case GET_SEGMENT_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_SEGMENT_BY_ID.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        segmentData: data,
      };
    }
    case GET_SEGMENT_BY_ID.FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
