import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";
import Table from "components/table/Table";

const MOCK_DATA = [];

const Div = styled.div`
  p {
    margin-top: 0;
  }

  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 2.25em;
        font-weight: bold;
        color: #982436;
      }

      .group-subtitle {
        font-size: 2em;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        > a {
          color: #982436;
          text-decoration: none;
          width: auto;
          min-width: auto;
          margin-left: auto;
          font-size: 18px;
        }
        > a:hover {
          text-decoration: underline;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          height: 47px;
          margin: 0 10px;
        }
      }
    }
  }
`;

const Post_GL = () => {
  const dispatch = useDispatch();

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
    },
    {
      field: "a",
      headerName: "วันที่ชำระเงิน	",
    },
    {
      field: "b",
      headerName: "รหัส GL",
    },
    {
      field: "c",
      headerName: "ชื่อบัญชี GL",
    },
    {
      field: "d",
      headerName: "ชื่อประเภทรายการ",
    },
    {
      field: "e",
      headerName: "จำนวนรายการ",
    },
    {
      field: "f",
      headerName: "ราคาต่อหน่วย",
    },
    {
      field: "g",
      headerName: "จำนวนรวมรายได้",
    },
  ];
  return (
    <SidebarLayout title="Accouting Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-balance", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Post GL </div>
              <div className="group-subtitle">สรุปรายได้แบ่งตามประเภท GL</div>
              <div className="group-field">
                <TextField
                  label="วันที่ชำระเงิน"
                  type="date"
                  value={""}
                  placeholder="Start Date"
                />
                <TextField
                  label="ถึงวันที่"
                  type="date"
                  value={""}
                  placeholder="End Date"
                />

                <Button onClick={() => {}}>ค้นหา</Button>

                <Button onClick={() => {}} outlined>
                  Download CSV
                </Button>

                <a href="#">ข้อมูลรหัส GL</a>
              </div>
            </div>
          </div>
          <br />

          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={MOCK_DATA}
              // rowCount={total}
              // pageSize={limit}
              // page={page}
              // onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default Post_GL;
