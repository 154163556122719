import {
  CREATE_EXAM_CAMPAIGN,
  CREATE_REPORT_CONFIG,
  GET_CAMPAIGN_LIST,
  GET_DISC_RESULT_LIST,
  GET_EXAM_CAMPAIGN_BY_DATE,
  GET_EXAM_CAMPAIGN_LIST,
  GET_REPORT_CONFIG_BY_ID,
  GET_REPORT_CONFIG_LIST,
  GET_REPORT_CONFIG_LIST_DROPDOWN,
  PATCH_EXAM_STATUS,
  PATCH_REPORT_CONFIG,
  PATCH_EXAM_CAMPAIGN_STATUS,
  GET_REPORT_CSV,
  GET_REPORT_EXAM,
  GET_REPORT_1,
  GET_REPORT_EXTRA_1,
  GET_REPORT_2,
  GET_REPORT_EXTRA_2,
  GET_REPORT_3,
  GET_REPORT_EXTRA_3,
  GET_EXPORT_REPORT_1,
  GET_EXPORT_REPORT_EXTRA_1,
  GET_EXPORT_REPORT_2,
  GET_EXPORT_REPORT_EXTRA_2,
  GET_EXPORT_REPORT_3,
  GET_EXPORT_REPORT_EXTRA_3,
  GET_REPORT_EXAM_1,
  GET_EXPORT_REPORT_EXAM_1,
  GET_REPORT_EXAM_2,
  GET_EXPORT_REPORT_EXAM_2,
  GET_REPORT_EXAM_3,
  GET_EXPORT_REPORT_EXAM_3,
  GET_REPORT_EXAM_4,
  GET_EXPORT_REPORT_EXAM_4,
  GET_EXPORT_REPORT_EXAM
} from "redux/actionTypes";
import {} from "utils/common";

const mapExamCampaignItems = (v) => ({
  campaignUid: v.campaign_uid,
  campaignName: v.campaign_name,
  email: v.email,
  firstName: v.first_name,
  lastName: v.last_name,
  userUid: v.userUid,
  slug: v.slug,
  userExam: v.user_exam,
  totalExam: v.total_exam,
  campaignType: v.campaign_type,
  dateExam: v.date_exam,
  timeExam: v.time_exam,
  totalQuestionExam: v.total_question_exam,
  resultExam: v.result_exam,
  resultDisc: v.result_disc,
  compatibility: v.compatibility,
  pointPercentile: v.point_percentile,
  status: v.status,
  campaignStartDate: v.campaign_start_date,
  campaignEndDate: v.campaign_end_date,
  examUserStatusId: v.exam_user_status_id,
});

const mapDiscItems = (v) => ({
  id: v.disc_type,
  discCharacter: v.disc_character,
  discDescriptionMedium: v.disc_description_medium,
  discDescriptionFull: v.disc_description_full,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
});

const mapReportConfigItems = (v) => ({
  id: v.id,
  name: v.name,
  createdat: v.createdat,
  createdby: v.createdby,
  subjectCount: v.subject_count,
  specialSubject: v.special_subject,
});

const mapReportConfigDropdownItems = (v) => ({
  value: v.id,
  text: v.name,
});

const mapCampaignItems = (v) => ({
  id: v.id,
  slug: v.slug,
  campaignUid: v.campaign_uid,
  campaignName: v.campaign_name,
  campaignNameLowercase: v.campaign_name_lowercase,
  campaignStartDate: v.campaign_start_date,
  campaignEndDate: v.campaign_end_date,
  campaignStatus: v.campaign_status,
  campaignType: v.campaign_type,
  userExam: v.user_exam,
  campaignCreatedDate: v.campaign_created_date,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  items: [],
  discs: [],
  reportConfigData: {},
  reportConfigsDropdown: [],

  reportList: [],
  exportReportResponse: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_EXAM_CAMPAIGN.REQUEST:
      return { ...state };
    case CREATE_EXAM_CAMPAIGN.SUCCESS:
      return { ...state };
    case CREATE_EXAM_CAMPAIGN.FAILURE:
      return { ...state };
    case GET_EXAM_CAMPAIGN_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapExamCampaignItems),
      };
    }
    case GET_EXAM_CAMPAIGN_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_CAMPAIGN_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_CAMPAIGN_BY_DATE.SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_EXAM_CAMPAIGN_BY_DATE.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_CAMPAIGN_BY_DATE.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_STATUS.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_EXAM_STATUS.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_STATUS.REQUEST:
      return {
        ...state,
      };
    case GET_DISC_RESULT_LIST.SUCCESS: {
      return {
        ...state,
        discs: action.payload.map(mapDiscItems),
      };
    }
    case GET_DISC_RESULT_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_DISC_RESULT_LIST.REQUEST:
      return {
        ...state,
      };
    case CREATE_REPORT_CONFIG.REQUEST:
      return { ...state };
    case CREATE_REPORT_CONFIG.SUCCESS:
      return { ...state };
    case CREATE_REPORT_CONFIG.FAILURE:
      return { ...state };
    case GET_REPORT_CONFIG_LIST.SUCCESS: {
      const { data, limit, skip, total } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapReportConfigItems),
      };
    }
    case GET_REPORT_CONFIG_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_REPORT_CONFIG_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_REPORT_CONFIG_BY_ID.SUCCESS: {
      return {
        ...state,
        reportConfigData: action.payload,
      };
    }
    case GET_REPORT_CONFIG_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_REPORT_CONFIG_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_REPORT_CONFIG.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_REPORT_CONFIG.FAILURE:
      return {
        ...state,
      };
    case GET_CAMPAIGN_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_CAMPAIGN_LIST.SUCCESS: {
      const { data, limit, skip, total } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapCampaignItems),
      };
    }
    case GET_CAMPAIGN_LIST.FAILURE:
      return {
        ...state,
      };
    case PATCH_REPORT_CONFIG.REQUEST:
      return {
        ...state,
      };
    case GET_REPORT_CONFIG_LIST_DROPDOWN.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        reportConfigsDropdown: data.map(mapReportConfigDropdownItems),
      };
    }
    case GET_REPORT_CONFIG_LIST_DROPDOWN.FAILURE:
      return {
        ...state,
      };
    case GET_REPORT_CONFIG_LIST_DROPDOWN.REQUEST:
      return {
        ...state,
      };
    case PATCH_EXAM_CAMPAIGN_STATUS.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_EXAM_CAMPAIGN_STATUS.FAILURE:
      return {
        ...state,
      };
    case PATCH_EXAM_CAMPAIGN_STATUS.REQUEST:
      return {
        ...state,
      };

    case GET_REPORT_CSV:
      return {
        ...state,
      };

    case GET_REPORT_EXAM.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_1.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXTRA_1.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_2.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXTRA_2.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_3.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXTRA_3.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXAM_1.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXAM_2.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXAM_3.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case GET_REPORT_EXAM_4.SUCCESS: {
      return {
        ...state,
        reportList: action.payload,
      };
    }

    case GET_EXPORT_REPORT_1.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXTRA_1.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_2.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXTRA_2.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_3.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXTRA_3.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }

    case GET_EXPORT_REPORT_EXAM_1.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXAM_2.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXAM_3.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXAM_4.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }
    case GET_EXPORT_REPORT_EXAM.SUCCESS: {
      return {
        ...state,
        exportReportResponse: action.payload,
      };
    }

    default:
      return state;
  }
};
