import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import TextFieldArea from "components/form/TextFieldArea";
import Grid from "@mui/material/Grid";

import Select from "components/form/Select";
import { addTicketStatus } from "redux/actions/callcenter";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
  .coe-sty-color {
    background: #a81e21 !important;
  }
`;
const statusList = [
  { value: "open", text: "เปิด" },
  { value: "inprogress", text: "ดำเนินการ" },
  { value: "inprogressnotify", text: "ดำเนินงานต้องติดตามและแจ้งผล" },
  { value: "close", text: "ปิด" },
];

const PopupTicketConfirmDelete = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  uid,
  userId,
  ticketId,
  popupTicketStatusID,
}) => {
  const dispatch = useDispatch();
  const [ticketStatus, setTicketStatus] = useState(false);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  // useEffect
  useEffect(() => {
    console.log(ticketId);
    console.log(popupTicketStatusID);
    setTicketStatus(popupTicketStatusID);
  }, [popupTicketStatusID]);

  const handleChangeTicketStatus = () => {
    console.log(ticketId);
    
    const obj = {
      ticket_id: ticketId,
      status: "delete",
      comment: 'delete tiket by user',
    };
    if (ticketStatus === "cancel" && validate()) {
      dispatch(addTicketStatus(obj)).then((res) => {
        const { payload } = res;
        if (payload) {
          console.log(payload);
          onClose();
        }
      });
    } else {
      dispatch(addTicketStatus(obj)).then((res) => {
        const { payload } = res;
        if (payload) {
          console.log(payload);
          onClose();
        }
      });
    }
  };

  const validate = (
    fieldValues = {
      description,
    }
  ) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกเหตุผล";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };
  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">ยืนยันการลบรายการ</div>
            
          </Grid>
        </Grid>
        
        <div className="button-wrapper">
          <Button onClick={onClose} outlined="true">
            ยกเลิก{" "}
          </Button>
          <ButtonBlack
            onClick={() => handleChangeTicketStatus()}
            className="coe-sty-color"
          >
            บันทึก
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupTicketConfirmDelete;
