import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import PopupExampleNotification from "./PopupExampleNotification";

import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

// import {} from "redux/actions/notification";
import TextField from "components/form/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextFieldArea from "components/form/TextFieldArea";

import { editNotification } from "../../redux/actions/notification";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 20px;
    }

    .question-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .example-notification {
    color: #1473e6;
    font-weight: bold;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .suggestion-notification {
    color: red;
    padding-top: 1.5rem;
  }
`;

const notificationTimeList = [
  { value: "immediately", text: "Immediately" },
  { value: "advanced", text: "Advanced" },
];

const channelList = [
  { value: "email", text: "Email" },
  { value: "sms", text: "SMS" },
];

const receiverList = [
  { value: null, text: "- Select Receiver Type -" },
  { value: 1, text: "บุคคล" },
  { value: 2, text: "นิติบุคคล" },
];

const PopupEditNotification = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  uid,
  userId,
  is_schedule = 1,
  filter,
}) => {
  const dispatch = useDispatch();
  const { conditions, notificationInfo } = useSelector(
    (state) => state.notification
  );
  const [form, setForm] = useState(notificationInfo);
  const [notificationTime, setnotificationTime] = useState();
  const [conditionList, setConditionList] = useState();
  const [exampleModal, setExampleModal] = useState(false);

  useEffect(() => {
    let date = "";
    let time = "";
    if (notificationInfo.start_date) {
      const dateArr = notificationInfo.start_date.split(" ");
      date = dateArr[0];
      time = dateArr[1].substring(0, 5);
    }

    setForm({
      ...notificationInfo,
      notification_time: time,
      start_date: date,
      member_type: notificationInfo.member_type === "personal" ? 1 : 2,
    });
    setnotificationTime(notificationInfo.notification_type);
  }, [notificationInfo]);

  useEffect(() => {
    if (conditions.length > 0) {
      const condition = conditions.map((v) => ({
        value: v.id,
        text: v.name,
      }));
      setConditionList(condition);
    }
  }, [conditions]);

  const handleConfirm = () => {
    let request;
    if (is_schedule === 1) {
      request =
        notificationTime === "advanced"
          ? {
              ...form,
              start_date: `${form.start_date}T${form.notification_time}`,
            }
          : { ...form, start_date: "" };
    } else {
      request = {
        ...form,
        member_type:
          form.member_type.toString() === "1" ? "personal" : "corporation",
      };
    }

    const filters = { ...filter, is_schedule };
    dispatch(editNotification(request, filters));
    onClose();
  };

  const handleFromChange = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleChangeNotificationTime = (value) => {
    setnotificationTime(value);
    setForm({
      ...form,
      notification_type: value,
    });
  };

  const openExample = () => {
    setExampleModal(true);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        {exampleModal && (
          <PopupExampleNotification
            open={open}
            onClose={() => setExampleModal(false)}
            channel={form.channel}
            form={form}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">
              {/* {is_schedule === 1 ? "Preview" : "Edit"} */}
              แก้ไข
            </div>
            <br />
            <div className="question-from">
              <div className="question-group">
                <div className="question-title">ชื่อการแจ้งเตือน</div>
                <div className="question-item">
                  <TextField
                    disabled
                    value={form.name}
                    onChange={(e) => handleFromChange("name", e.target.value)}
                  />
                </div>
              </div>

              {is_schedule === 1 ? (
                <>
                  <div className="question-group">
                    <div className="question-title">เงื่อนไข</div>
                    <div className="question-item">
                      <Select
                        value={form.condition_id}
                        items={conditionList}
                        onChange={(e) =>
                          handleFromChange("condition_id", e.target.value)
                        }
                        defaultValue={"- Select Condition -"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">รูปแบบการแจ้งเตือน</div>
                    <div className="question-item">
                      <Select
                        value={form.notification_type}
                        items={notificationTimeList}
                        onChange={(e) =>
                          handleChangeNotificationTime(e.target.value)
                        }
                        defaultValue={"- Select Type -"}
                        disabled
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="question-group">
                    <div className="question-title">Receiver Type</div>
                    <div className="question-item">
                      <Select
                        value={form.member_type}
                        items={receiverList}
                        onChange={(e) =>
                          handleFromChange("member_type", e.target.value)
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="- Select Channel -"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </>
              )}

              {notificationTime === "advanced" && (
                <div>
                  <div className="question-group">
                    <div className="question-title">เวลาในการส่ง</div>
                    <div className="question-item">
                      <TextField
                        value={form.notification_time}
                        type="time"
                        onChange={(e) =>
                          handleFromChange("notification_time", e.target.value)
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">วันที่เริ่มส่ง</div>
                    <div className="question-item">
                      <TextField
                        value={form.start_date}
                        type="date"
                        onChange={(e) =>
                          handleFromChange("start_date", e.target.value)
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="- Select Channel -"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </div>
              )}
              {notificationTime === "immediately" && (
                <div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="- Select Channel -"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </div>
              )}

              {is_schedule === 1 && (
                <div className="example-notification" onClick={openExample}>
                  ดูตัวอย่างการส่ง
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {/* {is_schedule === 1 ? (
          <>
            <div className="button-wrapper">
              <Button onClick={onClose}>Close</Button>
            </div>
          </>
        ) : (
          <>
            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                Cancel{" "}
              </Button>
              <Button onClick={() => handleConfirm()}>Save</Button>
            </div>
          </>
        )} */}
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก{" "}
          </Button>
          <Button onClick={() => handleConfirm()}>บันทึก</Button>
        </div>
        {/* {is_schedule === 1 && (
          <div className="suggestion-notification">
            หากต้องการแก้ไขข้อมูลกรุณาติดต่อแอดมิน
          </div>
        )} */}
      </Div>
    </BaseModal>
  );
};

export default PopupEditNotification;
