import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { checkValue } from "utils/common";
import RouterLinkCell from "components/table/RouterLinkCell";

import InlineSpaceText from "components/form/InlineSpaceText";
import Button from "components/form/Button";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import Paper from "components/surfaces/Paper";

import DemoUser from "../../assets/images/no-image.png";
import Table from "components/table/Table";
import PopupCreateTicketCallCenter from "containers/popup/PopupCreateTicketCallCenter";
import {
  getMemberInfoById,
  getCorporateInfoById,
  getAllTickets,
} from "redux/actions/callcenter";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn {
    color: #fff;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
  }

  .question-img {
    max-width: 350px;
    padding: 10px 0;
    img {
      width: 100%;
    }
  }
  .question-item {
    display: flex;
    margin: 15px;
    .question-title {
      width: 200px;
      margin-left: 10px;
    }
    .question-detail {
      width: 100%;
    }
  }

  .image-profile-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
  }
`;

const CustomerDetail = () => {
  const dispatch = useDispatch();
  const { memberInfoByMemNo, corporateInfoByMemNo } = useSelector(
    (state) => state.callcenter
  );
  const { total, tickets } = useSelector((state) => state.callcenter);
  const { id } = useParams();
  const { search } = useLocation();
  const [page, setPage] = useState(0);
  const [memberData, setMemberData] = useState(false);
  const [dataList, setDataList] = useState(false);
  const [corporateLicenseList, setCorporateLicenseList] = useState(false);
  const history = useHistory();
  const [isMember, setIsMember] = useState(false);

  const [isOpenPopupCreateTicket, setIsOpenPopupCreateTicket] = useState(false);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/call-center/ticket/detail/${row?.id}`}>
        {value}
      </RouterLinkCell>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const formatBuddhistYear = ({ value }, format = "DD/MM/YYYY") => {
    let momentDate = moment(value);
    var christianYear = momentDate.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();

    return momentDate
      .format(
        format
          .replace("YYYY", buddhishYear)
          .replace("YY", buddhishYear.substring(2, 4))
      )
      .replace(christianYear, buddhishYear);
  };

  const columnsMember = [
    {
      field: "id",
      headerName: "ลำดับ",
    },
    {
      field: "ticket_no",
      headerName: "เลขที่รายการ",
      renderCell: LinkDetailCell,
    },
    {
      field: "ticket_type",
      headerName: "หัวข้อ",
      width: 250,
    },
    {
      field: "full_name_create_by",
      headerName: "สร้างรายการโดย",
      width: 150,
    },
    { field: "description", headerName: "เนื่อหา", width: 250 },
    {
      field: "first_name",
      headerName: "ชื่อจริง",
      renderCell: IsNullValue,
    },
    {
      field: "last_name",
      headerName: "นามสกุล",
      renderCell: IsNullValue,
    },
    {
      field: "phone_no",
      headerName: "เบอร์โทร",
      width: 150,
    },
    {
      field: "ticket_date",
      headerName: "วันที่เปิดเรื่อง",
      width: 150,
      renderCell: formatBuddhistYear,
    },

    {
      field: "ticket_status_name",
      headerName: "สถานะ",
    },
  ];

  const columnsCorporate = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 80,
    },
    {
      field: "ticket_no",
      headerName: "เลขที่รายการ",
      renderCell: LinkDetailCell,
      width: 100,
    },
    {
      field: "ticket_type",
      headerName: "หัวข้อ",
      width: 300,
    },
    {
      field: "full_name_create_by",
      headerName: "บันทึกรายการโดย",
      width: 150,
    },
    { field: "description", headerName: "เนื่อหา", width: 300 },
    {
      field: "first_name",
      headerName: "ชื่อจริง",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "last_name",
      headerName: "นามสกุล",
      renderCell: IsNullValue,
      width: 80,
    },
    {
      field: "phone_no",
      headerName: "เบอร์โทร",
      width: 150,
    },
    {
      field: "ticket_date",
      headerName: "วันที่เปิดเรื่อง",
      renderCell: IsNullValue,
      width: 150,
    },

    {
      field: "ticket_status_name",
      headerName: "สถานะ",
      width: 100,
    },
  ];

  const columnsCorporateLicense = [
    {
      field: "request_id",
      headerName: "เลขที่ใบรับเรื่อง",
      width: 200,
    },
    {
      field: "request_date",
      headerName: "วันที่รับเรื่อง",
      width: 200,
    },
    {
      field: "corporate_license",
      headerName: "เลขทะเบียนนิติบุคคล",
      width: 200,
    },
    {
      field: "date_corporate_approve",
      headerName: "วันที่อนุมัติใบอนุญาต",
      width: 200,
    },
    {
      field: "date_corporate_expire",
      headerName: "วันที่หมดอายุใบอนุญาต",
      width: 200,
    },
  ];

  // useEffect
  useEffect(() => {
    const paramIsmember = new URLSearchParams(search).get("ismember");
    setIsMember(paramIsmember);

    requestMemberByMemno(id);
  }, []);

  useEffect(() => {
    setMemberData(memberInfoByMemNo);
  }, [memberInfoByMemNo]);

  useEffect(() => {
    setDataList(tickets);
  }, [tickets]);

  useEffect(() => {
    setCorporateLicenseList(corporateInfoByMemNo);
  }, [corporateInfoByMemNo]);

  // handle method
  const requestMemberByMemno = async () => {
    dispatch(getMemberInfoById(id));
    dispatch(getCorporateInfoById(id));
    setPage(0);
    dispatch(getAllTickets({ member_no: id }, page * 10));
  };

  const handleOpenPopup = () => {
    setIsOpenPopupCreateTicket(true);
  };
  const handleClosePopup = () => {
    handleSearchTicket();
    setIsOpenPopupCreateTicket(false);
  };

  const handleSearchTicket = () => {
    setPage(0);
    dispatch(getAllTickets({ member_no: id }, page * 10));
    // dispatch(getTickets({ member_no: id }, page * 10));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const hancleSearchParam = () => {
    const paramIsmember = new URLSearchParams(search).get("ismember");
    console.log("paramIsmember:", paramIsmember);
    return paramIsmember;
  };

  return (
    <SidebarLayout title="">
      {Boolean(isOpenPopupCreateTicket) && (
        <PopupCreateTicketCallCenter
          open
          onClose={() => handleClosePopup()}
          memberNo={id}
          isMemberRef={hancleSearchParam()}
        />
      )}

      <Div>
        <SubMenu
          menu={[
            { path: "/call-center/ticket/all", text: "รายการ" },
            { path: "/call-center/account", text: "รายชื่อ" },
          ]}
        />

        <div className="content-wrapper">
          {hancleSearchParam() == 1 ? (
            <Grid container spacing={2}>
              {/* <Grid item xs={12} className="headbar-panel-wrapper">
                <BackIcon
                  className="back-icon"
                  onClick={() => history.goBack()}
                />
                <span className="title">ข้อมูลสมาชิก</span>
              </Grid> */}

              {/* <Grid item xs={8}>
                <Paper>
                  <Grid item container>
                    <Grid item xs="4">
                      <div className="image-profile-panel">
                        <img width="200" src={DemoUser} alt="profile" />

                        <p>
                          <strong>
                            {checkValue(memberInfoByMemNo?.title_name_th_th)}
                            {checkValue(
                              memberInfoByMemNo?.first_name_th_th
                            )}{" "}
                            {checkValue(memberInfoByMemNo?.last_name_th_th)}
                          </strong>{" "}
                        </p>
                        <p>
                          {" "}
                          <small>
                            เลขที่สมาชิก :{" "}
                            {checkValue(memberInfoByMemNo?.member_no)}
                          </small>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs="8">
                      <Grid container spacing={1} item direction="column">
                        <Grid
                          item
                          spacing={1}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="headline"
                        >
                          <Grid item alignItems="center" container xs={9}>
                            ข้อมูลสมาชิก
                          </Grid>
                        </Grid>

                        <InlineSpaceText
                          className="fix-height"
                          label="ชื่อ-นามสกุล (EN)"
                        >
                          <span>
                            {checkValue(memberInfoByMemNo?.title_name_en_us)}
                            {checkValue(
                              memberInfoByMemNo?.first_name_en_us
                            )}{" "}
                            {checkValue(memberInfoByMemNo?.last_name_en_us)}
                          </span>
                        </InlineSpaceText>
                        <InlineSpaceText
                          className="fix-height"
                          label="วันที่เป็นสมาชิกครั้งแรก"
                        >
                          <span>
                            {checkValue(
                              memberInfoByMemNo?.member_registration_date
                            )}
                          </span>
                        </InlineSpaceText>
                        <InlineSpaceText
                          className="fix-height"
                          label="วันที่เริ่มใช้"
                        >
                          <span>
                            {checkValue(
                              memberInfoByMemNo?.member_effective_date
                            )}
                          </span>
                        </InlineSpaceText>
                        <InlineSpaceText
                          className="fix-height"
                          label="สถานะภาพสมาชิก"
                        >
                          <span>
                            {checkValue(memberInfoByMemNo?.member_status)}
                          </span>
                        </InlineSpaceText>
                        <InlineSpaceText
                          className="fix-height"
                          label="สถานะภาพบุลคล"
                        >
                          <span>
                            {checkValue(memberInfoByMemNo?.personal_status)}
                          </span>
                        </InlineSpaceText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        สถานะภาพสมาชิก
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      label="ประเภทสมาชิก"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.member_type_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="วันหมดอายุสมาชิก"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.member_expire_date)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={8}>
                <Paper>
                  <Grid container spacing={1} item direction="column">
                    <Grid
                      item
                      spacing={1}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item alignItems="center" container xs={9}>
                        ข้อมูลส่วนบุลคล
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      className="fix-height"
                      label="เลขบัตรประชาชน"
                    >
                      <span>{checkValue(memberInfoByMemNo?.id_card_no)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="สัญชาติ">
                      <span>
                        {checkValue(memberInfoByMemNo?.nationality_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="วันเกิด">
                      <span>{checkValue(memberInfoByMemNo?.birthday)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="อายุ">
                      <span>{checkValue(memberInfoByMemNo?.age)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="เพศ">
                      <span>
                        {checkValue(memberInfoByMemNo?.gender_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="Email">
                      <span>{checkValue(memberInfoByMemNo?.email)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="โทรศัพท์">
                      <span>{checkValue(memberInfoByMemNo?.phone_no)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText className="fix-height" label="ที่ทำงาน">
                      <span>{checkValue(memberInfoByMemNo?.company_name)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ที่อยู่ที่ทำงาน"
                    >
                      <span>{checkValue(memberInfoByMemNo?.work_address)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ที่อยู่ตามทะเบียนบ้าน"
                    >
                      <span>{checkValue(memberInfoByMemNo?.addresses)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ที่อยู่จัดส่งเอกสาร"
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.delivery_addresses)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        ข้อมูลคุณวุฒิ
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      label="สาขาที่ขอใบอนุญาต"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(
                          memberInfoByMemNo?.engineering_course_name_th_th
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="สถาบันที่จบการศึกษา"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.institute_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText label="คุณวุฒิ" className="fix-height ">
                      <span>
                        {checkValue(memberInfoByMemNo?.degree_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="สาขาที่เรียน"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.field_study_name_th_th)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="ปีที่เข้าศึกษา"
                      className="fix-height "
                    >
                      <span>{checkValue(memberInfoByMemNo?.joint_year)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="วันที่จบการศึกษา"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(memberInfoByMemNo?.graduated_date)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="รหัสนักศึกษา"
                      className="fix-height "
                    >
                      <span>{checkValue(memberInfoByMemNo?.student_no)}</span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid> */}
              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        <b>ประวัติการให้บริการของ</b>{" "}
                        {memberInfoByMemNo?.title_name_th_th}{" "}
                        {memberInfoByMemNo?.first_name_th_th}{" "}
                        {memberInfoByMemNo?.last_name_th_th} <b>เลขที่สมาชิก</b>{" "}
                        {memberInfoByMemNo?.member_no}
                      </Grid>

                      <Grid item xs={4} container justifyContent="flex-end">
                        {/* <Button
                          className="btn"
                          onClick={() => handleOpenPopup()}
                        >
                          บันทึกการให้บริการ
                        </Button> */}
                      </Grid>
                    </Grid>
                    <Table
                      columns={columnsMember}
                      rows={dataList}
                      rowCount={total}
                      pageSize={10}
                      page={page}
                      onPageChange={handlePageChange}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {/* <Grid item xs={12} className="headbar-panel-wrapper">
                <BackIcon
                  className="back-icon"
                  onClick={() => history.goBack()}
                />
                <span className="title">ข้อมูลสมาชิก</span>
              </Grid> */}

              {/* <Grid item xs={8}>
                <Paper>
                  <Grid container spacing={1} item direction="column">
                    <Grid
                      item
                      spacing={1}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item alignItems="center" container xs={9}>
                        <span>
                          {checkValue(corporateInfoByMemNo?.name_th_th)}
                        </span>
                      </Grid>
                    </Grid>

                    <InlineSpaceText
                      className="fix-height"
                      label="ประเภทนิติบุคคล"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.corporate_type_name)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="เลขทะเบียนนิติบุคคล"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.corporation_no)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="เงื่อนไขในการแนบเอกสาร"
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.attach_documents_service_type_name
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="หมายเลขโทรศัพท์นิติบุคคล (OTP)"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.member_status)}
                      </span>
                    </InlineSpaceText>

                    <InlineSpaceText className="fix-height" label="เว็บไซต์">
                      <span>{checkValue(corporateInfoByMemNo?.website)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="วัน/เดือน/ปี ที่จดทะเบียน"
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.display_register_date
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ทุนจดทะเบียน (บาท)"
                    >
                      <span>{checkValue(corporateInfoByMemNo?.com_fund)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="งานตามกฏกระทรวง"
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.work_type_under_ministry_name
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ประเภทธุรกิจ"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.business_type_name)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
              
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        สถานะ
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      label="เลขที่ใบอนุญาต"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.license_no)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="วันที่เริ่มใช้ใบอนุญาต"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.display_issued_date)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="วันที่หมดอายุใบอนุญาต"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.display_expire_date)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="จำนวนวันคงเหลือ"
                      className="fix-height "
                    >
                      <span>{"-"}</span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={8}>
                <Paper>
                  <Grid container spacing={1} item direction="column">
                    <Grid
                      item
                      spacing={1}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item alignItems="center" container xs={9}>
                        ที่อยู่
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      className="fix-height"
                      label="
                     ที่อยู่สำนักงานใหญ่"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.address_company)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ที่อยู่จัดส่งเอกสาร"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.address_document)}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      className="fix-height"
                      label="ที่อยู่ออกใบกํากับภาษี"
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.address_receive)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        ข้อมูลการติดต่อ
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      label="เบอร์โทรศัพท์"
                      className="fix-height "
                    >
                      <span>{checkValue(corporateInfoByMemNo?.phone_no)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText label="อีเมล์" className="fix-height ">
                      <span>{checkValue(corporateInfoByMemNo?.email)}</span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="ผู้ติดต่อประสานงาน"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.requestor_fullname)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid> */}

              {/* <Grid item xs={8}>
                <Paper>
                  <Grid container spacing={1} item direction="column">
                    <Grid
                      item
                      spacing={1}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item alignItems="center" container xs={9}>
                        ข้อมูลใบอนุญาต
                      </Grid>
                    </Grid>
                    <Table
                      columns={columnsCorporateLicense}
                      rows={corporateLicenseList}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        จำนวนพนักงาน
                      </Grid>
                    </Grid>
                    <InlineSpaceText
                      label="จำนวนวิศวกรที่มีใบอนุญาต"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.total_engineer_licenses
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="จำนวนพนักงานฝ่ายเทคนิค"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.total_technical_staff
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="จำนวนพนักงานในสายงานอื่นๆ"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(
                          corporateInfoByMemNo?.total_other_field_staff
                        )}
                      </span>
                    </InlineSpaceText>
                    <InlineSpaceText
                      label="จำนวนพนักงานฝ่ายอื่นๆ"
                      className="fix-height "
                    >
                      <span>
                        {checkValue(corporateInfoByMemNo?.total_other_staff)}
                      </span>
                    </InlineSpaceText>
                  </Grid>
                </Paper>
              </Grid> */}

              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={1} direction="column" item>
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="headline"
                    >
                      <Grid item xs={8}>
                        <b>ประวัติการให้บริการของ</b>{" "}
                        {corporateInfoByMemNo?.name_th_th}{" "}
                        <b>เลขที่นิติบุคคล</b>{" "}
                        {corporateInfoByMemNo?.corporation_no}
                      </Grid>

                      <Grid item xs={4} container justifyContent="flex-end">
                        <Button
                          className="btn"
                          onClick={() => handleOpenPopup()}
                        >
                          บันทึกการให้บริการ
                        </Button>
                      </Grid>
                    </Grid>
                    <Table
                      columns={columnsCorporate}
                      rows={dataList}
                      rowCount={total}
                      pageSize={10}
                      page={page}
                      onPageChange={handlePageChange}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default CustomerDetail;
