import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextFieldArea from "components/form/TextFieldArea";
import Button from "components/form/Button";
import Radio from "components/form/Radio";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import masterData from "redux/reducers/masterData";

import PopupPreviewImage from "containers/popup/PopupPreviewImage";

import {
  approveDocumentMember,
  requestDocumentMember,
  getMemberById,
} from "redux/actions/member";
import member from "redux/reducers/member";

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 10px;
    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
    .appove-panel {
      display: flex;
      align-items: stretch;
      column-gap: 10px;
    }
    .approve-status-bar {
      position: relative;
      top: -15px;
      left: -15px;
    }
    .preview-image {
      :hover {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const StyleCardWrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
`;

const PopupApproveDocument = ({ open, onClose, maxWidth = "xl" }) => {
  const dispatch = useDispatch();
  const { memberData } = useSelector((state) => state.member);

  const [idCardApproveStatus, setIdCardApproveStatus] = useState();
  const [bankAccountApproveStatus, setBankAccountApproveStatus] = useState();
  const [approveStatusDoc, setApproveStatusDoc] = useState();
  const [comment, setComment] = useState("");
  const [idCardImgUrl, setIdCardImgUrl] = useState("");
  const [idBankAccountImgUrl, setIdBankAccountImgUrl] = useState("");

  const [isOpenIdCardModal, setIsOpenIdCardModal] = useState(false);
  const [isOpenBankAccountModal, setIsOpenBankAccountModal] = useState(false);

  const DisplayMemberStatus = (value) => {
    if (value === 1) {
      return "Active";
    } else if (value === 2) {
      return "Inacive";
    } else if (value === 3) {
      return "Draft";
    }
  };

  useEffect(() => {
    if (memberData?.verify_document) {
      setIdCardApproveStatus(
        memberData?.verify_document[0]?.verify_document_status
      );
      setBankAccountApproveStatus(
        memberData?.verify_document[1]?.verify_document_status
      );
    }
    setApproveStatusDoc(memberData?.approve_status);
    setComment(memberData?.document_comment);
  }, [memberData]);

  useEffect(() => {
    let autoStatusDoc;
    if (idCardApproveStatus && bankAccountApproveStatus) {
      if (
        idCardApproveStatus === bankAccountApproveStatus &&
        idCardApproveStatus === 1
      ) {
        autoStatusDoc = 3;
      } else if (
        idCardApproveStatus !== bankAccountApproveStatus &&
        (idCardApproveStatus === 1 || idCardApproveStatus === 2)
      ) {
        autoStatusDoc = 2;
      } else if (
        idCardApproveStatus === bankAccountApproveStatus &&
        idCardApproveStatus === 2
      ) {
        autoStatusDoc = 4;
      }
    }

    setApproveStatusDoc(autoStatusDoc);
  }, [idCardApproveStatus, bankAccountApproveStatus]);

  useEffect(() => {
    if (idCardImgUrl) {
      let obj = {
        member_id: memberData?.member_id,
        document_type: "สำเนาบัตรประชาชน",
        document_url: idCardImgUrl,
      };
      dispatch(requestDocumentMember(obj)).then(() =>
        dispatch(getMemberById(memberData?.member_id))
      );
    } else if (idBankAccountImgUrl) {
      let obj = {
        member_id: memberData?.member_id,
        document_type: "หน้าสมุดบัญชีธนาคาร",
        document_url: idBankAccountImgUrl,
      };
      dispatch(requestDocumentMember(obj)).then(() =>
        dispatch(getMemberById(memberData?.member_id))
      );
    }
  }, [idCardImgUrl, idBankAccountImgUrl]);

  const isVerifyDocumentNotPass = (index) => {
    if (memberData?.verify_document) {
      return memberData?.verify_document[index]?.verify_document_status === 2;
    }
    return false;
  };

  const handlePreviewIdCardImage = () => {
    setIsOpenIdCardModal(true);
  };

  const handlePreviewBankAccountImage = () => {
    setIsOpenBankAccountModal(true);
  };

  const handleCloseIdCardModal = () => {
    setIsOpenIdCardModal(false);
  };

  const handleCloseBankAccountModal = () => {
    setIsOpenBankAccountModal(false);
  };

  const submitApproveMember = () => {
    let obj = {
      member_id: memberData?.member_id,
      approve_status: approveStatusDoc,
      id_card_verify_document_id: memberData?.verify_document[0].id,
      id_card_approve_status: idCardApproveStatus,
      bank_account_verify_document_id: memberData?.verify_document[1].id,
      bank_account_approve_status: bankAccountApproveStatus,
      document_comment: comment,
    };

    dispatch(approveDocumentMember(obj));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Approve Document Member</div>
          <StyleCardWrapper className="card-wrapper">
            <InlineSpaceText
              label={`Name : ${memberData?.first_name} ${memberData?.last_name}`}
            ></InlineSpaceText>
            <InlineSpaceText label={`Member Id : ${memberData?.member_id}`}>
              สถานะ : {DisplayMemberStatus(memberData?.member_status)}
            </InlineSpaceText>
            <InlineSpaceText label={`Email : ${memberData?.email}`}>
              Create Date/Time : {memberData?.createdAt}
            </InlineSpaceText>
          </StyleCardWrapper>
          <div className="appove-panel ">
            <StyleCardWrapper className="card-wrapper">
              <p className="form-topic">เอกสารการอัพโหลด</p>
              <InlineSpaceText label="รายการ">ผลการตรวจเอกสาร</InlineSpaceText>
              <InlineSpaceText
                label={
                  <div
                    className="preview-image"
                    onClick={handlePreviewIdCardImage}
                  >{`สำเนาบัตรประชาชน`}</div>
                }
                onClick={handlePreviewIdCardImage}
              >
                {Boolean(isOpenIdCardModal) && (
                  <PopupPreviewImage
                    open
                    path={memberData?.verify_document[0].document_url}
                    onClose={handleCloseIdCardModal}
                  ></PopupPreviewImage>
                )}
                <Radio
                  label="ผ่าน"
                  id="id_card_pass"
                  name={"id_card"}
                  checked={idCardApproveStatus === 1}
                  onChange={() => setIdCardApproveStatus(1)}
                />
                <Radio
                  label="ไม่ผ่าน"
                  id="id_card_nopass"
                  name={"id_card"}
                  checked={idCardApproveStatus === 2}
                  onChange={() => setIdCardApproveStatus(2)}
                />
              </InlineSpaceText>
              {isVerifyDocumentNotPass(0) && (
                <UploadImage
                  label={"อัพโหลดสำเนาบัตรประชาชน"}
                  inputID={"id_card_img_url"}
                  setURLImage={setIdCardImgUrl}
                />
              )}

              <InlineSpaceText
                label={
                  <div
                    className="preview-image"
                    onClick={handlePreviewBankAccountImage}
                  >{`หน้าสมุดบัญชีธนาคาร`}</div>
                }
              >
                {Boolean(isOpenBankAccountModal) && (
                  <PopupPreviewImage
                    open
                    path={memberData?.verify_document[1].document_url}
                    onClose={handleCloseBankAccountModal}
                  ></PopupPreviewImage>
                )}
                <Radio
                  label="ผ่าน"
                  id="bank_account_pass"
                  name={"bank_account"}
                  checked={bankAccountApproveStatus === 1}
                  onChange={() => setBankAccountApproveStatus(1)}
                />
                <Radio
                  label="ไม่ผ่าน"
                  id="bank_account_nopass"
                  name={"bank_account"}
                  checked={bankAccountApproveStatus === 2}
                  onChange={() => setBankAccountApproveStatus(2)}
                />
              </InlineSpaceText>

              {isVerifyDocumentNotPass(1) && (
                <UploadImage
                  inputID={"bank_account_img_url"}
                  label={"อัพโหลดรูปสมุดบัญชีธนาคาร"}
                  setURLImage={setIdBankAccountImgUrl}
                />
              )}
            </StyleCardWrapper>
            <StyleCardWrapper className="card-wrapper">
              <p className="form-topic">ผลการตรวขเอกสารการสมัคร</p>
              <div>
                <Radio
                  label="ผ่าน"
                  id="status_document_pass"
                  name={"status_document"}
                  checked={approveStatusDoc === 3}
                  onChange={() => setApproveStatusDoc(3)}
                />
                <Radio
                  label="ไม่ผ่าน"
                  id="status_document_nopass"
                  name={"status_document"}
                  checked={approveStatusDoc === 4}
                  onChange={() => setApproveStatusDoc(4)}
                />
                <Radio
                  label="ขอเอกสารเพิ่มเติม"
                  id="status_document_more_file"
                  name={"status_document"}
                  checked={approveStatusDoc === 2}
                  onChange={() => setApproveStatusDoc(2)}
                />
              </div>
              <TextFieldArea
                label="คำอธิบายเพิ่มเติม"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </StyleCardWrapper>
          </div>
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Close{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitApproveMember()}>
            Save
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupApproveDocument;
