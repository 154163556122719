import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SidebarLayout from "components/layout/SidebarLayout";

import {
  getMasterDataProvince,
  getMasterDataDistrict,
  getMasterDataSubDistrict,
  getMasterDataBank,
  getMasterDataNationality,
  getMasterDataSubject,
  getMasterDataJobs,
  getMasterDataCOESubReport,
  getMasterDataCOEReport,
} from "redux/actions/masterData";

const Div = styled.div`
  > .title {
    font-family: "kelson_sansbold";
    font-size: 32px;
    text-align: center;
  }

  > .item-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1146px;
    margin: 33px auto 10px;

    a {
      width: 360px;
      height: 360px;
      padding: 60px 38px 10px;
      margin: 11px;
      border-radius: 10px;
      background: #fff;
      color: #080808;
      text-decoration: none;
      text-align: center;

      .icon {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        border-radius: 50%;
        background: #f7f9fa;
      }

      .item-name {
        margin-top: 37px;
        font-size: 20px;
        font-weight: bold;
      }

      .item-desc {
        margin-top: 21px;
        font-size: 16px;
        color: #b6b8c1;
      }
    }
  }
`;

const Home = () => {
  const accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMasterData();
  }, []);

  const fetchMasterData = useCallback(() => {
    if (accessToken) {
      dispatch(getMasterDataProvince());
      dispatch(getMasterDataDistrict());
      dispatch(getMasterDataSubDistrict());
      dispatch(getMasterDataBank());
      dispatch(getMasterDataNationality());
      dispatch(getMasterDataSubject());
      dispatch(getMasterDataJobs());
      dispatch(getMasterDataCOEReport());
      dispatch(getMasterDataCOESubReport());
    }
  }, [accessToken, dispatch]);

  return (
    <SidebarLayout title="Fusion Tech Centre">
      {/* <SidebarLayout title="Fusion Tech Centre" hideMenu> */}
      <Div>
        <div className="title">Fusion Tech Centre</div>
        <div className="item-wrapper">
          <Link to="/member">
            <div className="icon" />
            <div className="item-name">Member Management</div>
            <div className="item-desc">
              Collect and manage Member information safely and confidentially
            </div>
          </Link>
          <Link to="/order">
            <div className="icon" />
            <div className="item-name">Order Management</div>
            <div className="item-desc">
              Collect and manage Member information safely and confidentially
            </div>
          </Link>
          <Link to="/report">
            <div className="icon" />
            <div className="item-name">Report Management</div>
            <div className="item-desc">
              Collect and manage Member information safely and confidentially
            </div>
          </Link>
          <Link to="/call-center">
            <div className="icon" />
            <div className="item-name">Call Center Management</div>
            <div className="item-desc">Contract customers</div>
          </Link>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default Home;
