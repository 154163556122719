import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import formatValue from "utils/formatValue";
import Grid from "@mui/material/Grid";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import Select from "components/form/Select";
import Table from "components/table/Table";
import TextField from "components/form/TextField";

import {
  getUsersNotification,
  setUsersNotificationSelected,
} from "../../redux/actions/notification";

const Div = styled.div`
  padding: 20px;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 20px;

    .button-toggle-panel {
      display: flex;
      column-gap: 10px;
      align-items: center;

      > button {
        min-width: 150px;
      }
      &.end {
        justify-content: flex-end;
      }
    }
    .filter-panel {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
      .row {
        display: flex;
        column-gap: 10px;
        align-items: flex-end;
        .select {
          width: 20%;
        }
        > button {
          min-width: 150px;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .limit-dropdown {
    max-width: 100px;
  }
`;

const initFilter = {
  member_type_name: "",
  member_status: "",
  member_no: "",
  id_card_no: "",
  first_name: "",
  last_name: "",
  license: "",
  type_date: "",
  start_date: "",
  end_date: "",
  corporation_name: "",
  corporate_type_id: "",
};

const memberStatusList = [
  { value: "ปกติ", text: "ปกติ" },
  { value: "หมดอายุ", text: "หมดอายุ" },
  { value: "ตลอดชีพ", text: "ตลอดชีพ" },
  { value: "ยกเลิก", text: "ยกเลิก" },
];
const typeDateList = [
  { value: "1", text: "วันที่สมัครสมาชิก" },
  { value: "2", text: "วันที่เริ่มสถานะ" },
  { value: "3", text: "วันที่หมดอายุ" },
];
const limitList = [
  { value: 10, text: "10" },
  { value: 20, text: "20" },
  { value: 50, text: "50" },
  { value: 100, text: "100" },
];

function PopupAddUser({
  open,
  onCloseChildren,
  addUsers,
  notificationId,
  memberType,
}) {
  const dispatch = useDispatch();
  const {
    totalUsers,
    limitUsers,
    usersNotification,
    memberTypes,
    corporateTypes,
    usersNotificationSelected,
  } = useSelector((state) => state.notification);
  const [memberTypeList, setMemberTypeList] = useState();
  const [corporateTypeList, setCorporateTypeList] = useState();
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [limit, setLimit] = useState(limitUsers);

  const columnPersonal = [
    {
      field: "no",
      headerName: "ลำดับที่",
      width: "200",
    },
    {
      field: "member_no",
      headerName: "เลขที่สมาชิก",
      width: "200",
    },
    {
      field: "license",
      headerName: "เลขทะเบียนใบอนุญาต",
      width: "200",
    },
    {
      field: "id_card_no",
      headerName: "เลขบัตรประจำตัวประชาชน",
      width: "200",
    },
    {
      field: "title_name",
      headerName: "คำนำหน้า",
      width: "100",
    },
    {
      field: "first_name_th_th",
      headerName: "ชื่อ",
      width: "200",
    },
    {
      field: "last_name_th_th",
      headerName: "นามสกุล",
      width: "200",
    },
    {
      field: "phone_no",
      headerName: "เบอร์โทรศัพท์",
      width: "200",
    },
    {
      field: "email",
      headerName: "อีเมล์",
      width: "400",
    },
    {
      field: "member_type_name",
      headerName: "ประเภทสมาชิก",
      width: "200",
    },
    {
      field: "member_status",
      headerName: "สถานะภาพสมาชิก",
      width: "200",
    },
    // {
    //   field: "created_at",
    //   headerName: "วันสมัครสมาชิก",
    //   width: "200",
    //   valueGetter: ({ value }) => formatValue("DATE", value),
    // },
    // {
    //   field: "effective_date",
    //   headerName: "วันที่เริ่มสถานะ",
    //   width: "200",
    //   valueGetter: ({ value }) => formatValue("DATE", value),
    // },
    // {
    //   field: "expire_date",
    //   headerName: "วันหมดอายุสมาชิก",
    //   valueGetter: ({ value }) => formatValue("DATE", value),
    //   width: "200",
    // },
    // {
    //   field: "licenses_count",
    //   headerName: "จำนวนใบอนุญาติ",
    //   width: "200",
    // },
  ];

  const columnCorporation = [
    {
      field: "no",
      headerName: "ลำดับที่",
      width: "200",
    },
    {
      field: "license",
      headerName: "เลขที่ทะเบียนนิติบุคคล",
      width: "200",
    },
    {
      field: "name_th_th",
      headerName: "ชื่อบริษัท",
      width: "200",
    },
    {
      field: "corporate_type",
      headerName: "ประเภทบริษัท",
      width: "200",
    },
    {
      field: "business_type",
      headerName: "ประเภทธุรกิจ",
      width: "200",
    },
    {
      field: "requestor_email",
      headerName: "อีเมล์",
      width: "200",
    },
    {
      field: "requestor_phone_no",
      headerName: "เบอร์โทรศัพท์",
      width: "200",
    },
    {
      field: "requestor_name",
      headerName: "ผู้ติดต่อประสานงาน",
      width: "200",
    },
  ];

  useEffect(() => {
    dispatch(getUsersNotification(notificationId, page * limit, limit, filter));
  }, [limit]);

  useEffect(() => {
    dispatch(getUsersNotification(notificationId, page * limit, limit, filter));
  }, [notificationId]);

  useEffect(() => {
    if (memberTypes.length > 0) {
      const member_types = memberTypes.map((v) => ({
        value: v.name_th_th,
        text: v.name_th_th,
      }));
      setMemberTypeList(member_types);
    }
  }, [memberTypes]);

  useEffect(() => {
    if (corporateTypes?.length > 0) {
      const corporate_types = corporateTypes.map((v) => ({
        value: v.id,
        text: v.name_th_th,
      }));
      setCorporateTypeList(corporate_types);
    }
  }, [corporateTypes]);

  useEffect(() => {
    const selectedId = selected.map((v) => parseInt(v?.split("-")[0]));
    const allUserSelected = [
      ...new Set([...usersNotificationSelected, ...selectedId]),
    ];
    const usersNotificationId = userList.map((v) => v.id2);
    const idsSelected = allUserSelected.filter(
      (v) => usersNotificationId.includes(v) && selectedId.includes(v)
    );
    const idsPrev = allUserSelected.filter(
      (v) => !usersNotificationId.includes(v)
    );
    setSelectedCount(idsSelected.length + idsPrev.length);
  }, [usersNotificationSelected, selected]);

  useEffect(() => {
    const newList = usersNotification.map((v) => ({
      ...v,
      id: `${v.id}-${v.license}`,
      id2: v.id,
    }));
    setUserList(newList);
  }, [usersNotification]);

  useEffect(() => {
    if (usersNotificationSelected.length > 0) {
      const allUserSelected = [...usersNotificationSelected];
      const usersNotificationId = userList.map((v) => v.id2);
      const idsSelected = allUserSelected.filter((v) =>
        usersNotificationId.includes(v)
      );
      const idSelected2 = userList
        .filter((v) => idsSelected.includes(v.id2))
        .map((v) => v.id);
      setSelected(idSelected2);
    }
  }, [userList]);

  const add = () => {
    const selectedUsers = latestUserSelected();
    updatedUserSelected();
    addUsers(selectedUsers);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getUsersNotification(notificationId, 0 * limit, limit, filter));
  };

  const handleClearFilter = () => {
    setPage(0);
    setFilter(initFilter);
    dispatch(
      getUsersNotification(notificationId, 0 * limit, limit, initFilter)
    );
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    updatedUserSelected();
    setPage(nextPage);
    dispatch(
      getUsersNotification(notificationId, nextPage * limit, limit, filter)
    );
  };

  const handleChangeLimit = (event) => {
    const { value } = event.target;
    setLimit(value);
  };

  const updatedUserSelected = () => {
    const selectedId = selected.map((v) => parseInt(v?.split("-")[0]));
    const allUserSelected = [
      ...new Set([...usersNotificationSelected, ...selectedId]),
    ];
    const usersNotificationId = userList.map((v) => v.id2);
    const idsSelected = allUserSelected.filter(
      (v) => usersNotificationId.includes(v) && selectedId.includes(v)
    );
    const idsPrev = allUserSelected.filter(
      (v) => !usersNotificationId.includes(v)
    );
    const newUserSelected = [...idsPrev, ...idsSelected];
    dispatch(setUsersNotificationSelected(newUserSelected));
  };

  const latestUserSelected = () => {
    const selectedId = selected.map((v) => parseInt(v?.split("-")[0]));
    const allUserSelected = [
      ...new Set([...usersNotificationSelected, ...selectedId]),
    ];
    const usersNotificationId = userList.map((v) => v.id2);
    const idsSelected = allUserSelected.filter(
      (v) => usersNotificationId.includes(v) && selectedId.includes(v)
    );
    const idsPrev = allUserSelected.filter(
      (v) => !usersNotificationId.includes(v)
    );
    const newUserSelected = [...idsPrev, ...idsSelected];
    return newUserSelected;
  };

  return (
    <BaseModal open={open} onClose={onCloseChildren} maxWidth={"xl"} fullWidth>
      <Div>
        <div className="title">เพิ่มสมาชิก</div>
        <div className="content-wrapper">
          {memberType === "personal" ? (
            <div className="filter-panel">
              <div className="row ">
                <div className="select">
                  <Select
                    label="กรอกข้อมูล"
                    defaultValue={"ประเภทสมาชิก"}
                    items={memberTypeList}
                    value={filter.member_type_name}
                    onChange={(e) =>
                      handleFilterChange("member_type_name", e.target.value)
                    }
                    name="member_type_name"
                  />
                </div>
                <div className="select">
                  <Select
                    defaultValue={"สถานะภาพสมาชิก"}
                    items={memberStatusList}
                    value={filter.member_status}
                    onChange={(e) =>
                      handleFilterChange("member_status", e.target.value)
                    }
                    name="member_status"
                  />
                </div>
              </div>
              <div className="row">
                <div className="select">
                  <TextField
                    label="ค้นหาด้วยข้อมูลสมาชิก"
                    placeholder={"Search เลขที่สมาชิก"}
                    value={filter.member_no}
                    onChange={(e) =>
                      handleFilterChange("member_no", e.target.value)
                    }
                    name="member_no"
                  />
                </div>
                <div className="select">
                  <TextField
                    placeholder={"Search เลขบัตรประจำตัวประชาชน"}
                    value={filter.id_card_no}
                    onChange={(e) =>
                      handleFilterChange("id_card_no", e.target.value)
                    }
                    name="id_card_no"
                  />
                </div>
                <div className="select">
                  <TextField
                    placeholder={"Search ชื่อ"}
                    value={filter.first_name}
                    onChange={(e) =>
                      handleFilterChange("first_name", e.target.value)
                    }
                    name="first_name"
                  />
                </div>
                <div className="select">
                  <TextField
                    placeholder={"Search นามสกุล"}
                    value={filter.last_name}
                    onChange={(e) =>
                      handleFilterChange("last_name", e.target.value)
                    }
                    name="last_name"
                  />
                </div>
                <div className="select">
                  <TextField
                    placeholder={"Search เลขทะเบียนใบอนุญาต"}
                    value={filter.license}
                    onChange={(e) =>
                      handleFilterChange("license", e.target.value)
                    }
                    name="license"
                  />
                </div>
              </div>
              <div className="row">
                <div className="select">
                  <Select
                    label="เลือกช่วงเวลา ตั้งแต่วันที่-วันที่"
                    defaultValue={"ประเภทวันที่"}
                    items={typeDateList}
                    value={filter.type_date}
                    onChange={(e) =>
                      handleFilterChange("type_date", e.target.value)
                    }
                    name="type_date"
                  />
                </div>

                <div className="select">
                  <TextField
                    type="date"
                    placeholder="วว/ดด/ปปปป"
                    value={filter.start_date}
                    onChange={(e) =>
                      handleFilterChange("start_date", e.target.value)
                    }
                    name="start_date"
                  />
                </div>
                <div className="select">
                  <TextField
                    type="date"
                    placeholder="วว/ดด/ปปปป"
                    value={filter.end_date}
                    onChange={(e) =>
                      handleFilterChange("end_date", e.target.value)
                    }
                    name="end_date"
                  />
                </div>

                <Button onClick={() => handleSearch()}>ค้นหาข้อมูล</Button>
                <Button onClick={() => handleClearFilter()} outlined>
                  ล้างการค้นหา
                </Button>
              </div>
            </div>
          ) : (
            <div className="filter-panel">
              <div className="row ">
                <div className="select">
                  <Select
                    label="กรอกข้อมูล"
                    defaultValue={"ประเภทนิติบุคคล"}
                    items={corporateTypeList}
                    value={filter.corporate_type_id}
                    onChange={(e) =>
                      handleFilterChange("corporate_type_id", e.target.value)
                    }
                    name="corporate_type_id"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="select">
                  <TextField
                    label="ค้นหาด้วยข้อมูลนิติบุคคล"
                    placeholder={"Search ชื่อบริษัท"}
                    value={filter.corporation_name}
                    onChange={(e) =>
                      handleFilterChange("corporation_name", e.target.value)
                    }
                    name="corporation_name"
                  />
                </div>
                <div className="select">
                  <TextField
                    placeholder={"Search เลขทะเบียนนิติบุคคล"}
                    value={filter.license}
                    onChange={(e) =>
                      handleFilterChange("license", e.target.value)
                    }
                    name="license"
                  />
                </div>
                <Button onClick={() => handleSearch()}>ค้นหาข้อมูล</Button>
                <Button onClick={() => handleClearFilter()} outlined>
                  ล้างการค้นหา
                </Button>
              </div>
            </div>
          )}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h3>เลือกแล้ว {selectedCount} รายการ</h3>
            </Grid>
            <Grid item>
              <Select
                className="limit-dropdown"
                items={limitList}
                value={limit}
                onChange={handleChangeLimit}
                defaultValue={""}
              />
            </Grid>
          </Grid>

          {memberType === "personal" ? (
            <Table
              columns={columnPersonal}
              rows={userList}
              rowCount={totalUsers}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              onSelectionChange={(newUserSelected) => {
                setSelected(newUserSelected);
              }}
              disableSelectionOnClick={true}
              checkboxSelection
              selectionModel={selected}
            />
          ) : (
            <Table
              columns={columnCorporation}
              rows={userList}
              rowCount={totalUsers}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              onSelectionChange={(newUserSelected) => {
                setSelected(newUserSelected);
              }}
              disableSelectionOnClick={true}
              checkboxSelection
              selectionModel={selected}
            />
          )}
        </div>
        <div className="button-wrapper">
          <Button onClick={add}>เพิ่ม</Button>
          <Button onClick={onCloseChildren} outlined>
            ปิด
          </Button>
        </div>
      </Div>
    </BaseModal>
  );
}

export default PopupAddUser;
