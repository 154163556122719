import React from "react";
import styled from "styled-components";
import { Paper as PaperMui } from "@mui/material";

const StylePaper = styled(PaperMui)`
  padding: 14px 16px;
  height: 100%;
`;

const Paper = ({ className, children, ...props }) => {
  return <StylePaper className={className}>{children}</StylePaper>;
};

export default Paper;
