import React from "react";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

const Div = styled.div`
  .welcome-wrapper {
    margin-top: 29px;
    height: 870px;
    padding: 228px 120px;
    font-family: "kelson_sansbold";
    background: #fff;
    border-radius: 8px;

    .sub-title {
      font-size: 32px;
      color: #080808;
    }

    .title {
      font-size: 56px;
      color: #a81e21;
      margin-top: 36px;
    }
  }
`;

const AccountingWelcome = () => {
  return (
    <SidebarLayout title="Order Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-payment", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="welcome-wrapper">
          <div className="sub-title">Welcome to</div>
          <div className="title">Accouting Management</div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default AccountingWelcome;
