import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { logout } from 'redux/actions/auth'

const Logout = () => {
  const history = useHistory()

  useEffect(() => {
    logout()
    history.replace('/login')
  }, [history])

  return null
}

export default Logout
