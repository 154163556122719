import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";

import StepBar from "components/form/StepBar";
import TextField from "components/form/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextEditor from "components/form/TextEditor";
import TextFieldArea from "components/form/TextFieldArea";
import InlineSpaceText from "components/form/InlineSpaceText";
import Table from "components/table/Table";

import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import { addExamQuestion } from "redux/actions/library";
import PopupRandomExamADT from "./PopupRandomExamADT";
import PopupRandomExamShuffleOrNormal from "./PopupRandomExamShuffleOrNormal";
import PopupAddQuestion from "./PopupAddQuestion";

const Div = styled.div`
  padding: 20px;
  width: 1400px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
          margin-right: 16px;
        }

        > * {
          width: 250px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .invilid {
      color: red;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .score-container {
    margin-top: 15px;
  }

  .summary-score-container {
    display: flex;
    width: 100%;
    align-items: center;

    .summary-score-title {
      width: 33%;
    }

    .summary-score-box {
      margin: 5px 0;
      display: flex;
      align-items: center;

      .header {
        font-size: 14px;
        font-weight: bold;
        width: 55%;
        margin-right: 8px;
      }
      .score {
        width: 46%;
        border-right: 1px solid black;
        padding-right: 8px;
        margin-right: 8px;
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .button-table {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 10px;
    .btn {
      margin-right: 15px;
    }
  }
`;

const conditionList = [
  { value: "eq", text: "=" },
  { value: "gt", text: ">" },
  { value: "gte", text: ">=" },
  { value: "lt", text: "<" },
  { value: "lteq", text: "<=" },
];

const modList = [
  { value: "adt", text: "ADT" },
  { value: "shuffle", text: "SHUFFLE" },
  { value: "normal", text: "NORMAL" },
];

const scoreList = [
  { value: -5, text: "-5" },
  { value: -4, text: "-4" },
  { value: -3, text: "-3" },
  { value: -2, text: "-2" },
  { value: -1, text: "-1" },
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
];

const columns = [
  {
    field: "id",
    headerName: "ลำดับที่",
    width: 250,
  },
  {
    field: "code",
    headerName: "รหัสข้อสอบ",
    width: 300,
  },
  {
    field: "question",
    headerName: "โจทย์",
    width: 250,
  },
  {
    field: "nameUpper",
    headerName: "หมวดวิชา",
    width: 250,
  },
  {
    field: "topicName",
    headerName: "หัวข้อ",
    width: 250,
  },
  {
    field: "level",
    headerName: "Center Level",
    width: 250,
  },
  {
    field: "-",
    headerName: "My Level",
    width: 250,
  },
  {
    field: "examOwner",
    headerName: "ชื่อผู้ออกข้อสอบ",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "วันที่สร้างข้อสอบ",
    width: 250,
  },
  { field: "phone", headerName: "เบอร์โทร", width: 250 },
];

const steps = ["กรอกข้อมูลข้อสอบ", "สร้างโจทย์"];
const topicSubjectList = [{ value: null, text: "เลือกหัวข้อสอบ" }];
const difficultyLevelList = [
  { value: null, text: "ระดับความยาก" },
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];
const numberAnswersList = [{ value: null, text: "จำนวนคำตอบ" }];
const positionList = [{ value: null, text: "งานตำแหน่ง" }];

const PopupCreateExamSet = ({ open, onClose, maxWidth = "xl", invoiceId }) => {
  const dispatch = useDispatch();
  const { randomExams } = useSelector((state) => state.library);

  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);
  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [dataJobs, setDataJobs] = useState(masterDataJob);

  const [examMode, setExamMode] = useState("adt");
  const [isRandom, setIsRandom] = useState(false);
  const [isOpenRandomADTPopup, setIsOpenRandomADTPopup] = useState(false);
  const [
    isOpenRandomShuffleOrNormalPopup,
    setIsOpenRandomShuffleOrNormalPopup,
  ] = useState(false);
  const [isOpenPopupAddQuestion, setIsOpenPopupAddQuestion] = useState(false);

  const [subjectId, setSubjectId] = useState("");
  const [topicName, setTopicName] = useState("");
  const [examOwner, setExamOwner] = useState("");
  const [level, setLevel] = useState("");
  const [amountAnswer, setAmountAnswer] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");

  const [dataRandomExams, setDataRandomExams] = useState(randomExams);

  useEffect(() => {
    setDataRandomExams(randomExams);
  }, [randomExams]);

  const handleSetExamMode = (value) => {
    setExamMode(value);
  };

  const handleOpenPopupRandomADT = () => {
    setIsOpenRandomADTPopup(true);
  };

  const handleClosePopupRandomADT = () => {
    setIsOpenRandomADTPopup(false);
  };

  const handleOpenPopupRandomShuffleOrNormal = () => {
    setIsOpenRandomShuffleOrNormalPopup(true);
  };

  const handleClosePopupRandomShuffleOrNormal = () => {
    setIsOpenRandomShuffleOrNormalPopup(false);
  };

  const handleOpenPopupAddQuestion = () => {
    setIsOpenPopupAddQuestion(true);
  };

  const handleClosePopupAddQuestion = () => {
    setIsOpenPopupAddQuestion(false);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      {examMode === "adt"
        ? Boolean(isOpenRandomADTPopup) && (
            <PopupRandomExamADT
              open
              onClose={() => handleClosePopupRandomADT()}
            />
          )
        : Boolean(isOpenRandomShuffleOrNormalPopup) && (
            <PopupRandomExamShuffleOrNormal
              open
              onClose={() => handleClosePopupRandomShuffleOrNormal()}
              randomType={examMode}
            />
          )}
      {Boolean(isOpenPopupAddQuestion) && (
        <PopupAddQuestion open onClose={() => handleClosePopupAddQuestion()} />
      )}
      <Div>
        <div className="form-wrapper">
          <div className="title">สร้างชุดข้อสอบ</div>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <h2>กรอกรายละเอียด</h2>
              <div className="form-wrapper">
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      value={topicName}
                      onChange={(e) => setTopicName(e.target.value)}
                      label="หัวข้อสอบ"
                      name="search_examer"
                      required
                    />
                    <TextField
                      value={topicName}
                      onChange={(e) => setTopicName(e.target.value)}
                      label="หัวข้อสอบ"
                      name="search_examer"
                      required
                    />
                  </div>
                  <div className="group-field">
                    <Select
                      label="วิชา"
                      items={dataSubject}
                      defaultValue="หมวดวิชา"
                      className="field-subjectId"
                    />
                  </div>
                  <div className="group-field">
                    <TextField
                      value={topicName}
                      onChange={(e) => setTopicName(e.target.value)}
                      label="จำนวนข้อ"
                      name="search_examer"
                      required
                    />
                  </div>
                  <div className="score-container">
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <TextField name="name" value={"ดี"} disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          spacing={1}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Select
                              name={"operation"}
                              items={conditionList}
                              defaultValue="Operation"
                              className="field-condition"
                            />
                          </Grid>
                          <Grid container item justifyContent="center" xs={6}>
                            <TextField name={"max_score"} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <TextFieldArea name={"description"} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="score-container">
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <TextField name="name" value={"ปานกลาง"} disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          spacing={1}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Select
                              name={"operation"}
                              items={conditionList}
                              defaultValue="Operation"
                              className="field-condition"
                            />
                          </Grid>
                          <Grid container item justifyContent="center" xs={6}>
                            <TextField name={"max_score"} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <TextFieldArea name={"description"} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="score-container">
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <TextField name="name" value={"แย่"} disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          spacing={1}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Select
                              name={"operation"}
                              items={conditionList}
                              defaultValue="Operation"
                              className="field-condition"
                            />
                          </Grid>
                          <Grid container item justifyContent="center" xs={6}>
                            <TextField name={"max_score"} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <TextFieldArea name={"description"} />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="form-wrapper">
                  <h2>Exam Mode</h2>
                  <div className="section-group">
                    <div className="group-field">
                      <Select
                        items={modList}
                        onChange={(e) => handleSetExamMode(e.target.value)}
                        defaultValue="หมวดวิชา"
                        className="field-subjectId"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <Grid item xs={12}>
                <h2>Summary</h2>
                <InlineSpaceText label="เวลารวมในการทำข้อสอบทั้งหมด 3 นาที">
                  <TextField className="field-firstName" />
                </InlineSpaceText>
                <p>จำนวนข้อสอบทั้งหมด : 2 ข้อ</p>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 1 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 2 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 3 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 4 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 5 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 6 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 7 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 8 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 9 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 10 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <div className="summary-score-container">
                  <div className="summary-score-title">
                    จำนวนข้อสอบ Level 11 : 10 ข้อ
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ตอบถูก Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>

                  <div className="summary-score-box">
                    <div className="header"> ตอบผิด Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                  <div className="summary-score-box">
                    <div className="header"> ไม่ตอบ Then </div>
                    <div className="score">
                      <Select
                        name={"score"}
                        items={scoreList}
                        defaultValue="Score"
                        className="field-score"
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <strong>
                    *Remark : ข้อที่ถูกกำหนดเวลาในการทำ
                    ผู้สอบจะไม่สามารถข้ามข้อสอบนั้น ๆ ได้
                  </strong>
                </p>
              </Grid>
              <Grid item xs={12}>
                <div className="table-wrapper">
                  <div className="form-wrapper">
                    <div className="section-group">
                      <div className="group-field">
                        <TextField
                          value={topicName}
                          onChange={(e) => setTopicName(e.target.value)}
                          label="หัวข้อสอบ"
                          name="search_examer"
                          required
                        />
                        <TextField
                          value={topicName}
                          onChange={(e) => setTopicName(e.target.value)}
                          label="หัวข้อสอบ"
                          name="search_examer"
                          required
                        />
                        <TextField
                          value={topicName}
                          onChange={(e) => setTopicName(e.target.value)}
                          label="หัวข้อสอบ"
                          name="search_examer"
                          required
                        />
                      </div>
                    </div>
                    <div className="section-group">
                      <div className="group-field end">
                        <Button>Select Client Library</Button>
                      </div>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    // rows={campaignList}
                    // rowCount={total}
                    // pageSize={+limit}
                    // page={page}
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    // onPageChange={handlePageChange}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="table-wrapper">
                <div className="button-table">
                  <div className="button-table-item">
                    <Button
                      className="btn"
                      onClick={(e) => handleOpenPopupAddQuestion()}
                    >
                      เพิ่มข้อสอบเข้าชุดข้อสอบ
                    </Button>
                    <Button
                      className="btn"
                      onClick={() => {
                        examMode === "adt"
                          ? handleOpenPopupRandomADT()
                          : handleOpenPopupRandomShuffleOrNormal();
                      }}
                    >
                      สุ่มข้อสอบเข้าชุดข้อสอบจาก Center Library
                    </Button>
                  </div>
                  <div className="button-table-item">
                    <Button>Ordering</Button>
                  </div>
                </div>
                <div className="table-wrapper">
                  <Table
                    columns={columns}
                    rows={dataRandomExams}
                    // rowCount={total}
                    // pageSize={+limit}
                    // page={page}
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    // onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <br />
      </Div>
    </BaseModal>
  );
};

export default PopupCreateExamSet;
