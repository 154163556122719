import React from "react";
import styled from "styled-components";
import { Stack as StackMui } from "@mui/material";
import Box from "../surfaces/Box";
const StyleStack = styled(StackMui)``;

const Stack = ({ children, classname, ...props }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <StyleStack className={classname} {...props}>
        {children}
      </StyleStack>
    </Box>
  );
};

export default Stack;
