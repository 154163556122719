import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";

import StepBar from "components/form/StepBar";
import TextField from "components/form/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextEditor from "components/form/TextEditor";
import TextFieldArea from "components/form/TextFieldArea";
import InlineSpaceText from "components/form/InlineSpaceText";
import Table from "components/table/Table";

import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import { addExamQuestion } from "redux/actions/library";
import PopupRandomExamADT from "./PopupRandomExamADT";
import PopupRandomExamShuffleOrNormal from "./PopupRandomExamShuffleOrNormal";
import PopupAddQuestion from "./PopupAddQuestion";

import ReactHtmlParser from "react-html-parser";

const Div = styled.div`
  padding: 20px;
  width: 1400px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
          margin-right: 16px;
        }

        > * {
          width: 250px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .invilid {
      color: red;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .score-container {
    margin-top: 15px;
  }

  .summary-score-container {
    display: flex;
    width: 100%;
    align-items: center;

    .summary-score-title {
      width: 33%;
    }

    .summary-score-box {
      margin: 5px 0;
      display: flex;
      align-items: center;

      .header {
        font-size: 14px;
        font-weight: bold;
        width: 55%;
        margin-right: 8px;
      }
      .score {
        width: 46%;
        border-right: 1px solid black;
        padding-right: 8px;
        margin-right: 8px;
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .button-table {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 10px;
    .btn {
      margin-right: 15px;
    }
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const difficultyLevelList = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];

const PopupOrderingExamSet = ({
  open,
  onClose,
  maxWidth = "xl",
  invoiceId,
  dataRandomExams,
  setDataRandomExams,
  setDataRandomExamsTime,
  setSummaryExamTime,
  summaryExamTime,
}) => {
  const dispatch = useDispatch();

  const [randonExamArr, setRandomExamArr] = useState(dataRandomExams);
  console.log(dataRandomExams);
  const [data, setData] = useState({
    rows: [],
    totalRows: 0,
    pageSize: 10,
    page: 0,
  });

  const handleSummaryExamTime = (row, value) => {
    let newArr = [...randonExamArr];
    let indexArr = newArr.findIndex((it) => it.id === row?.id);
    newArr[indexArr].question_time = +value;
    setRandomExamArr(newArr);
  };

  const ConvertHtmlToString = ({ value }) => {
    if (value === "") {
      return "-";
    }
    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };

  const TimeTextFeild = ({ row }) => {
    return (
      <TextField
        type="number"
        value={row?.question_time}
        onChange={(e) => handleSummaryExamTime(row, e.target.value)}
      />
    );
  };

  const handleMyLevelChange = (value, id) => {
    let newArr = [...randonExamArr];
    let indexArr = newArr.findIndex((it) => it.id === id);
    newArr[indexArr].mylevel = +value;
    setRandomExamArr(newArr);
  };

  const DisplayMyLevel = ({ row }) => {
    return (
      <Select
        items={difficultyLevelList}
        value={row?.mylevel || row?.level}
        defaultValue="Level"
        className="field_level"
        onChange={(e) => handleMyLevelChange(e.target.value, row?.id)}
      />
    );
  };

  const submitOrdering = () => {
    var newArr = [...randonExamArr];
    let sum = newArr
      .map((item) => item.question_time)
      .reduce((partialSum, a) => partialSum + a, 0);

    setSummaryExamTime(sum);
    setDataRandomExams(randonExamArr);
    setDataRandomExamsTime(randonExamArr);
    onClose();
  };

  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

  useEffect(() => {
    setTimeout(() => {
      const rows = dataRandomExams?.slice(
        data.page * data.pageSize,
        data.page * data.pageSize + data.pageSize
      );

      updateData("rowCount", dataRandomExams?.length);
      console.log("rows", rows);

      setTimeout(() => {
        updateData("rows", rows);
      }, 100);
    }, 500);
  }, [data.page, data.pageSize]);

  const columns = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 250,
    },
    {
      field: "question_time",
      headerName: "เวลา",
      width: 100,
      renderCell: TimeTextFeild,
    },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      width: 250,
    },
    {
      field: "question",
      headerName: "โจทย์",
      width: 500,
      renderCell: ConvertHtmlToString,
    },
    {
      field: "nameUpper",
      headerName: "หมวดวิชา",
      width: 250,
    },
    {
      field: "topicName",
      headerName: "หัวข้อ",
      width: 250,
    },
    {
      field: "level",
      headerName: "Center Level",
      width: 250,
    },
    {
      field: "-",
      headerName: "My Level",
      width: 250,
      renderCell: DisplayMyLevel,
    },
    {
      field: "examOwner",
      headerName: "ชื่อผู้ออกข้อสอบ",
      width: 250,
    },
    { field: "createdAt", headerName: "วันที่สร้างข้อสอบ", width: 250 },
  ];

  useEffect(() => {
    let newArr = [...dataRandomExams];

    const sumTime = newArr
      .map((it) => it.question_time)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (+summaryExamTime > 0 && +sumTime !== +summaryExamTime) {
      let avg = summaryExamTime / dataRandomExams.length;
      console.log(avg);

      const isFloat = avg % 1 !== 0;

      newArr = newArr.map((item) => ({
        ...item,
        question_time: Math.floor(avg),
      }));

      if (isFloat) newArr[0].question_time += 1;
    }

    setRandomExamArr(newArr);
  }, []);

  useEffect(() => {
    setRandomExamArr(dataRandomExams);
    const rows = dataRandomExams?.slice(
      data.page * data.pageSize,
      data.page * data.pageSize + data.pageSize
    );

    updateData("rowCount", dataRandomExams?.length);

    setTimeout(() => {
      updateData("rows", rows);
    }, 100);
  }, [dataRandomExams]);

  const handlePageChange = (nextPage) => {
    updateData("page", nextPage);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="table-wrapper">
                <Table
                  columns={columns}
                  rows={data?.rows}
                  rowCount={randonExamArr?.length}
                  disableSelectionOnClick={true}
                  checkboxSelection={false}
                  onPageChange={handlePageChange}
                />
              </div>
              <div className="button-wrapper">
                <Button onClick={onClose} outlined>
                  Cancel{" "}
                </Button>
                <Button type="submit" onClick={() => submitOrdering()}>
                  ยืนยัน
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>

        <br />
      </Div>
    </BaseModal>
  );
};

export default PopupOrderingExamSet;
