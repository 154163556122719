import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

// import {} from "redux/actions/notification";
import TextField from "components/form/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextFieldArea from "components/form/TextFieldArea";

import { createNotification } from "../../redux/actions/notification";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 20px;
    }

    .question-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
`;

const initForm = {
  name: "",
  condition_id: "",
  notification_type: "",
  channel: "",
  message: "",
  extra_condition: "",
  notification_time: "",
  start_date: "",
  is_schedule: 1,
  created_by: "",
  updated_by: "",
  member_type: "",
};

const notificationTimeList = [
  { value: "immediately", text: "Immediately" },
  { value: "advanced", text: "Advanced" },
];

const channelList = [
  { value: "email", text: "Email" },
  { value: "sms", text: "SMS" },
];

const receiverList = [
  { value: 1, text: "บุคคล" },
  { value: 2, text: "นิติบุคคล" },
];

const PopupCreateNotification = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  uid,
  userId,
  is_schedule = 1,
}) => {
  const dispatch = useDispatch();
  const { conditions } = useSelector((state) => state.notification);
  const [form, setForm] = useState(initForm);
  const [notificationTime, setnotificationTime] = useState();
  const [conditionList, setConditionList] = useState();

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      is_schedule,
    }));
  }, []);

  useEffect(() => {
    if (conditions.length > 0) {
      const condition = conditions.map((v) => ({
        value: v.id,
        text: v.name,
      }));
      setConditionList(condition);
    }
  }, [conditions]);

  const handleConfirm = () => {
    let request;
    if (is_schedule === 1) {
      request =
        notificationTime === "advanced"
          ? {
              ...form,
              start_date: `${form.start_date}T${form.notification_time}`,
            }
          : { ...form, start_date: "" };
    } else {
      request = {
        name: form.name,
        channel: form.channel,
        message: form.message,
        extra_condition: form.extra_condition,
        start_date: form.start_date,
        is_schedule: form.is_schedule,
        created_by: form.created_by,
        updated_by: form.updated_by,
        notification_type: null,
        member_type: form.member_type === "1" ? "personal" : "corporation",
      };
    }

    const filter = {
      is_schedule,
    };
    dispatch(createNotification(request, filter));
    onClose();
  };

  const handleFromChange = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleChangeNotificationTime = (value) => {
    setnotificationTime(value);
    setForm({
      ...form,
      notification_type: value,
    });
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">สร้างการแจ้งเตือน</div>
            <br />
            <div className="question-from">
              <div className="question-group">
                <div className="question-title">ชื่อการแจ้งเตือน</div>
                <div className="question-item">
                  <TextField
                    value={form.name}
                    onChange={(e) => handleFromChange("name", e.target.value)}
                  />
                </div>
              </div>
              {is_schedule === 1 ? (
                <>
                  <div className="question-group">
                    <div className="question-title">Condition</div>
                    <div className="question-item">
                      <Select
                        value={form.condition_id}
                        items={conditionList}
                        onChange={(e) =>
                          handleFromChange("condition_id", e.target.value)
                        }
                        defaultValue={"- Select Condition -"}
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">Notification Time</div>
                    <div className="question-item">
                      <Select
                        value={form.notification_type}
                        items={notificationTimeList}
                        onChange={(e) =>
                          handleChangeNotificationTime(e.target.value)
                        }
                        defaultValue={"- Select Type -"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="question-group">
                    <div className="question-title">
                      รูปแบบผู้รับการแจ้งเตือน
                    </div>
                    <div className="question-item">
                      <Select
                        value={form.member_type}
                        items={receiverList}
                        onChange={(e) =>
                          handleFromChange("member_type", e.target.value)
                        }
                        defaultValue="เลือกรูปแบบผู้รับการแจ้งเตือน"
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="เลือกช่องทาง"
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </>
              )}

              {notificationTime === "advanced" && (
                <div>
                  <div className="question-group">
                    <div className="question-title">Send Time</div>
                    <div className="question-item">
                      <TextField
                        value={form.notification_time}
                        type="time"
                        onChange={(e) =>
                          handleFromChange("notification_time", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">Start Date</div>
                    <div className="question-item">
                      <TextField
                        value={form.start_date}
                        type="date"
                        onChange={(e) =>
                          handleFromChange("start_date", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="เลือกช่องทาง"
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </div>
              )}
              {notificationTime === "immediately" && (
                <div>
                  <div className="question-group">
                    <div className="question-title">ช่องทาง</div>
                    <div className="question-item">
                      <Select
                        value={form.channel}
                        items={channelList}
                        onChange={(e) =>
                          handleFromChange("channel", e.target.value)
                        }
                        defaultValue="เลือกช่องทาง"
                      />
                    </div>
                  </div>
                  <div className="question-group">
                    <div className="question-title">ข้อความ</div>
                    <div className="question-item">
                      <TextFieldArea
                        value={form.message}
                        onChange={(e) =>
                          handleFromChange("message", e.target.value)
                        }
                        placeholder="ระบุข้อความ"
                        maxRows={6}
                        minRows={6}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก
          </Button>
          <Button onClick={() => handleConfirm()}>สร้าง</Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupCreateNotification;
