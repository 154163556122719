import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import FileCell from "components/table/FileCell";
import StatusCell from "components/table/StatusCell";
import CheckCell from "components/table/CheckCell";
import BaseModal from "components/modal/BaseModal";

import PopupChangeStatusMember from "../../containers/popup/PopupChangeStatusMember";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  getNotifications,
  getConditions,
  getMemberTypes,
  statusNotification,
  getNotificationById,
  getCorporateTypes,
  deleteNotification,
  sendImmediatelyNotification,
} from "redux/actions/notification";
import { IconButton, Menu, MenuItem, Switch } from "@mui/material";
import PopupCreateNotification from "containers/popup/PopupCreateNotification";
import PopupReceiverList from "containers/popup/PopupReceiverList";
import PopupEditNotification from "containers/popup/PopupEditNotification";
import PopupConfirmDeleteNotification from "containers/popup/PopupConfirmDeleteNotification";
import PopupConfirmSendEmail from "containers/popup/PopupConfirmSendEmail";
import PopupAlertReceiver from "containers/popup/PopupAlertReceiver";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    top: 793px;
    left: 1140px;
  }

  .btn-action {
    margin-right: 1rem;
  }
`;

const initFilter = {
  notification_name: "",
  notification_type: "",
  condition_id: "",
  status: "",
  is_schedule: 0,
};

const notificationTimeList = [
  { value: "immediately", text: "Immediately" },
  { value: "advanced", text: "Advanced" },
];

const statusList = [
  { value: "created", text: "สร้างแล้ว" },
  { value: "sent", text: "ส่งแล้ว" },
  // { value: "complete", text: "complete" },
];

const NotificationCustomAll = () => {
  const dispatch = useDispatch();
  const { total, limit, notifications, conditions } = useSelector(
    (state) => state.notification
  );

  const [customnNotifications, setCustomnNotifications] = useState([]);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [isOpenCreateNotificationModal, setIsOpenCreateNotificationModal] =
    useState(false);
  const [isOpenEditNotificationModal, setIsOpenEditNotificationModal] =
    useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isOpenConfirmSend, setIsOpenConfirmSend] = useState(false);
  const [isReceiver, setIsReceiver] = useState(false);
  const [isOpenPopupReceiverList, setIsOpenPopupReceiverList] = useState(false);
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [notificationId, setNotificationId] = useState();
  const [conditionList, setConditionList] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    requestNotifications();
    requestConditions();
    requestMemberTypes();
    requestCorparateTypes();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [isRefreshData]);

  useEffect(() => {
    if (notifications?.length > 0) {
      const notificationList = notifications.map((v) => ({
        id: v.id,
        no: v.no,
        name: v.name,
        condition: v.condition_name,
        channel: v.channel,
        notificationType: v.notification_type,
        status:
          v.status == "created"
            ? "สร้างแล้ว"
            : v.status == "sent"
            ? "ส่งแล้ว"
            : "",
        extra_condition: v.extra_condition,
        updated_at: v.updated_at,
        message: v.message,
      }));
      setCustomnNotifications(notificationList);
    } else {
      setCustomnNotifications([]);
    }
  }, [total, limit, notifications]);

  useEffect(() => {
    if (conditions.length > 0) {
      const condition = conditions.map((v) => ({
        value: v.id,
        text: v.name,
      }));
      setConditionList(condition);
    }
  }, [conditions]);

  const requestNotifications = async () => {
    dispatch(getNotifications(page * limit, 10, filter));
  };

  const requestConditions = async () => {
    dispatch(getConditions());
  };

  const requestMemberTypes = async () => {
    dispatch(getMemberTypes());
  };

  const requestCorparateTypes = async () => {
    dispatch(getCorporateTypes());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ViewReceiverList = ({ row }) => {
    return <Button onClick={(e) => handleOpenReceiverList(row.id)}>ดู</Button>;
  };

  const handleToggleStatus = async (value) => {
    const request = {
      notification_id: value.id,
      status: value.status ? "disable" : "active",
      page,
      limit,
    };

    dispatch(statusNotification(request, filter));
  };

  const ActionMenu = ({ row }) => {
    return (
      <div>
        <Button
          className="btn-action"
          onClick={() => handleSendNotification(row)}
        >
          ส่ง
        </Button>
        <Button
          className="btn-action"
          onClick={() => handleEditNotificationMember(row.id)}
        >
          แก้ไข
        </Button>
        <Button onClick={() => handleDelete(row.id)}>ลบ</Button>
      </div>
    );
  };

  const handleOpenReceiverList = (notification_id) => {
    setNotificationId(notification_id);
    setIsOpenPopupReceiverList(true);
  };

  const handleCloseReceiverList = () => {
    setIsOpenPopupReceiverList(false);
  };

  const columns = [
    {
      field: "no",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "name",
      headerName: "ชื่อรายการ",
      align: "left",
      width: 300,
    },
    { field: "channel", headerName: "ช่องทาง", width: 150 },
    {
      field: "receiverList",
      headerName: "ผู้ที่จะได้รับการแจ้งเตือน",
      renderCell: ViewReceiverList,
      width: 200,
    },
    {
      field: "action",
      headerName: "การทำงาน",
      renderCell: ActionMenu,
      width: 300,
    },
    {
      field: "updated_at",
      headerName: "แก้ไขครั้งล่าสุด",
      width: 300,
    },
    {
      field: "status",
      headerName: "สถานะ",
      width: 100,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getNotifications(page * limit, 10, initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getNotifications(nextPage * limit, 10, filter));
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getNotifications(page * limit, 10, filter));
  };

  const handleCloseCreateNotificationPopup = () => {
    setIsOpenCreateNotificationModal(false);
    setIsRefreshData(!isRefreshData);
    // handleSearch();
  };

  const handleCreatNotificationMember = () => {
    setIsOpenCreateNotificationModal(true);
  };

  const handleCloseEditeNotificationPopup = () => {
    setIsOpenEditNotificationModal(false);
    // handleSearch();
  };

  const handleCloseConfirmDeleteNotification = () => {
    setIsOpenConfirmDelete(false);
  };

  const handleCloseConfirmSend = () => {
    setIsOpenConfirmSend(false);
  };

  const handleCloseIsReceiverModal = () => {
    setIsReceiver(false);
  };

  const handleEditNotificationMember = (notification_id) => {
    dispatch(getNotificationById(notification_id));
    setIsOpenEditNotificationModal(true);
  };

  const handleSendNotification = (data) => {
    if (data.extra_condition === "") {
      setErrorMessage(
        "ท่านยังไม่เพิ่มรายชื่อผู้ที่ต้องการจะส่ง กรุณาเพิ่มรายชื่อก่อนทำการส่ง"
      );
      setIsReceiver(true);
    } else {
      if (data.message == "") {
        setErrorMessage("กรุณาเพิ่มข้อความก่อนส่ง");
        setIsReceiver(true);
      } else {
        setIsOpenConfirmSend(true);
        setNotificationId(data.id);
      }
    }
  };

  const handleDelete = (notification_id) => {
    setIsOpenConfirmDelete(true);
    setNotificationId(notification_id);
  };

  const confirmDelete = () => {
    const request = {
      notification_id: notificationId,
      page,
      limit,
    };
    dispatch(deleteNotification(request));
    setIsOpenConfirmDelete(false);
  };

  const confirmSend = () => {
    const request = {
      notification_id: notificationId,
      page,
      limit,
    };
    dispatch(sendImmediatelyNotification(request));
    setIsOpenConfirmSend(false);
  };

  return (
    <SidebarLayout title="Custom Notification Management">
      {isOpenCreateNotificationModal && (
        <PopupCreateNotification
          open
          onClose={handleCloseCreateNotificationPopup}
          is_schedule={0}
        />
      )}
      {isOpenEditNotificationModal && (
        <PopupEditNotification
          open
          onClose={handleCloseEditeNotificationPopup}
          is_schedule={0}
        />
      )}

      {isOpenPopupReceiverList && (
        <PopupReceiverList
          notificationId={notificationId}
          open
          onClose={handleCloseReceiverList}
          is_schedule={0}
        />
      )}

      {isOpenConfirmDelete && (
        <PopupConfirmDeleteNotification
          open
          onClose={handleCloseConfirmDeleteNotification}
          confirmDelete={confirmDelete}
        />
      )}
      {isOpenConfirmSend && (
        <PopupConfirmSendEmail
          open
          onClose={handleCloseConfirmSend}
          confirmSend={confirmSend}
        />
      )}
      {isReceiver && (
        <PopupAlertReceiver
          open
          onClose={handleCloseIsReceiverModal}
          message={errorMessage}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/notification/all", text: "Notification" },
            { path: "/notification/custom/all", text: "Custom Notification" },
            { path: "/notification/report", text: "Report" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">ค้นหา</div>
              <div className="group-field">
                <TextField
                  label="ค้นหาชื่อรายการ"
                  value={filter.notification_name}
                  onChange={(e) =>
                    handleFilterChange("notification_name", e.target.value)
                  }
                  name="notification_name"
                />
                {/* <Select
                  label="Notification Type"
                  items={notificationTimeList}
                  value={filter.notification_type}
                  onChange={(e) =>
                    handleFilterChange("notification_type", e.target.value)
                  }
                  name="notification_type"
                />
                <Select
                  label="Condition"
                  items={conditionList}
                  value={filter.condition_id}
                  onChange={(e) =>
                    handleFilterChange("condition_id", e.target.value)
                  }
                  name="condition_id"
                /> */}
                <Select
                  label="สถานะ"
                  items={statusList}
                  value={filter.status}
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                  name="status"
                />
              </div>
            </div>
            <div>
              {/* <div className="section-group ">
                <div className="group-field stretch">
                  <div className="box-massage">จำนวนการส่ง SMS 500 ข้อความ</div>
                  <div className="box-massage">จำนวนการส่ง Email 500 เมล</div>
                  <div className="box-massage">
                    จำนวนการส่ง Notification ทั้งหมด 1,000 ข้อความ
                  </div>
                </div>
              </div> */}
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>ค้นหา</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    ล้างการค้นหา
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    id="btn-add-member-individual"
                    onClick={handleCreatNotificationMember}
                  >
                    สร้างการแจ้งเตือน
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={customnNotifications}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default NotificationCustomAll;
