import {
  GET_TICKET_LIST,
  GET_TICKET_BY_ID,
  ADD_TICKET_STATUS,
  ADD_TICKET,
  EDIT_TICKET,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_BY_MEMNO,
  GET_TICKET_TYPE_LIST,
  GET_TICKET_HISTORY_LIST,
  GET_CORPORATE_INFO,
  GET_CORPORATE_INFO_BY_ID,
  DOWNLOAD_MEMBER_EXPIRED_CSV,
  DOWNLOAD_CORPORATE_EXPIRED_CSV,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

const mapFilter = (v) => ({
  member_no: v.member_no || null,
  phone_no: v.phone_no || null,
  name: v.name || null,
  start_date: v.start_date || null,
  end_date: v.end_date || null,
  ticket_no: v.ticket_no || null,
  ticket_type: v.ticket_type || null,
  ticket_status: v.ticket_status || null,
  license: v.license || null,
  ticket_owner_type: v.ticket_owner_type || null,
  description: v.description || null
});

const mapMemberInfoFilter = (v) => ({
  member_no: v.member_no || null,
  phone_no: v.phone_no || null,
  name: v.name || null,
  license: v.license || null,
});

const filterHistory = (v) => ({
  id: v.id || null,
});

export const getTickets =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    console.log("skip", skip);
    dispatch(showLoading());
    dispatch({ type: GET_TICKET_LIST.REQUEST });
    return getAPI({
      url: "callcenter/ticketList",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
        //first_name_create_by: filter?.first_name_create_by ?? null,
        //description: filter?.description ?? null,
        // "description[$like]": filter.description
        //   ? `%${filter.description}%`
        //   : null,
      },
    })
      .then((data) =>
        dispatch({ type: GET_TICKET_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_TICKET_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const createTicket = (ticket) => (dispatch) => {
  dispatch({ type: ADD_TICKET.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "callcenter/ticket",
    data: ticket,
  })
    .then((data) => dispatch({ type: ADD_TICKET.SUCCESS, payload: data?.id }))
    .catch((error) => dispatch({ type: ADD_TICKET.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getMemberInfos =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_MEMBER_INFO.REQUEST });
    return getAPI({
      url: "callcenter/memberStatusInfo",
      params: {
        first_name_th_th: filter?.first_name_th_th || null,
        last_name_th_th: filter?.last_name_th_th || null,
        id_card_no: filter?.id_card_no || null,
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        dispatch({ type: GET_MEMBER_INFO.SUCCESS, payload: data });
        console.log("getMemberInfos:", data);
        return data;
      })
      .catch((error) => dispatch({ type: GET_MEMBER_INFO.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getMemberInfoById = (id) => (dispatch) => {
  dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `callcenter/memberStatusInfo/${id}`,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.SUCCESS, payload: data });
    })
    .catch((error) =>
      dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getTicketInfoById = (id) => (dispatch) => {
  dispatch({ type: GET_TICKET_BY_ID.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `callcenter/ticketDetail/${id}`,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_TICKET_BY_ID.SUCCESS, payload: data });
      return data;
    })
    .catch((error) => dispatch({ type: GET_TICKET_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const addTicketStatus = (ticketStutus) => (dispatch) => {
  dispatch({ type: ADD_TICKET_STATUS.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "callcenter/changeTicketStatus",
    data: ticketStutus,
  })
    .then((data) =>
      dispatch({ type: ADD_TICKET_STATUS.SUCCESS, payload: data?.code })
    )
    .catch((error) => dispatch({ type: ADD_TICKET_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getAllTicketType = () => (dispatch, getState) => {
  //const { limit } = getState().order;
  dispatch(showLoading());
  dispatch({ type: GET_TICKET_TYPE_LIST.REQUEST });
  return getAPI({
    url: "callcenter/alltickettype",
    params: {},
  })
    .then((data) => {
      //console.log("data", data);
      dispatch({ type: GET_TICKET_TYPE_LIST.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_TICKET_TYPE_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const editTicketInfo = (ticketId, ticket) => (dispatch) => {
  dispatch({ type: EDIT_TICKET.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `callcenter/ticket/${ticketId}`,
    data: ticket,
  })
    .then((data) => dispatch({ type: EDIT_TICKET.SUCCESS }))
    .catch((error) => dispatch({ type: EDIT_TICKET.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getTicketsHistory =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_TICKET_HISTORY_LIST.REQUEST });
    return getAPI({
      url: "callcenter/ticketHistoryList",
      params: {
        ...filterHistory(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_TICKET_HISTORY_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_TICKET_HISTORY_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getCorporateInfos =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_CORPORATE_INFO.REQUEST });
    console.log("filter", filter);
    return getAPI({
      url: "callcenter/corporateStatusInfo",
      params: {
        member_no: filter?.member_no || null,
        name: filter?.name || null,
        license: filter?.license || null,
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        dispatch({ type: GET_CORPORATE_INFO.SUCCESS, payload: data });
        return data;
      })
      .catch((error) => dispatch({ type: GET_CORPORATE_INFO.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getCorporateInfoById = (id) => (dispatch) => {
  dispatch({ type: GET_CORPORATE_INFO_BY_ID.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `callcenter/corporateStatusInfo/${id}`,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_CORPORATE_INFO_BY_ID.SUCCESS, payload: data });
      return data;
    })
    .catch((error) =>
      dispatch({ type: GET_CORPORATE_INFO_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const removeDataCoporate = () => (dispatch) => {
  dispatch({ type: GET_CORPORATE_INFO.SUCCESS, payload: [] });
};

export const removeDataMember = () => (dispatch) => {
  dispatch({ type: GET_MEMBER_INFO.SUCCESS, payload: [] });
};

export const searchMemberInfos =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_MEMBER_INFO.REQUEST });
    return getAPI({
      url: "callcenter/searchMember",
      params: {
        ...mapMemberInfoFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        dispatch({ type: GET_MEMBER_INFO.SUCCESS, payload: data });
        console.log("getMemberInfos:", data);
        return data;
      })
      .catch((error) => dispatch({ type: GET_MEMBER_INFO.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const searchCorporateInfos =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_MEMBER_INFO.REQUEST });
    return getAPI({
      url: "callcenter/searchCorporate",
      params: {
        ...mapMemberInfoFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        dispatch({ type: GET_MEMBER_INFO.SUCCESS, payload: data });
        console.log("getMemberInfos:", data);
        return data;
      })
      .catch((error) => dispatch({ type: GET_MEMBER_INFO.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getAllTickerMemberInfoById = (id) => (dispatch) => {
  dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `callcenter/allMemberTicketList/${id}`,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.SUCCESS, payload: data });
    })
    .catch((error) =>
      dispatch({ type: GET_MEMBER_INFO_BY_MEMNO.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getAllTickets =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_TICKET_LIST.REQUEST });
    return getAPI({
      url: "callcenter/AllticketList",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
        first_name_create_by: filter?.first_name_create_by ?? null,
        description: filter?.description ?? null,
      },
    })
      .then((data) =>
        dispatch({ type: GET_TICKET_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_TICKET_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const downloadMemberExpiredCsv = async (date_amount = 1) => {
  const response = await getAPI({
    url: `callcenter/meberExpireToCSV?date_amount=${date_amount}`,
  });
  return response.data;
};

export const downloadCorporateExpiredCsv = async () => {
  const response = await getAPI({
    url: "callcenter/corporateExpireToCSV",
  });
  return response.data;
};
