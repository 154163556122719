import { CLEAR_ALERT } from 'redux/actionTypes'

const initialState = {
  isShow: false,
  message: '',
  type: '',
}

export default (state = initialState, action = {}) => {
  const { alertMessage } = action
  if (action.type.endsWith('_SUCCESS')) {
    // not show by default
    const { showAlert = false } = action
    if (showAlert) {
      return {
        isShow: true,
        message: alertMessage || 'Success',
        type: 'success',
      }
    }
  } else if (action.type.endsWith('_FAILURE')) {
    // show by default
    const { showAlert = true, error } = action

    if (showAlert) {
      return {
        isShow: true,
        message: alertMessage || error,
        type: 'error',
      }
    }
  } else if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      isShow: false,
    }
  }

  return state
}
