import React from "react";
import styled from "styled-components";
import { Button as ButtonMui } from "@material-ui/core";

const StyledButton = styled(ButtonMui)`
  padding: 11px;
  :hover {
    cursor: pointer;
  }

  .MuiButton-label {
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
  }
`;

const Button = ({ className, color = "primary", outlined, ...props }) => {
  return (
    <StyledButton
      className={className}
      color={color}
      variant={outlined ? "outlined" : "contained"}
      {...props}
    />
  );
};

export default Button;
