import React from "react";
import styled from "styled-components";
import { Divider as DividerMui } from "@mui/material";

const StyleDivider = styled(DividerMui)``;

const Divider = ({ classname, ...props }) => {
  return <StyleDivider className={classname} />;
};

export default Divider;
