import React, { useRef } from 'react'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { HighlightOff as CloseIcon, AddCircleOutline as AddIcon } from '@material-ui/icons'

const Div = styled.div`
  color: #b6b8c1;

  .file-wrapper {
    display: flex;
    align-items: center;

    button {
      margin-left: 2px;
    }
  }

  .add-file-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    button {
      margin-right: 2px;
    }

    input[type='file'] {
      width: 0;
      height: 0;
      border: none;
    }
  }

  a {
    color: #0075ca;
    text-decoration: underline;
  }

  svg {
    color: #b6b8c1;
  }
`

const FileCell = ({ accept, filePath, fileName, onFileDelete, onFileChange }) => {
  const elem = useRef()

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0])
    }
  }

  if (fileName) {
    return (
      <Div>
        <div className="file-wrapper">
          <a href={filePath} target="_blank" rel="noopener noreferrer">
            {fileName}
          </a>
          <IconButton size="small" onClick={onFileDelete}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Div>
    )
  }

  return (
    <Div>
      <div className="add-file-wrapper" onClick={() => elem.current.click()}>
        <IconButton size="small">
          <AddIcon fontSize="small" />
        </IconButton>
        <span>Add file here</span>
        <input type="file" ref={elem} accept={accept} onChange={handleFileChange} />
      </div>
    </Div>
  )
}

export default FileCell
