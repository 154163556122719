import React from "react";
import styled from "styled-components";
import { FormControl, TextField as TextFieldMui } from "@material-ui/core";
import OverwriteMomentBE from "./OverwriteMomentBE";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import "moment/locale/th";

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .MuiInputBase-input {
    padding: 14px 16px;
    border-radius: 10px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e8ecef !important;
    border-width: 1px !important;
  }
  .Mui-disabled {
    background-color: #eeeeee;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error.Mui-error.MuiInputBase-formControl {
    border: 1px solid red;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`;

const TextFieldDateTH = ({
  className,
  label,
  valueControl,
  functionSet,
  ...props
}) => {
  return (
    <StyledFormControl className={className} fullWidth>
      {Boolean(label) && <label>{label}</label>}
      <MuiPickersUtilsProvider utils={OverwriteMomentBE} locale={"th"}>
        <KeyboardDatePicker
          format={"DD/MM/YYYY"}
          autoOk
          variant="inline"
          inputVariant="outlined"
          placeholder={"DD/MM/YYYY"}
          value={valueControl}
          InputAdornmentProps={{ position: "end" }}
          onChange={(e) => functionSet(e)}
          initialFocusedDate
          todayLabel
        />
      </MuiPickersUtilsProvider>
    </StyledFormControl>
  );
};

export default TextFieldDateTH;
