import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { checkValue } from "utils/common";
import formatValue from "utils/formatValue";

import InlineSpaceText from "components/form/InlineSpaceText";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";
import ButtonBlack from "components/form/ButtonBlack";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import Paper from "components/surfaces/Paper";
import Divider from "components/surfaces/Divider";

import Table from "components/table/Table";

import BaseModal from "components/modal/BaseModal";
import PopupCampaignGenerate from "containers/popup/PopupReportGenerate";
import PopupSelectReportType from "containers/popup/PopupSelectReportType";

import {
  getExamUserDetail,
  editExamUserDetail,
  getExamUserDetailSubjectList,
} from "redux/actions/library";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }
`;

const DATA_MOCK = [
  {
    id: 1,
    company: "2bsimple",
    campaign: "สอบวัดผลหานักบัญชี",
    position: "นักบัณชี",
    test: "",
    report: "",
    date: "06/June/2022",
  },
  {
    id: 2,
    company: "2bsimple",
    campaign: "สอบวัดผลหานักบัญชี",
    position: "นักบัณชี",
    test: "",
    report: "",
    date: "06/June/2022",
  },
  {
    id: 3,
    company: "2bsimple",
    campaign: "สอบวัดผลหานักบัญชี",
    position: "นักบัณชี",
    test: "",
    report: "",
    date: "06/June/2022",
  },
];

const Examinerdetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { examUserDetail: info } = useSelector((state) => state.library);
  const [uid, setUId] = useState();
  const [userId, setUserId] = useState();
  const [isOpenPopupCampaignGenerate, setIsOpenPopupCampaignGenerate] =
    useState(false);

  const [isOpenPopupViewCampaign, setIsOpenPopupViewCampaign] = useState(false);
  const [isOpenPopupEditInfo, setIsOpenPopupEditInfo] = useState(false);
  const [isOpenPopupEditStatus, setIsOpenPopupEditStatus] = useState(false);

  useEffect(() => {
    getDetailExam();
  }, []);

  const getDetailExam = useCallback(() => {
    dispatch(getExamUserDetail(id));
  }, [id]);

  const handleCampaignGenerate = (row) => {
    setUId(row?.campaing_uid);
    setUserId(id);
    setIsOpenPopupCampaignGenerate(true);
  };

  const handleCloseCampaignGenerate = () => {
    setUId(null);
    setUserId(null);
    setIsOpenPopupCampaignGenerate(false);
  };

  const handleViewCampaign = (row) => {
    dispatch(getExamUserDetailSubjectList(id, row?.campaign_slug)).then(() => {
      setIsOpenPopupViewCampaign(true);
    });
  };

  const handleCloseViewCampaign = () => {
    setIsOpenPopupViewCampaign(false);
  };

  const handleEditExaminer = (row) => {
    setIsOpenPopupEditInfo(true);
  };

  const handleCloseEditExaminer = () => {
    getDetailExam();
    setIsOpenPopupEditInfo(false);
  };

  const handleEditStatus = (row) => {
    setIsOpenPopupEditStatus(true);
  };

  const handleCloseEditStatus = () => {
    setIsOpenPopupEditStatus(false);
  };

  const ViewResultCell = ({ value, row }) => {
    return <Button onClick={() => handleViewCampaign(row)}>View </Button>;
  };

  const ViewReportCell = ({ value, row }) => {
    return (
      <Button onClick={() => handleCampaignGenerate(row)}>View Report</Button>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 200,
    },
    {
      field: "campaign_name",
      headerName: "Company",
      width: 200,
    },
    {
      field: "campaign_slug",
      headerName: "Campaign",
      width: 200,
    },
    {
      field: "position",
      headerName: "Position",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "test",
      headerName: "Test set & Result",
      renderCell: ViewResultCell,
      width: 200,
    },
    {
      field: "report",
      headerName: "Report",
      renderCell: ViewReportCell,
      width: 200,
    },
    {
      field: "date_exam",
      headerName: "Test Date",
      width: 200,
    },
  ];

  return (
    <SidebarLayout title="Membership">
      {Boolean(isOpenPopupCampaignGenerate) && (
        <PopupSelectReportType
          open
          onClose={handleCloseCampaignGenerate}
          uid={uid}
          userId={userId}
        />
      )}
      {Boolean(isOpenPopupViewCampaign) && (
        <PopupCampaign open onClose={handleCloseViewCampaign} />
      )}
      {Boolean(isOpenPopupEditInfo) && (
        <PopupEditInfoExaminer
          open
          onClose={handleCloseEditExaminer}
          examinerId={info?.userUid}
          formProp={{
            first_name: info?.first_name,
            last_name: info?.last_name,
            email: info?.email,
            phone_number: info?.phone_number,
          }}
        />
      )}
      {Boolean(isOpenPopupEditStatus) && (
        <PopupEditStatusExaminer
          open
          onClose={handleCloseEditStatus}
          status={""}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} className="headbar-panel-wrapper">
              <BackIcon
                className="back-icon"
                onClick={() => history.goBack()}
              />
              <span className="title">ผู้ใช้งาน</span>
            </Grid>

            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid
                    item
                    spacing={1}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item alignItems="center" container xs={9}>
                      ข้อมูลเกี่ยวกับผู้สอบ
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleEditExaminer()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText className="fix-height " label="ชื่อ">
                    <span>{checkValue(info?.first_name)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height " label="นามสกุล ">
                    <span>{checkValue(info?.last_name)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="Email " className="fix-height ">
                    <span>{checkValue(info?.email)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="เบอร์โทร " className="fix-height ">
                    <span>{checkValue(info?.phone_number)}</span>
                  </InlineSpaceText>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} direction="column" item>
                  <Grid
                    item
                    xs={12}
                    spacing={1}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline "
                  >
                    <Grid item xs={9}>
                      สถานะผู้ทดสอบ
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      <Button
                        style={{ visibility: "hidden" }}
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        disabled
                        onClick={() => handleEditStatus()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText label="สถานะ " className="fix-height ">
                    <span>{checkValue(info?.user_status)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="วันที่สมัคร " className="fix-height ">
                    <span>
                      {checkValue(formatValue("Date2", info?.register_date))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่ใช้งานล่าสุด "
                    className="fix-height "
                  >
                    <span>
                      {checkValue(formatValue("Date2", info?.last_user_date))}
                    </span>
                  </InlineSpaceText>
                  <br />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} direction="column" item>
                <Grid item xs={12} container className="headline ">
                  <Grid item xs={12}>
                    Campaign : สอบวัดผลหานักบัญชี
                  </Grid>

                  <Grid item xs={12} className="table-wrapper">
                    <Table
                      getRowId={(row) => row + 1}
                      columns={columns}
                      rows={info?.exam_user_history?.map((item, index) => ({
                        ...item,
                        id: index + 1,
                      }))}
                      // rowCount={total}
                      // pageSize={limit}
                      // page={page}
                      checkboxSelection={false}
                      // onPageChange={handlePageChange}
                      // onSelectionChange={handleSelectMember}
                      disableSelectionOnClick={true}
                      // onEditRow={handleEditMember}
                      // onDeleteRow={handleEditMember}
                    />
                  </Grid>
                </Grid>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default Examinerdetail;

const DivModal = styled.div`
  .header-modal {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  button {
    margin: 0 10px;
    width: auto;
    min-width: 120px;
    height: 47px;
  }
`;

const DATA_MOCK_CAMPAIGN = [
  {
    id: 1,
    exam_no: "Test00001",
    exam_name: "Math 01",
    company: "2bsimple",
    exam_amount: 15,
    create_by: "Center",
    author: "พีชญุตม์",
    result: "70%",
  },
  {
    id: 2,
    exam_no: "Test00002",
    exam_name: "Logic 01",
    company: "2bsimple",
    exam_amount: 15,
    create_by: "Center",
    author: "พีชญุตม์",
    result: "70%",
  },
  {
    id: 3,
    exam_no: "Test00003",
    exam_name: "English 01",
    company: "2bsimple",
    exam_amount: 15,
    create_by: "Center",
    author: "พีชญุตม์",
    result: "70%",
  },
];

const PopupCampaign = ({ open, onClose }) => {
  const { subjectDetailList: info } = useSelector((state) => state.library);

  const submitForm = (event) => {
    event.preventDefault();
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 200,
    },
    {
      field: "subject_id",
      headerName: "รหัสชุดข้อสอบ",
      width: 200,
    },
    {
      field: "title",
      headerName: "ชื่อชุดข้อสอบ",
      width: 200,
    },
    {
      field: "company",
      headerName: "บริษัท",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "score",
      headerName: "จำนวนข้อสอบ",
      width: 200,
    },
    {
      field: "originate",
      headerName: "Originate By",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "author",
      headerName: "Author",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "exam_result_percent",
      headerName: "ผลการสอบ",
      width: 200,
      renderCell: ({ value }) => <div> {value}%</div>,
    },
  ];

  return (
    <BaseModal open={open} fullWidth={true} onClose={onClose} maxWidth={"lg"}>
      <DivModal>
        <form onSubmit={submitForm}>
          <Grid container>
            <Grid container spacing={1} direction="column" item>
              <Grid item xs={12} container>
                <Grid item xs={12} className="header-modal">
                  Campaign : สอบวัดผลหานักบัญชี
                </Grid>

                <Grid item xs={12} className="table-wrapper">
                  <Table
                    columns={columns}
                    rows={info?.exam_result_detail}
                    // rowCount={total}
                    // pageSize={limit}
                    // page={page}
                    checkboxSelection={false}
                    // onPageChange={handlePageChange}
                    // onSelectionChange={handleSelectMember}
                    disableSelectionOnClick={true}
                    // onEditRow={handleEditMember}
                    // onDeleteRow={handleEditMember}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button onClick={() => onClose()}> ปิด</Button>
            </Grid>
          </Grid>
        </form>
      </DivModal>
    </BaseModal>
  );
};

const PopupEditInfoExaminer = ({ open, onClose, formProp, examinerId }) => {
  const [form, setForm] = useState(formProp);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const submitForm = (event) => {
    event.preventDefault();
    dispatch(editExamUserDetail(examinerId, form)).then(() => {
      onClose();
    });
  };

  return (
    <BaseModal open={open} fullWidth={true} maxWidth={"sm"} onClose={onClose}>
      <DivModal>
        <form onSubmit={submitForm}>
          <Grid container spacing={1} direction="column" item>
            <Grid item xs={12} className="header-modal">
              แก้ไขข้อมูลผู้เข้าสอบ
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={form?.first_name}
                name="first_name"
                label={<strong> ชื่อจริง</strong>}
                onChange={(e) => handleChange(e)}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={<strong> นามสกุล</strong>}
                value={form?.last_name}
                name="last_name"
                onChange={(e) => handleChange(e)}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={form?.email}
                label={<strong> email</strong>}
                name="email"
                onChange={(e) => handleChange(e)}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={form?.phone_number}
                label={<strong> เบอร์โทร</strong>}
                name="phone_number"
                onChange={(e) => handleChange(e)}
                required
              />
            </Grid>

            <Grid
              item
              xs={12}
              className="button-wrapper"
              container
              spacing={2}
              justifyContent="center"
            >
              <Button onClick={onClose} outlined>
                ยกเลิก{" "}
              </Button>
              <ButtonBlack type="submit">แก้ไขข้อมูล</ButtonBlack>
            </Grid>
          </Grid>
        </form>
      </DivModal>
    </BaseModal>
  );
};

const STATUS_LIST = [
  { text: "Active", value: 1 },
  { text: "InActive", value: 2 },
];

const PopupEditStatusExaminer = ({ open, onClose, statusProp }) => {
  const [status, setStatus] = useState(statusProp);

  const handleChange = (value) => {
    setStatus(value);
  };

  const submitForm = (event) => {
    event.preventDefault();
  };

  return (
    <BaseModal open={open} fullWidth={true} maxWidth={"sm"} onClose={onClose}>
      <DivModal>
        <form onSubmit={submitForm}>
          <Grid container spacing={1} direction="column" item>
            <Grid item xs={12} className="header-modal">
              แก้ไขข้อมูลสถานะผู้เข้าสอบ
            </Grid>
            <Grid item xs={12}>
              <Select
                items={STATUS_LIST}
                value={status}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className="button-wrapper"
              container
              spacing={2}
              justifyContent="center"
            >
              <Button onClick={onClose} outlined>
                ยกเลิก{" "}
              </Button>
              <ButtonBlack type="submit">ยืนยัน</ButtonBlack>
            </Grid>
          </Grid>
        </form>
      </DivModal>
    </BaseModal>
  );
};
