import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Button from "components/form/Button";
import Table from "components/table/Table";
import BaseModal from "components/modal/BaseModal";
import Select from "components/form/Select";
import Grid from "@mui/material/Grid";

const MOCK_DATA = [
  {
    id: 1,
    a: 1000001,
    b: "",
    c: "",
    d: "",
    e: 12345,
    f: "สมชาย",
    g: "ประเสริฐ",
    h: "2,000 บาท",
    i: "Admin01",
  },
];

const Div = styled.div`
  p {
    margin-top: 0;
  }

  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h2 span {
    background: #fff;
    padding: 0 10px;
  }

  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 2em;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .btn-status {
    width: 100px;
    height: 80%;
  }

  .btn-create {
    margin: 0;
    margin-left: auto;
  }

  .topic-popup {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
  }

  .text-center {
    text-align: center;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const initFilter = {
  code: "",
  examOwner: "",
  subjectId: "",
  topicName: "",
  level: "",
  status: "",
  question: "",
};

const ITEM_HEIGHT = 48;

const Refund = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.library);

  const [isOpenPopupCreate, setIsOpenPopupCreate] = useState(false);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
    },
    {
      field: "a",
      headerName: "เลขที่ใบรับเรื่อง",
    },
    {
      field: "b",
      headerName: "Ref 1",
    },
    {
      field: "c",
      headerName: "Ref 2",
    },
    {
      field: "d",
      headerName: "ประเภท",
    },
    {
      field: "e",
      headerName: "เลขที่สมาชิก",
    },
    {
      field: "f",
      headerName: "ชื่อสมาชิก",
    },
    {
      field: "g",
      headerName: "นามสกุล",
    },
    {
      field: "h",
      headerName: "จำนวนเงินที่คืน",
    },
    {
      field: "i",
      headerName: "เจ้าหน้าที่ผู้ดำเนินการ",
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
  };

  return (
    <SidebarLayout title="Accouting Management">
      <Div>
        {Boolean(isOpenPopupCreate) && (
          <PopupCreateRefund open onClose={() => setIsOpenPopupCreate(false)} />
        )}

        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-payment", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">ระบบคืนเงิน</div>
              <div className="group-field">
                <TextField
                  label="รายได้ประจำวันตั้งแต่วันที่"
                  type="date"
                  value={filter.code}
                  placeholder="Start Date"
                />
                <TextField
                  label="ถึงวันที่"
                  type="date"
                  value={filter.code}
                  placeholder="End Date"
                />
                <Button onClick={() => handleSearch()}>ค้นหา</Button>
                <Button
                  className="btn-create"
                  onClick={() => setIsOpenPopupCreate(true)}
                >
                  สร้างรายการคืนเงิน
                </Button>
              </div>
            </div>
          </div>
          <br />

          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={MOCK_DATA}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default Refund;

const MOCK_DATA_POPUP = [
  {
    id: 1,
    a: 1000001,
    b: "",
    c: "",
    d: "",
    e: "",
    f: "",
    g: "",
  },
];

const PopupCreateRefund = ({ open, onClose }) => {
  const [isOpenPopupDetail, setIsOpenPopupDetail] = useState(false);
  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
    },
    {
      field: "a",
      headerName: "เลขที่ใบรับเรื่อง",
    },
    {
      field: "b",
      headerName: "Ref 1",
    },
    {
      field: "c",
      headerName: "Ref 2",
    },
    {
      field: "d",
      headerName: "ประเภท",
    },
    {
      field: "e",
      headerName: "รายการชำระเงิน",
    },
    {
      field: "f",
      headerName: "จำนวนเงินที่ชำระ",
    },
    {
      field: "g",
      headerName: "",
      renderCell: ({ row }) => (
        <Button onClick={handleOpenPopupDetail}>Select</Button>
      ),
    },
  ];

  const handleOpenPopupDetail = (row) => {
    setIsOpenPopupDetail(true);
  };

  const handleClosePopupDetail = () => {
    setIsOpenPopupDetail(false);
  };

  return (
    <BaseModal open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
      <Div>
        {Boolean(isOpenPopupDetail) && (
          <PopupCreateDetail open onClose={handleClosePopupDetail} />
        )}

        <Grid container spacing={2}>
          <Grid item xs>
            <div className="form-wrapper">
              <div className="section-group">
                <div className="group-title">เลือกใบเสร็จคืนเงิน</div>
                <div className="group-field">
                  <TextField
                    label="รายได้ประจำวันตั้งแต่วันที่"
                    type="date"
                    value={" "}
                    placeholder="Start Date"
                  />
                  <TextField
                    label="ถึงวันที่"
                    type="date"
                    value={" "}
                    placeholder="End Date"
                  />
                  <Button onClick={() => {}}>ค้นหา</Button>
                </div>
                <div className="group-field">
                  <Select
                    name={"reportConfId"}
                    items={[]}
                    value={""}
                    defaultValue="เลือกประเภท"
                    className="field-type"
                    onChange={(e) => console.log(e)}
                  />
                  <TextField
                    placeholder="ค้นหาเลขที่ใบรับเรื่อง"
                    value={""}
                    type="search"
                    onChange={(e) => console.log(e)}
                  />
                  <TextField
                    placeholder="ค้นหาเลข Reference"
                    value={""}
                    type="search"
                    onChange={(e) => console.log(e)}
                  />
                  <Button
                    className="btn-create"
                    onClick={handleOpenPopupDetail}
                  >
                    กรอกใบเสร็จชำระเงินเอง
                  </Button>
                </div>
              </div>
            </div>
            <br />

            <div className="table-wrapper">
              <Table
                columns={columns}
                rows={MOCK_DATA_POPUP}
                // rowCount={total}
                // pageSize={limit}
                // page={page}
                // onPageChange={handlePageChange}
                checkboxSelection={false}
                disableSelectionOnClick={true}
              />
            </div>

            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                Close
              </Button>
            </div>
          </Grid>
        </Grid>
      </Div>
    </BaseModal>
  );
};

const PopupCreateDetail = ({ open, onClose, bindingData = {} }) => {
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const handleOpenPopupConfirm = (row) => {
    setIsOpenPopupConfirm(true);
  };

  const handleClosePopupConfirm = () => {
    setIsOpenPopupConfirm(false);
    onClose();
  };
  return (
    <BaseModal open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <Div>
        {Boolean(isOpenPopupConfirm) && (
          <PopupConfirm open onClose={() => handleClosePopupConfirm()} />
        )}

        <Grid container spacing={2}>
          <Grid item xs>
            <p className="topic-popup">กรอกรายละเอียดการคืนเงิน</p>
            <InlineSpaceText label="เลขที่ใบรับเรื่องในระบบ">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>
            <h2>
              <span>or</span>
            </h2>

            <InlineSpaceText label="เลขที่อ้างอิงอื่น ๆ">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>
            <InlineSpaceText label="เลขที่สมาชิก">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>
            <InlineSpaceText label="Ref 1">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>
            <InlineSpaceText label="Ref 2">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>
            <InlineSpaceText label="ประเภท">
              <Select
                items={[]}
                value={""}
                defaultValue="เลือกประเภท"
                className="field-type"
                onChange={(e) => console.log(e)}
              />
            </InlineSpaceText>
            <InlineSpaceText label="ช่องทางการชำระเงิน">
              <Select
                items={[]}
                value={""}
                defaultValue="-"
                className="field-type"
                onChange={(e) => console.log(e)}
              />
            </InlineSpaceText>
            <InlineSpaceText label="จำนวนเงิน">
              <TextField value={""} onChange={(e) => console.log(e)} />
            </InlineSpaceText>

            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                Close{" "}
              </Button>
              <Button onClick={handleOpenPopupConfirm}>Confirm </Button>
            </div>
          </Grid>
        </Grid>
      </Div>
    </BaseModal>
  );
};

const PopupConfirm = ({ open, onClose, bindingData = {} }) => {
  return (
    <BaseModal open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <p className="topic-popup">ยืนยันการคืนเงิน</p>
            <p className="text-center">
              คุณจะทำการคืนเงินให้กับสมาชิกเลขที่ xxxxxx หมายเลขใบเสร็จ xxxxxxx
              เป็นจำนวนเงิน xxxx บาท
            </p>
            <p className="text-center">ต้องการยืนยันการทำรายการใช่หรือไม่ ?</p>
            <div className="button-wrapper">
              <Button onClick={onClose} outlined>
                Close{" "}
              </Button>
              <Button onClick={onClose}>Confirm </Button>
            </div>
          </Grid>
        </Grid>
      </Div>
    </BaseModal>
  );
};
