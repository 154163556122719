import {
  GET_MASTER_DATA_PROVINCE,
  GET_MASTER_DATA_DISTRICT,
  GET_MASTER_DATA_SUB_DISTRICT,
  GET_MASTER_DATA_BANK,
  GET_MASTER_DATA_NATIONALITY,
  GET_MASTER_DATA_TAG,
  GET_MASTER_DATA_SUBJECT,
  GET_MASTER_DATA_JOB,
  GET_MASTER_DATA_COE_REPORT,
  GET_MASTER_DATA_COE_SUB_REPORT,
} from "redux/actionTypes";

const initialState = {
  proviceData: [],
  districtData: [],
  subDistrictData: [],
  bankData: [],
  nationalityData: [],
  tagData: [],
  memberStatusData: [
    {
      text: "active",
      value: 1,
    },
    {
      text: "inactive",
      value: 2,
    },
    {
      text: "draft",
      value: 3,
    },
    {
      text: "revoke",
      value: 4,
    },
  ],
  subjectData: [],
  jobData: [],
  reportData: [],
  subReportData: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_MASTER_DATA_PROVINCE: {
      return {
        ...state,
        proviceData: action.payload,
      };
    }
    case GET_MASTER_DATA_DISTRICT: {
      return {
        ...state,
        districtData: action.payload,
      };
    }
    case GET_MASTER_DATA_SUB_DISTRICT: {
      return {
        ...state,
        subDistrictData: action.payload,
      };
    }
    case GET_MASTER_DATA_BANK: {
      return {
        ...state,
        bankData: action.payload,
      };
    }
    case GET_MASTER_DATA_NATIONALITY: {
      return {
        ...state,
        nationalityData: action.payload,
      };
    }
    case GET_MASTER_DATA_TAG: {
      return {
        ...state,
        tagData: action.payload,
      };
    }
    case GET_MASTER_DATA_SUBJECT: {
      return {
        ...state,
        subjectData: action.payload,
      };
    }
    case GET_MASTER_DATA_JOB: {
      return {
        ...state,
        jobData: action.payload,
      };
    }
    case GET_MASTER_DATA_COE_REPORT: {
      return {
        ...state,
        reportData: action.payload,
      };
    }
    case GET_MASTER_DATA_COE_SUB_REPORT: {
      return {
        ...state,
        subReportData: action.payload,
      };
    }
    default:
      return state;
  }
};
