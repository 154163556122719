import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

// import {} from "redux/actions/notification";
import TextField from "components/form/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextFieldArea from "components/form/TextFieldArea";
import {
  createTicket,
  getMemberInfoById,
  getAllTicketType,
  getTicketInfoById,
  editTicketInfo,
  addTicketStatus,
} from "redux/actions/callcenter";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 20px;
    }

    .question-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
`;

const initFrom = {
  member_no: "",
  first_name: "",
  last_name: "",
  phone_no: "",
  ticket_type: "",
  description: "",
  ticket_date: "",
  ticket_time: "",
  time_call: "",
};

const PopupEditTicketCallCenter = ({
  open,
  onClose,
  label,
  maxWidth = "xl",
  ticketId,
}) => {
  const dispatch = useDispatch();
  const { ticketInfoById, tickettypes, memberInfoByMemNo } = useSelector(
    (state) => state.callcenter
  );
  const [from, setFrom] = useState(initFrom);
  const [errors, setErrors] = useState({});
  const [ticketType, setTicketType] = useState();

  const [member_no, setMember_no] = useState(ticketInfoById.member_no);
  const [first_name, setFirst_name] = useState(ticketInfoById.first_name);
  const [last_name, setLast_name] = useState(ticketInfoById.last_name);
  const [phone_no, setPhone_no] = useState(ticketInfoById.phone_no);
  const [ticket_type, setTicket_type] = useState(ticketInfoById.ticket_type_id);
  const [description, setDescription] = useState(ticketInfoById.description);
  const [ticket_date, setTicket_date] = useState(ticketInfoById.ticket_date);
  const [ticket_time, setTicket_time] = useState(ticketInfoById.ticket_time);
  const [time_call, setTime_call] = useState(ticketInfoById.time_call);
  const [history, setHistory] = useState(false);
  const [title_member_no, setTitle_member_no] = useState("เลขที่สมาชิก");
  const [title_fname, setTitle_fname] = useState("ชื่อ");
  const [is_render_member_no, setIs_render_member_no] = useState(true);
  const [is_render_lname, setIs_render_lname] = useState(true);

  // useEffect

  useEffect(() => {
    requestTicket(ticketId);
    requestAllTicketsType();
  }, [ticketId]);

  useEffect(() => {
    if (tickettypes?.length > 0) {
      console.log(tickettypes);
      const ticket_types = tickettypes.map((v) => ({
        value: v.id,
        text: v.type_name,
      }));
      setTicketType(ticket_types);
    }
  }, [tickettypes]);

  // useEffect(() => {
  //   console.log('App comp value:', isMember);
  //   setIsMember(isMember);
  //   if (isMember == 1){

  //   }
  //   if (isMember == 2){

  //   }
  //   if (isMember == 3){

  //   }
  //   console.log('App comp value title_member_no:', title_member_no);
  // }, [isMember]);
  // handle
  const requestTicket = async (id) => {
    dispatch(getTicketInfoById(id));
    if (ticketInfoById) {
      const log_history = {
        ticket_id: id,
        ticket_type: ticketInfoById.ticket_type_id,
        description: ticketInfoById.description,
        ticket_date: ticketInfoById.ticket_date,
        ticket_time: ticketInfoById.ticket_time,
        time_call: ticketInfoById.time_call,
        createdBy: ticketInfoById.updated_by,
      };
      setHistory(log_history);

      console.log(ticketInfoById);
      // setTimeout(() => {
      if (ticketInfoById.ticket_member_type == "member") {
        setTitle_member_no("เลขที่สมาชิก");
        setTitle_fname("ชื่อ");
        setIs_render_member_no(true);
        setIs_render_lname(true);
      } else if (ticketInfoById.ticket_member_type == "corporate") {
        setTitle_member_no("เลขทะเบียนนิติบุคคล");
        setTitle_fname("ชื่อนิติบุคคล");
        setIs_render_member_no(true);
        setIs_render_lname(false);
      } else {
        setTitle_fname("ชื่อ");
        setIs_render_member_no(false);
        setIs_render_lname(false);
      }
      // }, 3500)
    }
  };

  const requestAllTicketsType = async () => {
    dispatch(getAllTicketType());
  };

  const handleExistMember = () => {
    if (member_no && member_no !== "") {
      dispatch(getMemberInfoById(member_no));
      setMember_no(memberInfoByMemNo?.member_no);
      setFirst_name(memberInfoByMemNo?.first_name_th_th);
      setLast_name(memberInfoByMemNo?.last_name_th_th);
      setPhone_no(memberInfoByMemNo?.phone_no);
    }
  };

  const handleConfirmEdit = () => {
    if (validate()) {
      const obj = {
        ticket_type,
        description,
        ticket_date,
        ticket_time,
        time_call,
        history,
      };
      console.log(obj);
      dispatch(editTicketInfo(ticketId, obj));
      changeTicketStatus();
    }
  };

  const changeTicketStatus = () => {
    const body = {
      ticket_id: ticketId,
      status: "editdata",
      comment: "",
    };

    dispatch(addTicketStatus(body)).then((_) => onClose());
  };

  const validate = (
    fieldValues = {
      ticket_type,
      description,
      ticket_date,
      ticket_time,
      time_call,
    }
  ) => {
    let temp = { ...errors };
    //console.log(fieldValues);

    if ("ticket_type" in fieldValues)
      temp.ticket_type =
        fieldValues.ticket_type !== "" ? "" : "กรุณาเลือกหัวข้อเรื่อง";
    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกเนื้อหา";
    if ("ticket_date" in fieldValues)
      temp.ticket_date =
        fieldValues.ticket_date !== "" ? "" : "กรุณากรอกวันที่โทรเข้ามา";
    if ("ticket_time" in fieldValues)
      temp.ticket_time = fieldValues.ticket_time !== "" ? "" : "กรุณากรอกเวลา";
    if ("time_call" in fieldValues)
      temp.time_call =
        fieldValues.time_call !== "" ? "" : "กรุณากรอกระยะเวลาการโทร";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      memberNo={member_no}
    >
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">แก้ไขเรื่อง</div>
            <br />
            <div className="question-from">
              {is_render_member_no == true && (
                <div className="question-group">
                  <div className="question-title">{title_member_no}</div>
                  <div className="question-item">
                    <TextField
                      value={member_no}
                      type="number"
                      disabled={true}
                      onChange={(e) => {
                        setMember_no(e.target.value);
                      }}
                      // onChange={(e) => handleFromChange("member_no", e.target.value)}
                      error={errors.member_no}
                    />
                  </div>
                </div>
              )}
              <div className="question-group">
                <div className="question-title">{title_fname}</div>
                <div className="question-item">
                  <TextField
                    value={first_name}
                    disabled={true}
                    //onChange={(e) => handleFromChange("first_name", e.target.value)}
                    onChange={(e) => {
                      setFirst_name(e.target.value);
                    }}
                    // onChange={(e) => handleFromChange("member_no", e.target.value)}
                    error={errors.first_name}
                  />
                </div>
              </div>
              {is_render_lname == true && (
                <div className="question-group">
                  <div className="question-title">นามสกุล</div>
                  <div className="question-item">
                    <TextField
                      value={last_name}
                      disabled={true}
                      //onChange={(e) => handleFromChange("last_name", e.target.value)}
                      onChange={(e) => {
                        setLast_name(e.target.value);
                      }}
                      error={errors.last_name}
                    />
                  </div>
                </div>
              )}
              <div className="question-group">
                <div className="question-title">เบอร์โทร</div>
                <div className="question-item">
                  <TextField
                    value={phone_no}
                    type="tel"
                    disabled={true}
                    onChange={(e) => {
                      setPhone_no(e.target.value);
                    }}
                    error={errors.phone_no}
                    //onChange={(e) => handleFromChange("phone_no", e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="question-group">
                <div className="question-title">วันที่โทรเข้ามา</div>
                <div className="question-item">
                  <TextField
                    value={ticket_date}
                    type="date"
                    //onChange={(e) => handleFromChange("ticket_date", e.target.value)}
                    onChange={(e) => {
                      setTicket_date(e.target.value);
                      validate({ ["ticket_date"]: e.target.value });
                    }}
                    error={errors.ticket_date}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">เวลา</div>
                <div className="question-item">
                  <TextField
                    value={ticket_time}
                    type="time"
                    onChange={(e) => {
                      setTicket_time(e.target.value);
                      validate({ ["ticket_time"]: e.target.value });
                    }}
                    error={errors.ticket_time}
                    //onChange={(e) => handleFromChange("ticket_time", e.target.value)}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">ระยะเวลาการโทร</div>
                <div className="question-item">
                  <TextField
                    value={time_call}
                    type="number"
                    onChange={(e) => {
                      setTime_call(e.target.value);
                      validate({ ["time_call"]: e.target.value });
                    }}
                    error={errors.time_call}
                    //onChange={(e) => handleFromChange("time_call", e.target.value)}
                  />
                </div>
              </div> */}

              <div className="question-group">
                <div className="question-title">หัวข้อเรื่อง</div>
                <div className="question-item">
                  <Select
                    items={ticketType}
                    value={ticket_type}
                    defaultValue="Ticket Type"
                    // onChange={(e) =>
                    //   handleFromChange("ticket_type", e.target.value)
                    // }
                    onChange={(e) => {
                      setTicket_type(e.target.value);
                      validate({ ["ticket_type"]: e.target.value });
                    }}
                    error={errors.ticket_type}
                  />
                </div>
              </div>

              <div className="question-group">
                <div className="question-title">รายละเอียด</div>
                <div className="question-item">
                  <TextFieldArea
                    value={description}
                    // onChange={(e) =>
                    //   handleFromChange("description", e.target.value)
                    // }
                    onChange={(e) => {
                      setDescription(e.target.value);
                      validate({ ["description"]: e.target.value });
                    }}
                    error={errors.description}
                    placeholder="Message"
                    maxRows={6}
                    minRows={6}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="" style={{ textAlign: "center" }}>
          <Button onClick={onClose} outlined style={{ width: "10px" }}>
            ยกเลิก
          </Button>
          <Button
            onClick={() => handleConfirmEdit()}
            style={{ marginLeft: "20px", width: "10px" }}
          >
            แก้ไข
          </Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditTicketCallCenter;
