import React from 'react'
import styled from 'styled-components'
import { CheckCircleOutline as CheckIcon } from '@material-ui/icons'

const Div = styled.div`
  color: #b6b8c1;

  .check-wrapper {
    display: flex;
    align-items: center;
    color: #2bba7e;

    svg {
      margin-right: 2px;
      color: #2bba7e;
    }
  }
`
const CheckCell = ({ renderEmpty, children }) => {
  if (renderEmpty) {
    return renderEmpty()
  }

  if (children) {
    return (
      <Div>
        <div className="check-wrapper">
          <CheckIcon fontSize="small" />
          <span>{children}</span>
        </div>
      </Div>
    )
  }

  return <Div>No Data</Div>
}

export default CheckCell
