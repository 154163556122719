import React, { useState } from "react";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const selectList = [{ text: "ข้อมูลนิติบุคคล", value: "" }];

const mockData = [
  {
    id: 1,
    name: 'ข้อมูลนิติบุคคล แบบที่ 1',
    start_date: '01/08/2565',
    end_date: '30/08/2565'
  },
  {
    id: 2,
    name: 'ข้อมูลนิติบุคคล แบบที่ 2',
    start_date: '01/08/2565',
    end_date: '30/08/2565'
  },
  {
    id: 3,
    name: 'ข้อมูลนิติบุคคล แบบที่ 3',
    start_date: '01/08/2565',
    end_date: '30/08/2565'
  },
];

const ReportFinance = () => {
  const [select, setSelect] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState(mockData);
  const [total, setTotal] = useState(3);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const renderDownloadReportButton = ({ row }) => {
    if (row !== null)
      return (
        <Button>
          Download Report
        </Button>
      );
    return "-";
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      // width: 250,
    },
    {
      field: "name",
      headerName: "Name",
      // width: 250,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      // width: 250,
    },
    {
      field: "end_date",
      headerName: "End Date",
      // width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      // width: 250,
      renderCell: renderDownloadReportButton,
    },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <SidebarLayout title="Cluster">
      <Div>
        <SubMenu
          menu={[
            { path: "/report/manage", text: "ฝ่ายบริหาร" },
            { path: "/report/finance", text: "ฝ่ายการคลัง" },
            { path: "/report/international", text: "ฝ่ายต่างประเทศ" },
            { path: "/report/building", text: "ฝ่ายอาคารสถานที่" },
            { path: "/report/corporate_communication", text: "ฝ่ายสื่อสารองค์กร" },
            { path: "/report/office_coucil", text: "สำนักงานสภาวิศวกร" },
            { path: "/report/exclusive_coucil", text: "ผู้บริหารสภาวิศวกร" },
            { path: "/report/tech", text: "ฝ่ายเทคโนโลยีสารสนเทศ" },
            { path: "/report/license", text: "ฝ่ายทะเบียนและใบอนุญาต" },
            { path: "/report/law_ethic", text: "สำนักกฎหมายและจรรยาบรรณ" },
            { path: "/report/edu_standard", text: "ฝ่ายมาตรฐานการศึกษาและวิชาชีพ" },
          ]}
        />
        <div className="content-wrapper">
          <h3>Step 1 : เลือกรายงาน ฝ่ายการคลัง</h3>
          <div className="form-wrapper">
            <Select
              items={selectList}
              value={select}
              defaultValue="ประเภทข้อมูลนิติบุคคล"
              className="field-campaign"
              onChange={(e) =>
                setSelect(e.target.value)
              }
            />
          </div>
        </div>
        <div className="content-wrapper">
          <h3>Step 2 : เลือกรายงาน</h3>
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-field">
                <TextField
                  type="date"
                  label="Start Date"
                  value={startDate}
                  name="search_firstname"
                  onChange={(e) =>
                    setStartDate(e.target.value)
                  }
                />
                <TextField
                  type="date"
                  label="End Date"
                  value={endDate}
                  name="search_lastname"
                  onChange={(e) =>
                    setEndDate(e.target.value)
                  }
                />
                <TextField
                  label="Id"
                  value={id}
                  name="search_compatibilityLess"
                  onChange={(e) =>
                    setId(e.target.value)
                  }
                />
                <Button>
                  Search
                </Button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={tableData}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ReportFinance;
