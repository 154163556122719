import {
  GET_TICKET_LIST,
  GET_TICKET_BY_ID,
  ADD_TICKET_STATUS,
  ADD_TICKET,
  EDIT_TICKET,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_BY_MEMNO,
  GET_TICKET_TYPE_LIST,
  GET_TICKET_HISTORY_LIST,
  GET_CORPORATE_INFO,
  GET_CORPORATE_INFO_BY_ID,
  DOWNLOAD_MEMBER_EXPIRED_CSV,
  DOWNLOAD_CORPORATE_EXPIRED_CSV,
} from "redux/actionTypes";
import {} from "utils/common";

const mapTicketItems = (v) => ({
  id: v.id,
  ticket_no: v.ticket_no,
  member_no: v.member_no,
  title_id: v.title_id,
  first_name: v.first_name,
  last_name: v.last_name,
  phone_no: v.phone_no,
  full_name: v.full_name,
  ticket_type: v.ticket_type,
  description: v.description,
  ticket_date: v.ticket_date,
  ticket_time: v.ticket_time,
  time_call: v.time_call,
  email_create_by: v.email_create_by,
  first_name_create_by: v.first_name_create_by,
  last_name_create_by: v.last_name_create_by,
  full_name_create_by: v.full_name_create_by,
  ticket_status: v.ticket_status,
  created_at: v.created_at,
  comment: v.comment,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  tickets: [],
  totalCustomSearch: 0,
  memberExpiredCsvData: [],
  corporateExpiredCsvData: [],
  //items: [],
  //quotationById: {},
  //nvoiceById: {},
  //receiptById: {},
  //deliveryById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TICKET_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_TICKET_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return { ...state, total, limit, skip, tickets: data };
    }
    case GET_TICKET_LIST.FAILURE:
      return {
        ...state,
      };

    case ADD_TICKET.REQUEST:
      return { ...state };
    case ADD_TICKET.SUCCESS:
      return { ...state };
    case ADD_TICKET.FAILURE:
      return { ...state };

    case GET_MEMBER_INFO.REQUEST:
      return {
        ...state,
      };
    case GET_MEMBER_INFO.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return { ...state, totalCustomSearch: total, limit, skip, members: data };
    }
    case GET_MEMBER_INFO.FAILURE:

    case GET_MEMBER_INFO_BY_MEMNO.REQUEST:
      return {
        ...state,
      };
    case GET_MEMBER_INFO_BY_MEMNO.SUCCESS:
      return { ...state, memberInfoByMemNo: action.payload };
    case GET_MEMBER_INFO_BY_MEMNO.FAILURE:

    case GET_TICKET_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_TICKET_BY_ID.SUCCESS:
      return {
        ...state,
        ticketInfoById: action.payload,
        ticketInfoStatusList: action.payload.status_list,
      };
    case GET_TICKET_BY_ID.FAILURE:

    case ADD_TICKET_STATUS.REQUEST:
      return { ...state };
    case ADD_TICKET_STATUS.SUCCESS:
      return { ...state };
    case ADD_TICKET_STATUS.FAILURE:
      return { ...state };

    case GET_TICKET_TYPE_LIST.REQUEST:
      return { ...state };
    case GET_TICKET_TYPE_LIST.SUCCESS: {
      const { data } = action.payload;
      //console.log('tickettypes:', data);
      return { ...state, tickettypes: data };
    }
    case GET_TICKET_TYPE_LIST.FAILURE:
      return { ...state };

    case EDIT_TICKET.REQUEST:
      return { ...state };
    case EDIT_TICKET.SUCCESS:
      return { ...state };
    case EDIT_TICKET.FAILURE:
      return { ...state };

    case GET_TICKET_HISTORY_LIST.REQUEST:
      return { ...state };
    case GET_TICKET_HISTORY_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return { ...state, total, limit, skip, tickethistory: data };
    }
    case GET_TICKET_HISTORY_LIST.FAILURE:
      return { ...state };

    case GET_CORPORATE_INFO.REQUEST:
      return {
        ...state,
      };
    case GET_CORPORATE_INFO.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        totalCustomSearch: total,
        limit,
        skip,
        corporates: data,
      };
    }
    case GET_CORPORATE_INFO.FAILURE:

    case GET_CORPORATE_INFO_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_CORPORATE_INFO_BY_ID.SUCCESS:
      return { ...state, corporateInfoByMemNo: action.payload };
    case GET_CORPORATE_INFO_BY_ID.FAILURE:

    case DOWNLOAD_MEMBER_EXPIRED_CSV.REQUEST:
      return {
        ...state,
      };
    case DOWNLOAD_MEMBER_EXPIRED_CSV.SUCCESS:
      return { ...state, memberExpiredCsvData: action.payload };
    case DOWNLOAD_MEMBER_EXPIRED_CSV.FAILURE:
      return {
        ...state,
      };
    case DOWNLOAD_CORPORATE_EXPIRED_CSV.REQUEST:
      return {
        ...state,
      };
    case DOWNLOAD_CORPORATE_EXPIRED_CSV.SUCCESS:
      return { ...state, corporateExpiredCsvData: action.payload };
    case DOWNLOAD_CORPORATE_EXPIRED_CSV.FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
