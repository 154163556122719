import React from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";

const StyledButton = styled(IconButton)`
  padding: 0;
  :hover {
    background-color: inherit;
    cursor: pointer;
  }
`;

const ButtonIcon = ({
  className,
  children,
  color = "primary",
  size = "small",
  ...props
}) => {
  return (
    <StyledButton className={className} color={color} size={size} {...props}>
      {children}
    </StyledButton>
  );
};

export default ButtonIcon;
