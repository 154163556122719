import {
  CREATE_EXAM_CAMPAIGN,
  CREATE_REPORT_CONFIG,
  GET_CAMPAIGN_LIST,
  GET_DISC_RESULT_LIST,
  GET_EXAM_CAMPAIGN_BY_DATE,
  GET_EXAM_CAMPAIGN_LIST,
  GET_REPORT_CONFIG_BY_ID,
  GET_REPORT_CONFIG_LIST,
  GET_REPORT_CONFIG_LIST_DROPDOWN,
  PATCH_EXAM_CAMPAIGN_STATUS,
  PATCH_EXAM_STATUS,
  PATCH_REPORT_CONFIG,
  GET_REPORT_EXAM,
  GET_REPORT_1,
  GET_REPORT_EXTRA_1,
  GET_REPORT_2,
  GET_REPORT_EXTRA_2,
  GET_REPORT_3,
  GET_REPORT_EXTRA_3,
  GET_EXPORT_REPORT_1,
  GET_EXPORT_REPORT_EXTRA_1,
  GET_EXPORT_REPORT_2,
  GET_EXPORT_REPORT_EXTRA_2,
  GET_EXPORT_REPORT_3,
  GET_EXPORT_REPORT_EXTRA_3,
  GET_REPORT_EXAM_1,
  GET_EXPORT_REPORT_EXAM_1,
  GET_REPORT_EXAM_2,
  GET_EXPORT_REPORT_EXAM_2,
  GET_REPORT_EXAM_3,
  GET_EXPORT_REPORT_EXAM_3,
  GET_REPORT_EXAM_4,
  GET_EXPORT_REPORT_EXAM_4,
  GET_EXPORT_REPORT_EXAM,
  GET_BATCH_UPDATE,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

import Moment from "moment";

import { ExportToCsv } from "export-to-csv";

const mapFilter = (v) => ({
  id: v.id || null,
  first_name: v.firstName || null,
  last_name: v.lastName || null,
  compatibility_less: v.compatibilityLess || null,
  compatibility_more: v.compatibilityMore || null,
  exam_result_less: v.examResultLess || null,
  exam_result_more: v.examResultMore || null,
  status: v.status || null,
  disc: v.disc || null,
  campaign_type: v.campaignType || null,
  start_date: v.startDate || null,
  end_date: v.endDate || null,
});

const mapReportConfigFilter = (v) => ({
  report_name: v.reportName || null,
  report_create_by: v.reportCreateBy || null,
  report_create_date: v.reportCreateDate || null,
  report_total_subject: v.reportTotalSubject || null,
});

const mapCampaignFilter = (v) => ({
  name: v.name || null,
  companyName: v.companyName || null,
  start_date: v.startDate || null,
  end_date: v.endDate || null,
  status: v.status || null,
  campaign_type: v.campaignType || null,
});

const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: `report${new Date().getTime()}-${Math.floor(
    Math.random() * 100000000 + 1
  )}`,
};

const csvExporter = new ExportToCsv(options);

export const getExamCampaigns =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_LIST.REQUEST });
    return getAPI({
      url: "examCampaign",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const createCampaign = (obj) => (dispatch) => {
  dispatch({ type: CREATE_EXAM_CAMPAIGN.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "examCampaign",
    data: obj,
  })
    .then((data) => {
      dispatch({ type: CREATE_EXAM_CAMPAIGN.SUCCESS });
    })
    .catch((error) => dispatch({ type: CREATE_EXAM_CAMPAIGN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamCampaignByDate =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.REQUEST });
    return getAPI({
      url: "examCampaign",
      params: {
        ...mapFilter(filter),
        $limit: limit,
        $skip: skip,
      },
    })
      .then((data) => {
        console.log(data);
        if (data !== "[]") {
          csvExporter.generateCsv(data?.data);
        }
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.SUCCESS });
      })
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateExamStatus = (examUserStatusId) => (dispatch) => {
  dispatch({ type: PATCH_EXAM_STATUS.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `examUser/${examUserStatusId}`,
  })
    .then((data) => {
      dispatch({ type: PATCH_EXAM_STATUS.SUCCESS });
    })
    .catch((error) => dispatch({ type: PATCH_EXAM_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createReportConfig = (obj) => (dispatch) => {
  dispatch({ type: CREATE_REPORT_CONFIG.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "reportConf",
    data: obj,
  })
    .then((data) => {
      dispatch({ type: CREATE_REPORT_CONFIG.SUCCESS });
    })
    .catch((error) => dispatch({ type: CREATE_REPORT_CONFIG.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getDiscResults =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().report;
    dispatch(showLoading());
    dispatch({ type: GET_DISC_RESULT_LIST.REQUEST });
    return getAPI({
      url: "discResultList",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_DISC_RESULT_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_DISC_RESULT_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigs =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_REPORT_CONFIG_LIST.REQUEST });
    return getAPI({
      url: "reportConfList",
      params: {
        ...mapReportConfigFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigById = (id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_REPORT_CONFIG_BY_ID.REQUEST });
  return getAPI({
    url: `reportConf/${id}`,
  })
    .then((data) => {
      console.log(data);
      dispatch({ type: GET_REPORT_CONFIG_BY_ID.SUCCESS, payload: data });
    })
    .catch((error) =>
      dispatch({ type: GET_REPORT_CONFIG_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const clearStateReportConfig = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_REPORT_CONFIG_BY_ID.SUCCESS, payload: {} });
  dispatch(hideLoading());
};

export const updateReportConfig = (reportConfigData, id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_REPORT_CONFIG.REQUEST });
  return patchAPI({
    url: `reportConf/${id}`,
    data: reportConfigData,
  })
    .then((data) => dispatch({ type: PATCH_REPORT_CONFIG.SUCCESS }))
    .catch((error) => dispatch({ type: PATCH_REPORT_CONFIG.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCampaigns =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_CAMPAIGN_LIST.REQUEST });
    return getAPI({
      url: "examCampaignList",
      params: {
        ...mapCampaignFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_CAMPAIGN_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_CAMPAIGN_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getReportConfigsDropdown =
  (filter = {}, skip = 0, limit = 1000) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_REPORT_CONFIG_LIST_DROPDOWN.REQUEST });
    return getAPI({
      url: "reportConfList",
      params: {
        ...mapReportConfigFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({
          type: GET_REPORT_CONFIG_LIST_DROPDOWN.SUCCESS,
          payload: data,
        })
      )
      .catch((error) =>
        dispatch({ type: GET_REPORT_CONFIG_LIST_DROPDOWN.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const generateReportConfigsCSV =
  (campaignId, reportConfId) => (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.REQUEST });
    return getAPI({
      url: `examCampaignList/${campaignId}?reportID=${reportConfId}`,
    })
      .then((data) => {
        console.log(data);
        if (data !== "[]") {
          csvExporter.generateCsv(data);
        }
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.SUCCESS });
      })
      .catch((error) =>
        dispatch({ type: GET_EXAM_CAMPAIGN_BY_DATE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateCampaignStatus = (campaignId) => (dispatch) => {
  dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `examCampaignList/${campaignId}`,
  })
    .then((data) => {
      dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.SUCCESS });
    })
    .catch((error) =>
      dispatch({ type: PATCH_EXAM_CAMPAIGN_STATUS.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const fetchExportReportMapping = (typeReport, id) => (dispatch) => {
  switch (typeReport) {
    case 1:
      dispatch(getCSVReport1(id));
      break;
    case 2:
      dispatch(getCSVReportExtra1(id));
      break;
    case 3:
      dispatch(getCSVReport2(id));
      break;
    case 4:
      dispatch(getCSVReportExtra2(id));
      break;
    case 5:
      dispatch(getCSVReport3(id));
      break;
    case 6:
      dispatch(getCSVReportExtra3(id));
      break;
    case 7:
      dispatch(exportReportExam1(id));
      break;
    case 8:
      dispatch(exportReportExam2(id));
      break;
    case 9:
      dispatch(exportReportExam3(id));
      break;
    case 10:
      dispatch(exportReportExam4(id));
      break;
    case 10:
      dispatch(exportReportExam4(id));
      break;
    case 11:
      dispatch(exportReportExam(id));
      break;
    default:
      break;
  }
};

export const getCSVReport1 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_1.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetOne/${id}`,
  })
    .then((data) => {
      
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_1.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) => dispatch({ type: GET_EXPORT_REPORT_1.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCSVReportExtra1 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_EXTRA_1.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetOneExtra/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXTRA_1.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXTRA_1.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCSVReport2 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_2.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetTwoNormal/${id}`,
  })
    .then((data) => {
      console.log(data);
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_2.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) => dispatch({ type: GET_EXPORT_REPORT_2.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCSVReportExtra2 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_EXTRA_2.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetTwoExtra/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXTRA_2.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXTRA_2.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCSVReport3 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_3.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetThree/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_3.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) => dispatch({ type: GET_EXPORT_REPORT_3.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCSVReportExtra3 = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXPORT_REPORT_EXTRA_3.REQUEST });
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetThreeExtra/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXTRA_3.SUCCESS });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXTRA_3.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const fetchReportMapping = (typeReport, filter) => (dispatch) => {
  switch (typeReport) {
    case 1:
      dispatch(getReport1(filter));
      break;
    case 2:
      dispatch(getReportExtra1(filter));
      break;
    case 3:
      dispatch(getReport2(filter));
      break;
    case 4:
      dispatch(getReportExtra2(filter));
      break;
    case 5:
      dispatch(getReport3(filter));
      break;
    case 6:
      dispatch(getReportExtra3(filter));
      break;
    case 7:
      dispatch(getReportExam1(filter));
      break;
    case 8:
      dispatch(getReportExam2(filter));
      break;
    case 9:
      dispatch(getReportExam3(filter));
      break;
    case 10:
      dispatch(getReportExam4(filter));
      break;
    case 11:
      dispatch(getReportExam(filter));
      break;

    default:
      break;
  }
};

export const getReportExam = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXAM.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExam`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXAM.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXAM.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReport1 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_1.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetOne`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_1.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_1.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExtra1 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXTRA_1.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetOneExtra`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXTRA_1.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXTRA_1.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
export const getReport2 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_2.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetTwoNormal`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_2.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_2.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExtra2 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXTRA_2.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetTwoExtra`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXTRA_2.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXTRA_2.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReport3 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_3.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetThree`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_3.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_3.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExtra3 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXTRA_3.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportTrainTestProfessionSheetThreeExtra`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXTRA_3.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXTRA_3.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExam1 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXAM_1.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamMember`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXAM_1.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXAM_1.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const exportReportExam1 = (id) => (dispatch) => {
  dispatch({ type: GET_EXPORT_REPORT_EXAM_1.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamMember/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXAM_1.SUCCESS, payload: data });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXAM_1.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExam2 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXAM_2.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamResultGroupbyEngineer`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXAM_2.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXAM_2.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const exportReportExam2 = (id) => (dispatch) => {
  dispatch({ type: GET_EXPORT_REPORT_EXAM_2.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamResultGroupbyEngineer/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXAM_2.SUCCESS, payload: data });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXAM_2.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExam3 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXAM_3.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamResultPassGroupbyEngineer`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXAM_3.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXAM_3.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const exportReportExam3 = (id) => (dispatch) => {
  dispatch({ type: GET_EXPORT_REPORT_EXAM_3.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExamResultPassGroupbyEngineer/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXAM_3.SUCCESS, payload: data });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXAM_3.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReportExam4 = (rawData) => (dispatch) => {
  dispatch({ type: GET_REPORT_EXAM_4.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportAmountExamPass`,
    params: {
      fromdate: rawData?.fromdate,
      todate: rawData?.todate,
    },
  })
    .then((data) => {
      dispatch({ type: GET_REPORT_EXAM_4.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_REPORT_EXAM_4.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const exportReportExam4 = (id) => (dispatch) => {
  dispatch({ type: GET_EXPORT_REPORT_EXAM_4.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportAmountExamPass/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXAM_4.SUCCESS, payload: data });
      } else throw "Empty data";
    })
    .catch((error) =>
      dispatch({ type: GET_EXPORT_REPORT_EXAM_4.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const exportReportExam = (id) => (dispatch) => {
  dispatch({ type: GET_EXPORT_REPORT_EXAM.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/reportExam/${id}`,
  })
    .then((data) => {
      if (data && data.length > 0) {
        csvExporter.generateCsv(data);
        dispatch({ type: GET_EXPORT_REPORT_EXAM.SUCCESS, payload: data });
      } else throw "Empty data";
    })
    .catch((error) => dispatch({ type: GET_EXPORT_REPORT_EXAM.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getBatchUpdate = () => (dispatch) => {
  dispatch({ type: GET_BATCH_UPDATE.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `cms/batchTrain`,
  })
    .then((data) => {
      dispatch({ type: GET_BATCH_UPDATE.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_BATCH_UPDATE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
