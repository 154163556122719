import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import formatValue from "utils/formatValue";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Radio from "components/form/Radio";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getMemberInfos,
  getCorporateInfos,
  removeDataCoporate,
  removeDataMember,
} from "redux/actions/callcenter";

const Div = styled.div`
  .content-wrapper {
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.w-100 {
          width: 100%;
        }
        &.end {
          justify-content: flex-end;
        }

        > .MuiFormControl-root {
          min-width: 200px;
          padding: 0 10px;
          width: auto;

          .MuiTextField-root {
            position: relative;
          }

          .MuiFormHelperText-root.Mui-error {
            position: absolute;
            bottom: -1.5rem;
            margin: 0;
          }
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .radio-group {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding-right: 10px;
  }

  .btn-serach {
    display: flex;
    flex-flow: row-reverse !important;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;

    button {
      width: 150px;
    }
  }

  .color-required {
    color: #f44336;
  }

  .extra-msg-search {
    position: absolute;
    margin-left: 228px;
    bottom: 0;
    font-size: 12px;
  }
`;

const initFilter = {
  first_name_th_th: "",
  last_name_th_th: "",
  id_card_no: "",
  member_no: "",
  name: "",
  license: "",
};
const PopupSearchCustomerCallCenter = ({
  open,
  onClose,
  linkCreate,
  isMembers = 1,
}) => {
  const dispatch = useDispatch();
  const { totalCustomSearch, members, corporates } = useSelector(
    (state) => state.callcenter
  );

  const [errors, setErrors] = useState({});

  const [isSearch, setIsSearch] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(totalCustomSearch);
  const [filter, setFilter] = useState(initFilter);
  const [dataList, setDataList] = useState([]);
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [isMember, setIsMember] = useState(isMembers);
  const [title_member_no, setTitle_member_no] = useState("เลขที่สมาชิก");
  const [title_fname, setTitle_fname] = useState("ชื่อ");
  const [search_type, setSearch_type] = useState("member");
  const getRowId = (row, relativeIndex, parent) => {
    // In row object you have access to data.
    // You can choose parameter. In this example I used uniqueId
    return parent ? [parent.id, row.uniqueId].join(".") : row.uniqueId;
  };
  const [memberNo, setMemberNo] = useState("");

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const formatIDCardNo = ({ value }) => {
    if (value === null || !value) return "-";
    let returnText = "";

    let addDash = [1, 5, 10, 12];

    for (let i = 0; i < value.length; i++) {
      if (i < 9) {
        returnText = returnText + `${addDash.includes(i) ? "-X" : "X"}`;
      } else {
        returnText =
          returnText +
          `${addDash.includes(i) ? `-${value[i]}` : `${value[i]}`}`;
      }
    }

    return returnText;
  };

  
  const formatBuddhistYear = ({value}) => {
    let momentDate = moment(value);
    var christianYear = momentDate.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    var format = "DD/MM/YYYY";
    return momentDate
        .format(
          format
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
  }

  const columnsMember = [
    {
      field: "member_no",
      headerName: "เลขที่สมาชิก",
      renderCell: ({ value }) => (
        <RouterLinkCell
          to={`/call-center/ticket/member/${value}?ismember=${isMember}`}
        >
          {value}
        </RouterLinkCell>
      ),
    },
    {
      field: "id_card_no",
      headerName: "เลขบัตรประจำตัวประชาชน",
      renderCell: formatIDCardNo,
    },

    {
      field: "title_name_th_th",
      headerName: "คำนำหน้า",
      renderCell: IsNullValue,
    },
    {
      field: "first_name_th_th",
      headerName: "ชื่อ",
      renderCell: IsNullValue,
    },
    {
      field: "last_name_th_th",
      headerName: "นามสกุล",
      renderCell: IsNullValue,
    },
    {
      field: "member_type_name_th_th",
      headerName: "ประเภทสมาชิก",
      renderCell: IsNullValue,
    },
    {
      field: "birthday",
      headerName: "วันเกิด",
      renderCell: formatBuddhistYear,
    },
    {
      field: "id",
      headerName: "Action",
      renderCell: ({ value }) => (
        <Button onClick={() => handleOpenPopup(value)}>สร้างรายการ</Button>
      ),
    },
  ];

  const columnsCorporate = [
    {
      field: "member_no",
      headerName: "เลขทะเบียนนิติบุคคล",
      // renderCell: ({ row }) => (
      //   <RouterLinkCell
      //     to={`/call-center/ticket/member/${row?.license_no}?ismember=${isMember}`}
      //   >
      //     {row?.member_no}
      //   </RouterLinkCell>
      // ),
      renderCell: ({ value }) => (
        <RouterLinkCell
          to={`/call-center/ticket/member/${value}?ismember=${isMember}`}
        >
          {value}
        </RouterLinkCell>
      ),
    },

    {
      field: "first_name_th_th",
      headerName: "ชื่อนิติบุคคล",
      renderCell: IsNullValue,
    },

    {
      field: "license_no",
      headerName: "เลขทะเบียนใบอนุญาต (นต.)",
      renderCell: IsNullValue,
    },
    {
      field: "id",
      headerName: "Action",
      renderCell: ({ value }) => (
        <Button
          onClick={() => handleOpenPopup(value)}
          style={{ padding: "8px !important" }}
        >
          สร้างรายการ
        </Button>
      ),
    },
  ];
  
  useEffect(() => {
    console.log("isMember", isMember);
    console.log("type isMember", typeof isMember);
    setDataList([]);
    setTotal(0);
    hamdleClearInput();
    if (isMember == 1) {
      setSearch_type("member");
    }
    if (isMember == 2) {
      setTitle_member_no("เลขทะเบียนนิติบุคคล");
      setSearch_type("corporate");
    }
  }, [isMember]);

  // handle method

  const handleSearchMember = () => {
    setPage(0);
    if (isMember === 1) {
      // search member
      let isValid = validate();
      if (isValid) {
        dispatch(getMemberInfos(filter)).then((_) => {
          setIsSearch(true);
        });
      }
    } else {
      // search corporate
      dispatch(getCorporateInfos(filter)).then((_) => {
        setIsSearch(true);
      });
    }
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
    validate({ [key]: value });
  };

  const handlePageMemberChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getMemberInfos(filter, nextPage * 10));
  };

  const handlePageCorporateChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getCorporateInfos(filter, nextPage * 10));
  };

  const handleOpenPopup = (v) => {
    let selectData = [];
    if (isMember == 1) {
      selectData = members.find((r) => r.id === v);
      //setMemberNo(selectData.member_no);
      onClose();
      linkCreate({ member_no: selectData.member_no, isMember: 1 });
    } else {
      selectData = corporates.find((r) => r.id === v);
      onClose();
      //linkCreate({ member_no: selectData.license_no, isMember: 2 });
      linkCreate({ member_no: selectData.member_no, isMember: 2 });
    }
  };

  useEffect(() => {
    dispatch(removeDataCoporate());
    dispatch(removeDataMember());
  }, []);

  const hamdleClearInput = () => {
    setFilter({
      first_name_th_th: "",
      last_name_th_th: "",
      id_card_no: "",
      member_no: "",
      name: "",
      license: "",
    });
  };

  useEffect(() => {
    setDataList(members);
  }, [members]);

  useEffect(() => {
    setDataList(corporates);
  }, [corporates]);

  useEffect(() => {
    setTotal(totalCustomSearch);
  }, [totalCustomSearch]);

  const closeModal = () => {
    dispatch(removeDataCoporate());
    dispatch(removeDataMember());
    onClose();
    setIsSearch(false);
  };

  const checkValiadteFilterMember = () => {
    if (isMember === 1) {
    } else return false;
  };

  const validate = (
    fieldValues = {
      ...filter,
    }
  ) => {
    let temp = { ...errors };
    if ("first_name_th_th" in fieldValues)
      temp.first_name_th_th =
        fieldValues.first_name_th_th !== "" ? "" : "กรุณากรอกชื่อจริง";
    if ("last_name_th_th" in fieldValues)
      temp.last_name_th_th =
        fieldValues.last_name_th_th !== "" ? "" : "กรุณากรอกนามสกุล";
    if ("id_card_no" in fieldValues)
      temp.id_card_no =
        fieldValues.id_card_no !== ""
          ? ""
          : "กรุณากรอกเลขบัตรประชาชน 4 ตัวหลัง";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  return (
    <BaseModal open={open} onClose={closeModal} maxWidth={"xl"} fullWidth>
      <Div>
        <div className="title"></div>
        <div className="content-wrapper">
          {/* <RendererFilterPanel /> */}
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">ค้นหาข้อมูล</div>
              <div className="group-field-filter">
                <div className="group-field w-100">
                  <div className="radio-group">
                    <Radio
                      className="radio-filter-type"
                      label="สมาชิก"
                      id="is_member"
                      name={"is_member_radio"}
                      checked={isMember === 1}
                      onChange={() => setIsMember(1)}
                    />
                    <Radio
                      className="radio-filter-type"
                      label="นิติบุคคล"
                      id="is_member"
                      name={"is_member_radio"}
                      checked={isMember === 2}
                      onChange={() => setIsMember(2)}
                    />
                  </div>

                  {isMember === 1 ? (
                    <>
                      <TextField
                        label={
                          <div>
                            ชื่อ <span className="color-required">*</span>{" "}
                          </div>
                        }
                        value={filter.first_name_th_th}
                        onChange={(e) =>
                          handleFilterChange("first_name_th_th", e.target.value)
                        }
                        name="first_name_th_th"
                        error={errors.first_name_th_th}
                      />
                      <TextField
                        label={
                          <div>
                            นามสกุล <span className="color-required">*</span>{" "}
                          </div>
                        }
                        value={filter.last_name_th_th}
                        onChange={(e) =>
                          handleFilterChange("last_name_th_th", e.target.value)
                        }
                        name="last_name_th_th"
                        error={errors.last_name_th_th}
                      />
                      <TextField
                        label={
                          <div>
                            เลขบัตรประชาชน 4 ตัวหลัง{" "}
                            <span className="color-required">*</span>{" "}
                          </div>
                        }
                        value={filter.id_card_no}
                        onChange={(e) =>
                          handleFilterChange("id_card_no", e.target.value)
                        }
                        name="id_card_no"
                        error={errors.id_card_no}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        label={title_member_no}
                        value={filter.member_no}
                        onChange={(e) =>
                          handleFilterChange("member_no", e.target.value)
                        }
                        name="member_no"
                        placeholder={
                          "1234567890123"
                        }
                      />
                      <TextField
                        label={"เลขทะเบียนใบอนุญาต (นต.)"}
                        value={filter.license}
                        onChange={(e) =>
                          handleFilterChange("license", e.target.value)
                        }
                        name="license"
                        placeholder={
                          "นต.1234"
                        }
                      />
                      <TextField
                        label={"ค้นหาชื่อนิติบุคคล"}
                        value={filter.name}
                        onChange={(e) =>
                          handleFilterChange("name", e.target.value)
                        }
                        name="name"
                      />
                    </>
                  )}

                  {/* <Button onClick={() => handleClosePopup()} outlined>Cancel{" "}</Button> */}
                </div>
                <div className="btn-serach">
                  <Button
                    onClick={() => handleSearchMember()}
                    // disabled={checkValiadteFilterMember()}
                  >
                    ค้นหา
                  </Button>
                  <Button onClick={() => closeModal()} outlined="true">
                    ยกเลิก{" "}
                  </Button>
                </div>
              </div>
            </div>
            {dataList?.length === 0 && isSearch && search_type == "member" ? (
              <div className="extra-msg-search color-required">
                กรุณากรอกข้อมูลให้ถูกต้องครบถ้วนทั้ง 3 รายการ (Exact Match)
              </div>
            ) : (
              ""
            )}
          </div>

          {dataList?.length === 0 && isSearch && search_type == "member" ? (
            <div style={{ height: "10px" }}></div>
          ) : (
            ""
          )}

          {search_type == "member" && (
            <Table
              columns={columnsMember}
              rows={dataList}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              rowCount={total}
              pageSize={10}
              page={page}
              onPageChange={handlePageMemberChange}
              getRowId={getRowId}
              textNoRow={
                isSearch ? "ไม่พบข้อมูลสมาชิก หรือ ข้อมูลไม่ถูกต้อง" : "No Rows"
              }
            />
          )}
          {search_type == "corporate" && (
            <Table
              columns={columnsCorporate}
              rows={dataList}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              rowCount={total}
              pageSize={10}
              page={page}
              onPageChange={handlePageCorporateChange}
              getRowId={getRowId}
              textNoRow={
                isSearch ? "ไม่พบข้อมูลสมาชิก หรือ ข้อมูลไม่ถูกต้อง" : "No Rows"
              }
            />
          )}
        </div>
        {/* <div className="button-wrapper">
          <Button onClick={onCloseChildren}>Add</Button>
          <Button onClick={onCloseChildren} outlined>
            Close
          </Button>
        </div> */}
      </Div>
    </BaseModal>
  );
};

export default PopupSearchCustomerCallCenter;
