import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";

const MOCK_DATA = [
  {
    id: 1,
    a: 1000001,
    b: "",
    c: "",
    d: "",
    e: 12345,
    f: "สมชาย",
    g: "ประเสริฐ",
    h: "2,000 บาท",
    i: "Admin01",
  },
];

const Div = styled.div`
  p {
    margin-top: 0;
  }

  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 2.25em;
        font-weight: bold;
        color: #982436;
      }

      .group-subtitle {
        font-size: 2em;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          height: 47px;
          margin: 0 10px;
        }
      }
    }
  }
`;

const Reconcile = () => {
  const dispatch = useDispatch();

  return (
    <SidebarLayout title="Accouting Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-balance", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">กระทบยอด</div>
              <div className="group-subtitle">รายงานบัญชีต่างๆ</div>
              <div className="group-field">
                <TextField
                  label="วันที่ชำระเงิน"
                  type="date"
                  value={""}
                  placeholder="Start Date"
                />

                <Select
                  name={"reportConfId"}
                  items={[]}
                  value={""}
                  defaultValue="ตัวแทนรับชำระ"
                  onChange={(e) => console.log(e)}
                />
              </div>
              <div className="group-field">
                <TextField
                  label="กรอกจำนวนเงิน"
                  value={""}
                  placeholder="กรุณากรอกจำนวนเงิน"
                />
                <Button onClick={() => {}}>ตรวจสอบ</Button>
              </div>
            </div>
          </div>
          <br />
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default Reconcile;
