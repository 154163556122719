import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import Grid from "@mui/material/Grid";
import Radio from "components/form/Radio";
import Select from "components/form/Select";
import moment from "moment";

// import {} from "redux/actions/notification";
import TextField from "components/form/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import TextFieldArea from "components/form/TextFieldArea";
import {
  createTicket,
  getMemberInfoById,
  getAllTicketType,
  getMemberInfos,
  getCorporateInfos,
  searchMemberInfos,
  searchCorporateInfos,
} from "redux/actions/callcenter";
import { el } from "date-fns/locale";
import PopupSearchCustomerCallCenter from "containers/popup/PopupSearchCustomerCallCenter";

const Div = styled.div`
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .row-question {
      display: flex;
      column-gap: 20px;
    }
    .question-group {
      margin-bottom: 10px;

      &.flex-1 {
        flex: 1;
      }

      &.radio {
        margin-left: -12px;
      }
    }

    .question-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .div-group-field-left {
    width: 80%;
    float: left;
  }
  .div-group-field-right {
    float: left !important;
    padding-left: 10px !important;
  }

  .question-item {
    margin-bottom: 1rem;
  }

  .find-with-name {
    color: #0075ca;
    text-decoration: underline;
    clear: both;
    &:hover {
      cursor: pointer;
    }
  }
`;

// const ticketType = [
//   { value: "ติดตามใบอนุญาต", text: "ติดตามใบอนุญาต" },
//   { value: "สอบถามขั้นตอนการต่ออายุใบอนุญาต", text: "สอบถามขั้นตอนการต่ออายุใบอนุญาต" },
//   { value: "สอบถามขั้นตอนการสอบภาคี", text: "สอบถามขั้นตอนการสอบภาคี" },
//   { value: "สอบถามขั้นตอนการสอบอบรม", text: "สอบถามขั้นตอนการสอบอบรม" },
//   { value: "ขอใบอนุญาตนิติบุคคล", text: "ขอใบอนุญาตนิติบุคคล" },
//   { value: "ต่อใบอนุญาตนิติบุคคล", text: "ต่อใบอนุญาตนิติบุคคล" },
//   { value: "สอบถามเรื่องการขอวุฒิบัตร", text: "สอบถามเรื่องการขอวุฒิบัตร" },
//   { value: "สอบถามขั้นตอนการขอหนังสือรับรอง", text: "สอบถามขั้นตอนการขอหนังสือรับรอง" },
//   { value: "สอบถามขั้นตอนการขอเลื่อนระดับ", text: "สอบถามขั้นตอนการขอเลื่อนระดับ" },
//   { value: "สอบถามการรับรองปริญญา", text: "สอบถามการรับรองปริญญา" },
//   { value: "ติดต่อสำนักกฎหมายและจรรยาบรรณ", text: "ติดต่อสำนักกฎหมายและจรรยาบรรณ" },
//   { value: "สอบถามขั้นตอนการขอใบแทนใบอนุญาต", text: "สอบถามขั้นตอนการขอใบแทนใบอนุญาต" },
//   { value: "อื่นๆ", text: "อื่นๆ" },
// ];

const statusList = [
  { value: "open", text: "เปิด" },
  { value: "openclose", text: "เปิดและปิด" },
];

const initFrom = {
  member_no: "",
  first_name: "",
  last_name: "",
  phone_no: "",
  ticket_type: "",
  description: "",
  ticket_date: "",
  ticket_time: "",
  time_call: "",
};

const PopupCreateTicketCallCenter = ({
  open,
  onClose,
  label,
  maxWidth = "xl",
  memberNo,
  isMemberRef,
}) => {
  const dispatch = useDispatch();
  const { memberInfoByMemNo, tickettypes, members, corporates } = useSelector(
    (state) => state.callcenter
  );
  const [from, setFrom] = useState(initFrom);
  const [errors, setErrors] = useState({});
  const [ticketType, setTicketType] = useState();
  const [isMember, setIsMember] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const [member_no, setMember_no] = useState("");
  const [license, setLicense] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [ticket_type, setTicket_type] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [licenses_no, setLicenses_no] = useState("");

  const [ticket_date, setTicket_date] = useState("");
  const [ticket_time, setTicket_time] = useState("");
  const [ticket_end_date, setTicket_end_date] = useState("");
  const [ticket_end_time, setTicket_end_time] = useState("");
  const [time_call, setTime_call] = useState("");
  const [ticketStart, setTicketStart] = useState(moment());
  const [ticketEnd, setTicketEnd] = useState(moment());
  const [title_member_no, setTitle_member_no] = useState(
    "เลขที่สมาชิก / เลขที่ใบอนุญาต"
  );
  const [title_fname, setTitle_fname] = useState("ชื่อ");
  const [is_render_member_no, setIs_render_member_no] = useState(true);
  const [is_render_lname, setIs_render_lname] = useState(true);
  let is_render_fname = true;
  const [isOpenPopupSearchUser, setIsOpenPopupSearchUser] = useState(false);
  const [memberNoSearch, setMemberNoSearch] = useState(memberNo);

  // useEffect
  useEffect(() => {
    setTimeout(() => {
      setIsMember(1);
    }, "1000");
    requestAllTicketsType();
    handleExistMember();
    setTicketStart(moment());

    let ticketStartDate = moment(ticketStart).format("YYYY-MM-DD");
    let ticketStartTime = moment(ticketStart).format("HH:mm:ss");
    setTicket_date(ticketStartDate);
    setTicket_time(ticketStartTime);
  }, []);

  useEffect(() => {
    if (tickettypes?.length > 0) {
      const ticket_types = tickettypes.map((v) => ({
        value: v.id,
        text: v.type_name,
      }));
      setTicketType(ticket_types);
    }
  }, [tickettypes]);

  useEffect(() => {
    console.log("memberNoSearch:", memberNoSearch);
    setMember_no("");
    setFirst_name("");
    setLast_name("");
    setPhone_no("");
    setLicenses_no("");
    if (memberNoSearch !== "") {
      setIsDisabled(true);
      //setMember_no(memberNo);

      setTimeout(() => {
        setIsMember(isMemberRef);
        handleSearchMember();
      }, "1000");
      //handleSearchMemberByRef();

      console.log("isMemberRef:", isMemberRef);
      console.log("isMember:", isMember);
    }
  }, [memberNoSearch]);

  useEffect(() => {
    console.log(ticket_end_date);
  }, [ticket_end_date]);

  useEffect(() => {
    console.log("App comp value:", isMember);
    setIsMember(isMember);
    if (isMember == 1) {
      setTitle_member_no("เลขที่สมาชิก / เลขที่ใบอนุญาต");
      setTitle_fname("ชื่อ");
      setIs_render_member_no(true);
      setIs_render_lname(true);
      setIsDisabled(true);
    }
    if (isMember == 2) {
      setTitle_member_no("เลขทะเบียนใบอนุญาต / เลขทะเบียนนิติบุคคล (13 หลัก)");
      setTitle_fname("ชื่อนิติบุคคล");
      setIs_render_member_no(true);
      setIs_render_lname(false);
      setIsDisabled(true);
    }
    if (isMember == 3) {
      setTitle_fname("ชื่อ");
      setIs_render_member_no(false);
      setIs_render_lname(false);
      setIsDisabled(false);
    }
  }, [isMember]);

  useEffect(() => {
    console.log(ticket_end_date);
  }, [ticket_end_date]);

  useEffect(() => {
    console.log("title_member_no", title_member_no);
  }, [title_member_no]);

  // handle
  const requestAllTicketsType = async () => {
    dispatch(getAllTicketType());
  };

  const handleExistMember = () => {
    console.log("handleExistMember");
    if (memberNoSearch && memberNoSearch !== "") {
      dispatch(getMemberInfoById(memberNoSearch));
      setMember_no(memberInfoByMemNo?.member_no);
      setFirst_name(memberInfoByMemNo?.first_name_th_th);
      setLast_name(memberInfoByMemNo?.last_name_th_th);
      setPhone_no(memberInfoByMemNo?.phone_no);
      setLicenses_no(memberInfoByMemNo?.licenses_no ?? "-");
    }
  };

  const handleConfirm = async () => {
    let endDateTime = handleSetTicketEnd();
    var minutesDiff = endDateTime.endDate.diff(ticketStart, "minutes");
    var secondsDiff = endDateTime.endDate.diff(ticketStart, "seconds");
    const time_diff = minutesDiff + secondsDiff / 100;
    let ticket_member_type = "";

    if (isMember == 1) {
      ticket_member_type = "member";
    }
    if (isMember == 2) {
      ticket_member_type = "corporate";
    }
    if (isMember == 3) {
      ticket_member_type = "other";
    }

    if (validate()) {
      const obj = {
        member_no,
        first_name,
        last_name,
        phone_no,
        ticket_type,
        description,
        ticket_date,
        ticket_time,
        ticket_end_date: endDateTime.ticketEndDate,
        ticket_end_time: endDateTime.ticketEndTime,
        time_call: time_diff,
        ticket_member_type,
        status,
      };
      console.log(obj);
      dispatch(createTicket(obj)).then((res) => {
        const { payload } = res;

        if (payload) {
          onClose();
        }
      });
    }
  };

  const handleOpenPopupSearchUser = () => {
    setIsOpenPopupSearchUser(true);
  };

  const validate = (
    fieldValues = {
      member_no,
      first_name,
      last_name,
      ticket_type,
      description,
      status,
      // ticket_date,
      // ticket_time,
      // time_call
    }
  ) => {
    let temp = { ...errors };
    //console.log(fieldValues);

    if ("member_no" in fieldValues && isMember == 1)
      temp.member_no =
        fieldValues.member_no !== ""
          ? ""
          : "กรุณากรอกเลขที่สมาชิก / เลขที่ใบอนุญาต";
    if ("member_no" in fieldValues && isMember == 2)
      temp.member_no =
        fieldValues.member_no !== ""
          ? ""
          : "กรุณากรอกเลขทะเบียนใบอนุญาต / เลขทะเบียนนิติบุคคล (13 หลัก)";
    if ("first_name" in fieldValues)
      temp.first_name =
        fieldValues.first_name !== "" ? "" : "กรุณากรอกชื่อจริง";
    if ("last_name" in fieldValues && isMember == 1)
      temp.last_name = fieldValues.last_name !== "" ? "" : "กรุณากรอกนามสกุล";
    if ("phone_no" in fieldValues)
      temp.phone_no = fieldValues.phone_no !== "" ? "" : "กรุณากรอกเบอร์โทร";
    if ("ticket_type" in fieldValues)
      temp.ticket_type =
        fieldValues.ticket_type !== "" ? "" : "กรุณาเลือกหัวข้อเรื่อง";
    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกเนื้อหา";
    if ("status" in fieldValues)
      temp.status = fieldValues.status !== "" ? "" : "กรุณาเลือก status";
    // if ("ticket_date" in fieldValues)
    //   temp.ticket_date = fieldValues.ticket_date !== "" ? "" : "กรุณากรอกวันที่โทรเข้ามา";
    // if ("ticket_time" in fieldValues)
    //   temp.ticket_time = fieldValues.ticket_time !== "" ? "" : "กรุณากรอกเวลา";
    // if ("time_call" in fieldValues)
    //   temp.time_call = fieldValues.time_call !== "" ? "" : "กรุณากรอกระยะเวลาการโทร";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSearchMember = async (v = {}) => {
    console.log("isMemberRef:", isMemberRef);
    console.log("action:", v.action);
    if (v && v.action == "create") {
      //isMember = null;
      //setIsMember(isMember)
      handleChangeOption2();
    } else {
      //setIsMember(isMemberRef)
      handleChangeOption2();
    }
    if (member_no != "" || memberNoSearch != "") {
      let member_no_filter = member_no ? member_no : memberNoSearch;
      let isMember_filter = isMember ? isMember : isMember;
      // let isMember_filter = isMember;
      setIsMember(isMember_filter);
      console.log("isMember:", isMember_filter);
      if (isMember_filter == 1) {
        // search member

        //console.log("handleSearchMember:",member_no_filter);
        dispatch(searchMemberInfos({ member_no: member_no_filter })).then(
          (res) => {
            const { data } = res;
            if (data.length > 0) {
              setIsDisabled(true);
              setMember_no(data[0]?.member_no);
              setFirst_name(data[0]?.first_name_th_th);
              setLast_name(data[0]?.last_name_th_th);
              setPhone_no(data[0]?.phone_no);
              setLicenses_no(data[0]?.licenses_no ?? "-");
            } else {
              let temp = { ...errors };
              temp.member_no = "ไม่มีเลขที่สมาชิก";
              setErrors({ ...temp });
              //console.log("error:",Object.values(temp).every((x) => x === ""));
              setMember_no(member_no_filter);
              setIsDisabled(false);
              setStatus("");
              setFirst_name("");
              setLast_name("");
              setPhone_no("");
              setLicenses_no("");
            }
          }
        );
      } else {
        // search corporate
        if (v && v.action == "create") {
          dispatch(searchCorporateInfos({ license: member_no_filter })).then(
            (res) => {
              const { data } = res;
              console.log(data);

              if (data.length > 0) {
                setIsDisabled(true);
                setMember_no(data[0]?.member_no);
                setLicense(data[0]?.license_no);
                setFirst_name(data[0]?.first_name_th_th);
                setLast_name(data[0]?.last_name_th_th);
                setPhone_no(data[0]?.phone_no ?? "-");
                setLicenses_no(data[0]?.licenses_no ?? "-");
              } else {
                let temp = { ...errors };
                temp.member_no = "ไม่มีเลขทะเบียนนิติบุคคล";
                setErrors({ ...temp });
                setMember_no(member_no_filter);
                setIsDisabled(false);
                setFirst_name("");
                setLast_name("");
                setPhone_no("");
                setLicenses_no("");
                setStatus("");
              }
            }
          );
        } else {
          dispatch(getCorporateInfos({ member_no: member_no_filter })).then(
            (res) => {
              const { data } = res;
              console.log(data);

              if (data.length > 0) {
                setIsDisabled(true);
                setMember_no(data[0]?.member_no);
                setLicense(data[0]?.license_no);
                setFirst_name(data[0]?.first_name_th_th);
                setLast_name(data[0]?.last_name_th_th);
                setPhone_no(data[0]?.phone_no ?? "-");
                setLicenses_no(data[0]?.licenses_no ?? "-");
              } else {
                setIsDisabled(false);
                setFirst_name("");
                setLast_name("");
                setPhone_no("");
                setLicenses_no("");
                setStatus("");
              }
            }
          );
        }
      }
    }
  };

  // const handleSearchMemberByRef = async () => {
  //   if (memberNo != "") {
  //     if (isMember === 1) {
  //       // search member
  //       console.log("handleSearchMemberByRef");
  //       dispatch(getMemberInfos({ memberNo })).then((res) => {
  //         const { data } = res;
  //         if (data.length > 0) {
  //           setMember_no(data[0]?.member_no);
  //           setFirst_name(data[0]?.first_name_th_th);
  //           setLast_name(data[0]?.last_name_th_th);
  //           setPhone_no(data[0]?.phone_no);
  //         } else {
  //           setIsDisabled(false);
  //         }
  //       });
  //     } else {
  //       // search corporate
  //       dispatch(getCorporateInfos({ memberNo })).then((res) => {
  //         const { data } = res;
  //         if (data.length > 0) {
  //           setMember_no(data[0]?.license_no);
  //           setFirst_name(data[0]?.first_name_th_th);
  //           setLast_name(data[0]?.last_name_th_th);
  //           setPhone_no(data[0]?.phone_no);
  //         }
  //       });
  //     }
  //   }
  // };

  const handleSetTicketEnd = () => {
    const endDate = moment();
    setTicketEnd(endDate);
    let ticketEndDate = moment(endDate).format("YYYY-MM-DD");
    let ticketEndTime = moment(endDate).format("HH:mm:ss");
    setTicket_end_date(ticketEndDate);
    setTicket_end_time(ticketEndTime);
    return { ticketEndDate, ticketEndTime, endDate };
  };

  const handleChangeOption = () => {
    setMember_no("");
    setLicense("");
    setStatus("");
    setFirst_name("");
    setLast_name("");
    setPhone_no("");
    setTicket_type("");
    setLicenses_no("");
  };

  const handleChangeOption2 = () => {
    setMember_no("");
    setLicense("");
    setStatus("");
    setFirst_name("");
    setLast_name("");
    setPhone_no("");
    setLicenses_no("");
  };

  const changeTitleMemberNot = (v) => {
    let title = "";
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      memberNo={memberNoSearch}
    >
      <Div>
        {Boolean(isOpenPopupSearchUser) && (
          <PopupSearchCustomerCallCenter
            open
            onClose={(v) => {
              //setMemberNoSearch("");
              //setIsMember(1)
              //handleSearchTicket();

              setIsOpenPopupSearchUser(false);
              //setIsOpenPopupCreateTicket(true);
              //handleClosePopup(v);
            }}
            linkCreate={(v) => {
              console.log("linkCreate:", v);
              setMemberNoSearch(v.member_no);
              setIsMember(v.isMember);
              setIsOpenPopupSearchUser(false);
              // setIsOpenPopupCreateTicket(true);
            }}
            isMembers={parseInt(isMember)}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">บันทึกการให้บริการ</div>
            <div className="question-group">
              <div className="question-title">หัวข้อเรื่อง</div>
              <div className="question-item">
                <Select
                  items={ticketType}
                  value={ticket_type}
                  defaultValue="Ticket Type"
                  // onChange={(e) =>
                  //   handleFromChange("ticket_type", e.target.value)
                  // }
                  onChange={(e) => {
                    setTicket_type(e.target.value);
                    validate({ ["ticket_type"]: e.target.value });
                  }}
                  errorText={errors.ticket_type}
                  isError={errors.ticket_type ? true : false}
                />
              </div>
            </div>
            <div className="question-from">
              <div className="question-group radio">
                <div className="question-item">
                  <Radio
                    className="radio-filter-type"
                    label="สมาชิก"
                    id="is_member"
                    name={"is_member_radio"}
                    checked={isMember == 1}
                    value={1}
                    onChange={(e) => {
                      handleChangeOption();
                      setIsMember(e.target.value);
                    }}
                  />
                  <Radio
                    className="radio-filter-type"
                    label="นิติบุคคล"
                    id="is_member"
                    name={"is_member_radio"}
                    checked={isMember == 2}
                    value={2}
                    onChange={(e) => {
                      handleChangeOption();
                      setIsMember(e.target.value);
                    }}
                  />
                  <Radio
                    className="radio-filter-type"
                    label="อื่นๆ"
                    id="is_member"
                    name={"is_member_radio"}
                    checked={isMember == 3}
                    value={3}
                    onChange={(e) => {
                      handleChangeOption();
                      setIsMember(e.target.value);
                    }}
                  />
                </div>
                <div className="question-item"></div>
              </div>

              {is_render_member_no == true && (
                <div className="question-group">
                  <div className="question-title">{title_member_no}</div>
                  <div className="question-item">
                    <div className="div-group-field-left">
                      <TextField
                        value={
                          title_member_no === "เลขที่สมาชิก / เลขที่ใบอนุญาต"
                            ? member_no
                            : license
                        }
                        type="text"
                        onChange={(e) => {
                          if (
                            title_member_no === "เลขที่สมาชิก / เลขที่ใบอนุญาต"
                          ) {
                            setMember_no(e.target.value);
                            validate({ ["member_no"]: e.target.value });
                          } else {
                            setMember_no(e.target.value);
                            setLicense(e.target.value);
                          }
                        }}
                        // onChange={(e) => handleFromChange("member_no", e.target.value)}
                        error={errors.member_no}
                        placeholder={
                          isMember == 1
                            ? "เลขที่สมาชิก หรือ ภย.12345"
                            : "นต.1999 หรือ 1234567890123"
                        }
                      />
                    </div>
                    {/* <div className="div-group-field-right"> */}
                    <div>
                      <Button
                        onClick={() => handleSearchMember({ action: "create" })}
                        style={{
                          padding: "9px 20px !important",
                          marginLeft: "18px",
                        }}
                      >
                        ค้นหา
                      </Button>
                    </div>
                  </div>
                  <div className="question-item">
                    <div
                      className="find-with-name"
                      onClick={() => handleOpenPopupSearchUser()}
                    >
                      ค้นหาข้อมูลด้วยชื่อ
                    </div>
                  </div>
                </div>
              )}

              <div className="row-question">
                <div className="question-group flex-1">
                  <div className="question-title">{title_fname}</div>
                  <div className="question-item">
                    <TextField
                      value={first_name}
                      //onChange={(e) => handleFromChange("first_name", e.target.value)}
                      onChange={(e) => {
                        setFirst_name(e.target.value);
                        validate({ ["first_name"]: e.target.value });
                      }}
                      // onChange={(e) => handleFromChange("member_no", e.target.value)}
                      error={errors.first_name}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                {is_render_lname == true && (
                  <div className="question-group flex-1">
                    <div className="question-title">นามสกุล</div>
                    <div className="question-item">
                      <TextField
                        value={last_name}
                        //onChange={(e) => handleFromChange("last_name", e.target.value)}
                        onChange={(e) => {
                          setLast_name(e.target.value);
                          validate({ ["last_name"]: e.target.value });
                        }}
                        error={errors.last_name}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="question-group">
                <div className="question-title">เบอร์โทร</div>
                <div className="question-item">
                  <TextField
                    value={phone_no}
                    type="tel"
                    onChange={(e) => {
                      setPhone_no(e.target.value);
                      validate({ ["phone_no"]: e.target.value });
                    }}
                    error={errors.phone_no}
                    disabled={isDisabled}
                    //onChange={(e) => handleFromChange("phone_no", e.target.value)}
                  />
                </div>
              </div>

              <div className="question-group">
                <div className="question-title">เลขที่ใบอนุญาต</div>
                <div className="question-item">
                  <TextField
                    value={licenses_no}
                    type="text"
                    onChange={(e) => {
                      setLicenses_no(e.target.value);
                      // validate({ ["license"]: e.target.value });
                    }}
                    error={errors.license}
                    disabled={isDisabled}
                    //onChange={(e) => handleFromChange("phone_no", e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="question-group">
                <div className="question-title">วันที่โทรเข้ามา</div>
                <div className="question-item">
                  <TextField
                    value={ticket_date}
                    type="date"
                    //onChange={(e) => handleFromChange("ticket_date", e.target.value)}
                    onChange={(e) => {
                      setTicket_date(e.target.value);
                      validate({ ["ticket_date"]: e.target.value });
                    }}
                    error={errors.ticket_date}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">เวลา</div>
                <div className="question-item">
                  <TextField
                    value={ticket_time}
                    type="time"
                    onChange={(e) => {
                      setTicket_time(e.target.value);
                      validate({ ["ticket_time"]: e.target.value });
                    }}
                    error={errors.ticket_time}
                    //onChange={(e) => handleFromChange("ticket_time", e.target.value)}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">ระยะเวลาการโทร</div>
                <div className="question-item">
                  <TextField
                    value={time_call}
                    type="number"
                    onChange={(e) => {
                      setTime_call(e.target.value);
                      validate({ ["time_call"]: e.target.value });
                    }}
                    error={errors.time_call}
                    //onChange={(e) => handleFromChange("time_call", e.target.value)}
                  />
                </div>
              </div> */}

              <div className="question-group">
                <div className="question-title">สถานะ</div>
                <div className="question-item">
                  <Select
                    items={statusList}
                    value={status}
                    defaultValue="Status Type"
                    onChange={(e) => {
                      setStatus(e.target.value);
                      validate({ ["status"]: e.target.value });
                    }}
                    errorText={errors.status}
                    isError={errors.status ? true : false}
                  />
                </div>
              </div>

              <div className="question-group">
                <div className="question-title">รายละเอียด</div>
                <div className="question-item">
                  <TextFieldArea
                    value={description}
                    // onChange={(e) =>
                    //   handleFromChange("description", e.target.value)
                    // }
                    onChange={(e) => {
                      setDescription(e.target.value);
                      validate({ ["description"]: e.target.value });
                    }}
                    isError={errors.description ? true : false}
                    errorText={errors.description}
                    placeholder="Message"
                    maxRows={6}
                    minRows={6}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined="true">
            ยกเลิก{" "}
          </Button>
          <Button onClick={() => handleConfirm()}>บันทึก</Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupCreateTicketCallCenter;
