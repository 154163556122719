import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import TextField from "components/form/TextField";
import ButtonBlack from "components/form/ButtonBlack";
import { Image as ImageIcon } from "@material-ui/icons";

import { uploadImage } from "redux/actions/share";
import Button from "components/form/Button";

const Div = styled.div`
  min-width: ${(props) => props.minWidth};
  .upload-wrapper {
    .topic {
      font-size: 20px;
      margin: 10px 0;
      font-weight: bold;
    }
    label {
      background-color: #e6e5e5f0;
      width: 100%;
      height: 172px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;

      svg {
        font-size: 5rem;
      }
    }
    input[type="file"] {
      display: none;
    }

    .preview-image {
      width: 100%;
      object-fit: contain;
      object-position: center;
      height: 150px;
      transition: all 0.1s linear;
      :hover {
        height: 300px;
        z-index: 999;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      label {
        color: inherit;
        height: auto;
        background-color: inherit;
      }
    }
  }
`;
const UploadImage = ({
  label,
  inputID,
  minWidth = "395px",
  setURLImage,
  indexForSubForm = 99,
  defaultImage = "",
  required = false,
  index = null,
  setIndexImage,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(defaultImage);

  const onChangeUpload = (e) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setURLImage(path);
      setUrl(path);

      if (index !== null) setIndexImage({ index: index, path: path });
    });
    let iconEle = document.getElementById("icon-image");

    if (iconEle) {
      iconEle.style.display = "none";
    }
  };

  const hadndleRemoveImage = () => {
    setURLImage();
    setUrl();

    if (index !== null) setIndexImage({ index: index, path: "" });
  };

  return (
    <Div minWidth={minWidth}>
      <div className="upload-wrapper">
        {Boolean(label) && <p className="topic">{label}</p>}
        <label id={`label_${inputID}`} htmlFor={inputID}>
          {!url ? (
            <ImageIcon id="icon-image" />
          ) : (
            <img
              id={`label_${inputID}`}
              onChange={(e) => onChangeUpload(e)}
              src={url}
              alt="preview"
              className="preview-image"
            />
          )}
        </label>
        {url && (
          <div style={{ display: "flex",justifyContent:"center" }}>
            <ButtonBlack>
              <label htmlFor={inputID}>change</label>
            </ButtonBlack>
            <Button onClick={() => hadndleRemoveImage()}>
              <label htmlFor={inputID}>remove</label>
            </Button>
          </div>
        )}
        <input
          id={inputID}
          type="file"
          required={required}
          onChange={(e) => onChangeUpload(e)}
        />
      </div>
    </Div>
  );
};

export default UploadImage;
