import React from 'react'
import { useSelector } from 'react-redux'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { selectLoading } from 'redux/selectors'

const useStyles = makeStyles({
  backdrop: {
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 9999,
    color: '#fff',
  },
})

export default () => {
  const classes = useStyles()
  const loading = useSelector(selectLoading)

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
