import {
  GET_EXAM_USER_LIST,
  GET_EXAM_USER_DETAIL,
  GET_EXAM_USER_DETAIL_SUBJECT_LIST,
  EDIT_EXAM_USER_DETAIL,
  GET_EXAM_QUESTION_LIST,
  GET_EXAM_QUESTION_BY_ID,
  CREATE_EXAM_QUESTION,
  GET_EXAM_QUESTION_CHOICE_BY_ID,
  PATCH_EXAM_QUESTION_CHOICE_BY_ID,
  PATCH_EXAM_QUESTION_BY_ID,
  PATCH_EXAM_QUESTION_STATUS_BY_ID,
  APPROVE_EXAM_QUESTION,
  GET_EXAM_SET_LIST,
  GET_RANDOM_EXAM,
  GET_ALL_QUESTION_ACTIVE,
  GET_ALL_DATA_QUESTION_ACTIVE,
  GET_ALL_DATA_EXAM_QUESTION_LIST,
  CREATE_EXAM_SET,
  GET_EXAM_SET_BY_ID,
  PATCH_EXAM_SET,
  CLEAR_EXAM_SET,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

const mapFilter = (v) => ({
  fname: v.firstName || null,
  lname: v.lastName || null,
  date_exam: v.dateExam || null,
});

const mapFilterExamQuestion = (v) => ({
  code: v.code || null,
  exam_owner: v.examOwner || null,
  subject_id: v.subjectId || null,
  topic_name: v.topicName || null,
  level: v.level || null,
  status: v.status || null,
  question: v.question || null,
});

export const getExamUserList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_USER_LIST.REQUEST });
    return getAPI({
      url: "examUserList",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_EXAM_USER_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_EXAM_USER_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getExamUserDetail = (token) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_USER_DETAIL.REQUEST });
  return getAPI({
    url: `examUserList/${token}`,
    params: {
      flag: "history",
    },
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_USER_DETAIL.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_EXAM_USER_DETAIL.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const editExamUserDetail = (token, body) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: EDIT_EXAM_USER_DETAIL.REQUEST });
  return patchAPI({
    url: `examUserList/${token}`,
    data: body,
  })
    .then((data) =>
      dispatch({ type: EDIT_EXAM_USER_DETAIL.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: EDIT_EXAM_USER_DETAIL.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamUserDetailSubjectList = (token, slug) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_USER_DETAIL_SUBJECT_LIST.REQUEST });
  return getAPI({
    url: `examUserList/${token}`,
    params: {
      flag: "subject",
      slug,
    },
  })
    .then((data) =>
      dispatch({
        type: GET_EXAM_USER_DETAIL_SUBJECT_LIST.SUCCESS,
        payload: data,
      })
    )
    .catch((error) =>
      dispatch({ type: GET_EXAM_USER_DETAIL_SUBJECT_LIST.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamQuestionList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_QUESTION_LIST.REQUEST });
    return getAPI({
      url: "cms/examQuestion",
      params: {
        ...mapFilterExamQuestion(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_EXAM_QUESTION_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_EXAM_QUESTION_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getExamQuestionById = (id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_QUESTION_BY_ID.REQUEST });
  return getAPI({
    url: `cms/examQuestion/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_QUESTION_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) =>
      dispatch({ type: GET_EXAM_QUESTION_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamChoiceDetailById = (id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_QUESTION_CHOICE_BY_ID.REQUEST });
  return getAPI({
    url: `cms/examChoiceDetail/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_QUESTION_CHOICE_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) =>
      dispatch({ type: GET_EXAM_QUESTION_CHOICE_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const addExamQuestion = (examQuestion) => (dispatch) => {
  dispatch({ type: CREATE_EXAM_QUESTION.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "cms/examQuestion",
    data: examQuestion,
  })
    .then((data) => dispatch({ type: CREATE_EXAM_QUESTION.SUCCESS }))
    .catch((error) => dispatch({ type: CREATE_EXAM_QUESTION.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateExamQuestionChoice =
  (examQuestionChoice, id) => (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: PATCH_EXAM_QUESTION_CHOICE_BY_ID.REQUEST });
    return patchAPI({
      url: `cms/examChoiceDetail/${id}`,
      data: examQuestionChoice,
    })
      .then((data) =>
        dispatch({ type: PATCH_EXAM_QUESTION_CHOICE_BY_ID.SUCCESS })
      )
      .catch((error) =>
        dispatch({ type: PATCH_EXAM_QUESTION_CHOICE_BY_ID.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateExamQuestion = (examQuestion, id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_EXAM_QUESTION_BY_ID.REQUEST });
  return patchAPI({
    url: `cms/examQuestion/${id}?module_name=${examQuestion.module_name}`,
    data: examQuestion,
  })
    .then((data) => dispatch({ type: PATCH_EXAM_QUESTION_BY_ID.SUCCESS }))
    .catch((error) =>
      dispatch({ type: PATCH_EXAM_QUESTION_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateExamQuestionStatus =
  (examQuestionStatus, id) => (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: PATCH_EXAM_QUESTION_STATUS_BY_ID.REQUEST });
    return patchAPI({
      url: `cms/examQuestion/${id}?module_name=status_info`,
      data: examQuestionStatus,
    })
      .then((data) =>
        dispatch({ type: PATCH_EXAM_QUESTION_STATUS_BY_ID.SUCCESS })
      )
      .catch((error) =>
        dispatch({ type: PATCH_EXAM_QUESTION_STATUS_BY_ID.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const approveExamQuestion = (questions) => (dispatch) => {
  dispatch({ type: APPROVE_EXAM_QUESTION.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "cms/approveExamQuestion",
    data: questions,
  })
    .then((data) => dispatch({ type: APPROVE_EXAM_QUESTION.SUCCESS }))
    .catch((error) => dispatch({ type: APPROVE_EXAM_QUESTION.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamSetList =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_SET_LIST.REQUEST });
    return getAPI({
      url: "cms/examSet",
      params: {
        ...mapFilterExamQuestion(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_EXAM_SET_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_EXAM_SET_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getRandomExam = (obj) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: GET_RANDOM_EXAM.REQUEST });
  return postAPI({
    url: "cms/randomExam",
    data: obj,
  })
    .then((data) => dispatch({ type: GET_RANDOM_EXAM.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: GET_RANDOM_EXAM.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getAllQuestionActive =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_ALL_QUESTION_ACTIVE.REQUEST });
    return getAPI({
      url: "getAllQuestionActive",
      params: {
        ...mapFilterExamQuestion(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_ALL_QUESTION_ACTIVE.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_ALL_QUESTION_ACTIVE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const createExamSet = (obj) => (dispatch) => {
  dispatch({ type: CREATE_EXAM_SET.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "cms/examSet",
    data: obj,
  })
    .then((data) => dispatch({ type: CREATE_EXAM_SET.SUCCESS }))
    .catch((error) => dispatch({ type: CREATE_EXAM_SET.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamSetById = (id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_SET_BY_ID.REQUEST });
  return getAPI({
    url: `cms/examSet/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_SET_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_EXAM_SET_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateExamSet = (obj, id) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: PATCH_EXAM_SET.REQUEST });
  return patchAPI({
    url: `cms/examSet/${id}`,
    data: obj,
  })
    .then((data) => dispatch({ type: PATCH_EXAM_SET.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: PATCH_EXAM_SET.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
export const clearStateExamSet = () => (dispatch) => {
  dispatch({ type: CLEAR_EXAM_SET });
};

export const getAllDataQuestion =
  (filter = {}) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_ALL_DATA_QUESTION_ACTIVE.REQUEST });
    return getAPI({
      url: "getAllQuestionActive?limit=1000000",
      params: {
        ...mapFilterExamQuestion(filter),
        skip: 0,
      },
    })
      .then((data) =>
        dispatch({ type: GET_ALL_DATA_QUESTION_ACTIVE.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_ALL_DATA_QUESTION_ACTIVE.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getAllDataExamQuestion =
  (filter = {}) =>
  (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: GET_ALL_DATA_EXAM_QUESTION_LIST.REQUEST });
    return getAPI({
      url: "cms/examQuestion?limit=1000000",
      params: {
        ...mapFilterExamQuestion(filter),
        skip: 0,
      },
    })
      .then((data) =>
        dispatch({ type: GET_ALL_DATA_EXAM_QUESTION_LIST.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_ALL_DATA_EXAM_QUESTION_LIST.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };
