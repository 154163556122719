import React from "react";
import styled from "styled-components";
import { FormControl, TextField as TextFieldMui } from "@material-ui/core";

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .MuiInputBase-input {
    padding: 14px 16px;
    border-radius: 10px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e8ecef !important;
    border-width: 1px !important;
  }
  .Mui-disabled {
    background-color: #eeeeee;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error.Mui-error.MuiInputBase-formControl {
    border: 1px solid red;
  }
`;

const TextField = ({ className, label, error = null, ...props }) => {
  return (
    <StyledFormControl className={className} fullWidth>
      {Boolean(label) && <label>{label}</label>}
      <TextFieldMui
        {...(error && { error: error, helperText: error })}
        variant="outlined"
        {...props}
      />
    </StyledFormControl>
  );
};

export default TextField;
