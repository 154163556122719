import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Select from "components/form/Select";
import ButtonUploadFile from "components/form/ButtonUploadFile";
import { uploadImage } from "redux/actions/share";
import { useHistory } from "react-router-dom";

const MOCK_DATA = [
  {
    id: 1,
    date: "1 ก.ย. 2565 - 15 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Download",
  },
  {
    id: 2,
    date: "16 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Download",
  },
  {
    id: 3,
    date: "17 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Waiting",
  },
];

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          /* min-width: 260px; */
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .btn-status {
    width: 100px;
    height: 80%;
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const initFilter = {
  code: "",
  examOwner: "",
  subjectId: "",
  topicName: "",
  level: "",
  status: "",
  question: "",
};

const ITEM_HEIGHT = 48;

const PaymentCash = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.library);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([
    { value: "ธนาคารกรุงเทพ", text: "ธนาคารกรุงเทพ" },
    { value: "Counter Service", text: "Counter Service" },
  ]);
  const [paymentStatus, setPaymentStatus] = useState([
    { value: "ทั้งหมด", text: "ทั้งหมด" },
    { value: "ไม่พบยอดเงิน", text: "ไม่พบยอดเงิน" },
    { value: "ยอดเงินขาด", text: "ยอดเงินขาด" },
    { value: "ยอดเงินเกิน", text: "ยอดเงินเกิน" },
    { value: "ชำระเงินสำเร็จ", text: "ชำระเงินสำเร็จ" },
  ]);
  const [displayNumber, setDisplayNumber] = useState([
    { value: "10", text: "10" },
    { value: "20", text: "20" },
    { value: "50", text: "50" },
    { value: "100", text: "100" },
  ]);

  const [paymentMethod, setPaymentMethod] = useState();
  const [urlImage, setUrlImage] = useState();

  const columns = [
    {
      field: "id",
      headerName: "ชื่อสมาชิก",
    },
    {
      field: "campaignName",
      headerName: "Ref1.",
    },
    {
      field: "companyName",
      headerName: "Ref2.",
    },
    { field: "campaignStartDate", headerName: "ประเภท" },
    { field: "campaignEndDate", headerName: "รายการชำระเงิน" },
    { field: "groupNumber", headerName: "จำนวนเงินที่ต้องชำระ" },
    {
      field: "userExam",
      headerName: "สถานะการชำระเงิน",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
  };

  const handleUploadImage = (e) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setUrlImage(path);
      console.log(path);
    });
  };

  return (
    <SidebarLayout title="Accouting Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-payment", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <h1>บันทึกข้อมูลการชำระเงินด้วยเจ้าหน้าที่</h1>
            <div className="section-group">
              <div className="group-title">กรอกรายละเอียด</div>
              <div className="group-field">
                <div style={{ width: "550px" }}>
                  <TextField
                    label="เลขที่รับเรื่อง"
                    type="text"
                    value={filter.code}
                    placeholder="Start Date"
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <Button
                    onClick={() => history.push("/accounting/payment-all")}
                  >
                    ตรวจสอบรายการทั้งหมด
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="table-wrapper">
            <h4>รายละเอียดการชำระเงิน</h4>
            <Table
              columns={columns}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
            />
          </div>
          <br />
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-field">
                <Button>กลับ</Button>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default PaymentCash;
