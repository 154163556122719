import axios from 'axios'

import { BASE_API, BASE_API_NOTIFICATION } from 'configs/app'

const fetchApi = async (options = {}, isNotificationUrl) => {
  const { url, headers, ...restOptions } = options
  const accessToken = localStorage.getItem('access_token')

  let defaultOptions;
  if (isNotificationUrl) {
    defaultOptions = {
      baseURL: BASE_API_NOTIFICATION,
      timeout: 30000,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {},
      params: {},
    }
  }
  else {
    defaultOptions = {
      baseURL: BASE_API,
      timeout: 30000,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {},
      params: {},
    }
  }


  if (accessToken) {
    defaultOptions.headers.Authorization = `Bearer ${accessToken}`
  }

  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  }

  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    })

    return data
  } catch (error) {
    // if (error.response?.status === 401) {
    //   localStorage.removeItem('access_token')
    //   localStorage.removeItem('user')
    //   window.location.href = '/login'
    //   return null
    // }

    const { response: { data = {} } = {}, message } = error
    const responseMessage = typeof data === 'string' ? data : data.message

    return Promise.reject(responseMessage || message)
  }
}

export const getAPI = (options = {}, isNotificationUrl = false) => fetchApi(options, isNotificationUrl)

export const postAPI = (options = {}, isNotificationUrl = false) =>
  fetchApi({
    ...options,
    method: 'POST',
  }, isNotificationUrl)

export const putAPI = async (options = {}, isNotificationUrl = false) =>
  fetchApi({
    ...options,
    method: 'PUT',
  }, isNotificationUrl)

export const patchAPI = async (options = {}, isNotificationUrl = false) =>
  fetchApi({
    ...options,
    method: 'PATCH',
  }, isNotificationUrl)

export const deleteAPI = async (options = {}, isNotificationUrl = false) =>
  fetchApi({
    ...options,
    method: 'DELETE',
  }, isNotificationUrl)
