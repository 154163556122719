import React from "react";
import styled from "styled-components";

import {
  FormHelperText,
  FormControl,
  TextareaAutosize as TextAreaMui,
} from "@material-ui/core";

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  textarea {
    padding: 14px 16px;
    font-size: 16px;
    border-radius: 4px;
    border-color: #e8ecef;
    outline: none;
    max-height: 300px;
  }

  textarea:disabled {
    background-color: #eeeeee;
    color: #939393;
  }
`;

const TextFieldArea = ({
  className,
  minRows = 3,
  maxRows = 10,
  label,
  isError = null,
  errorText = "",
  ...props
}) => {
  return (
    <StyledFormControl className={className} fullWidth>
      <FormControl
        error={isError}
        style={isError ? { border: "1px solid red", borderRadius: "4px" } : {}}
      >
        {Boolean(label) && <label>{label}</label>}
        <TextAreaMui minRows={minRows} maxRows={maxRows} {...props} />
      </FormControl>
      {isError && (
        <FormHelperText style={{ color: "red" }}>{errorText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default TextFieldArea;
