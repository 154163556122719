import React from "react";
import { useState, useEffect } from "react";

import { useQuill } from "react-quilljs";
import * as Quill from "quill";

import katex from "katex";
import "katex/dist/katex.min.css";

import FormHelperText from "@material-ui/core/FormHelperText";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import styled from "styled-components";

import "quill/dist/quill.snow.css";

const Div = styled.div`
  .ql-editor {
    padding: 6px 7px;
  }
`;

const TextEditor = ({
  setValue,
  editValue = "",
  isEdit = false,
  propositionFrm,
  index,
  errorText = "",
  isError = null,
  ...props
}) => {
  window.katex = katex;

  const theme = "snow";
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "formula"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ script: "super" }, { script: "sub" }],
      ["clean"],
    ],
  };
  const placeholder = "";
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "formula",
    "script",
  ];

  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  const [editData, setEditData] = useState("");

  useEffect(() => {
    if (quill) {
      quill.on("text-change", function (delta, source) {
        const updatedData = [...propositionFrm];
        updatedData[index].choice_description = quill?.root.innerHTML;

        setValue(updatedData);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (isEdit) {
      setEditData(editValue);
    }
  }, [editValue]);

  return (
    <Div>
      {Boolean(isEdit) ? (
        <div
          className="ql-editor"
          style={{ width: "100%", height: "130px" }}
          ref={quillRef}
          contentEditable={true}
          suppressContentEditableWarning={true}
          {...props}
        >
          <div dangerouslySetInnerHTML={{ __html: editData }}></div>
        </div>
      ) : (
        <div
          style={
            isError
              ? {
                  width: "100%",
                  height: "130px",
                  border: "1px solid red",
                  borderRadius: "4px",
                }
              : { width: "100%", height: "130px" }
          }
          ref={quillRef}
          {...props}
        ></div>
      )}
      {isError && (
        <FormHelperText style={{ color: "red" }}>{errorText}</FormHelperText>
      )}
    </Div>
  );
};

export default TextEditor;
