import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Papa from "papaparse";
import { saveAs } from "file-saver";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import BaseModal from "components/modal/BaseModal";

import { reportNotifications } from "redux/actions/notification";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    top: 793px;
    left: 1140px;
  }

  .btn-action {
    margin-right: 1rem;
  }

  .header {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .input-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 15rem 15rem 15rem;
    grid-template-rows: auto;
    align-content: center;
    margin-bottom: 1rem;
  }

  .error-message {
    font-size: 1.5rem;
    color: red;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
`;

const dateTypeList = [{ value: 1, text: "วันที่ทำรายการ" }];
const defaultValidator = {
  dateType: {
    isValid: false,
    message: "กรุณาเลือก",
  },
  startDate: {
    isValid: false,
    message: "กรุณาเลือกวันที่",
  },
  endDate: {
    isValid: false,
    message: "กรุณาเลือกวันที่",
  },
};
const headerCSVFile = [
  "วันที่ทำรายการ",
  "ประเภทสมาชิก",
  "ชื่อ นามสกุล",
  "เลขที่สมาชิก",
  "เลขที่ใบอนุญาต",
  "วันที่หมดอายุใบอนุญาต",
  "ชื่อรายการ",
  "ช่องทาง",
  "สถานะการส่ง",
  "เบอร์โทร",
];
const NotificationReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateTypeSelected, setDateTypeSelected] = useState("");
  const [validator, setValidator] = useState(defaultValidator);
  const [errorMessage, setErrorMessage] = useState("");

  const [errorModal, setErrorModal] = useState(false);

  const validate = () => {
    setValidator((prev) => ({
      startDate: {
        ...prev.startDate,
        isValid: startDate == null ? true : false,
      },
      endDate: {
        ...prev.endDate,
        isValid: endDate == null ? true : false,
      },
      dateType: {
        ...prev.dateType,
        isValid: dateTypeSelected == "" ? true : false,
      },
    }));

    return startDate == null || endDate == null || dateTypeSelected == ""
      ? false
      : true;
  };

  const exportCSV = (data, header, filename) => {
    const csv = Papa.unparse({
      fields: header,
      data: data,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
  };

  const downloadCSV = async () => {
    if (validate()) {
      const start_date = moment(startDate).format("YYYY-MM-DD");
      const end_date = moment(endDate).format("YYYY-MM-DD");
      const params = {
        start_date,
        end_date,
      };

      const data = await reportNotifications(params);
      if (data.length > 0) {
        const newCsvData = data.map((v) => {
          let newData = [];
          for (const key in v) {
            let value;
            if (key == "expire_date")
              value = moment(v[key]).format("DD/MM/YYYY");
            else value = v[key];
            newData.push(value);
          }
          return newData;
        });

        const start_date = moment(startDate).format("YYYY-MM-DD");
        const end_date = moment(endDate).format("YYYY-MM-DD");
        const fileName = `ประวัติการส่งแจ้งเตือนระหว่าง ${start_date} ถึง ${end_date}`;
        exportCSV(newCsvData, headerCSVFile, fileName);
      } else {
        setErrorMessage("ไม่มีประวัติการส่งแจ้งเตือนในช่วงระยะเวลาที่คุณเลือก");
        setErrorModal(true);
      }
    }
  };

  return (
    <SidebarLayout title="Custom Notification Management">
      <BaseModal
        open={errorModal}
        onClose={() => setErrorModal(false)}
        // maxWidth={mode === "user" ? "xl" : "md"}
        maxWidth={"sm"}
        fullWidth
      >
        <Div>
          <div className="error-message">{errorMessage}</div>
          <div className="button-wrapper">
            <Button onClick={() => setErrorModal(false)} outlined>
              ปิด
            </Button>
          </div>
        </Div>
      </BaseModal>

      <Div>
        <SubMenu
          menu={[
            { path: "/notification/all", text: "Notification" },
            { path: "/notification/custom/all", text: "Custom Notification" },
            { path: "/notification/report", text: "Report" },
          ]}
        />
        <div className="content-wrapper">
          <h1>ประวัติการส่งแจ้งเตือน</h1>
          <div className="header">เลือกช่วงเวลา ตั้งแต่วันที่-วันที่</div>
          <div className="input-container">
            <Select
              value={dateTypeSelected}
              items={dateTypeList}
              onChange={(event) => setDateTypeSelected(event.target.value)}
              isError={validator.dateType.isValid}
              errorText={validator.dateType.message}
            />
            <Date
              placeholder="วันที่"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              isError={validator.startDate.isValid}
              errorText={validator.startDate.message}
            />
            <Date
              placeholder="วันที่"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              isError={validator.endDate.isValid}
              errorText={validator.endDate.message}
            />
          </div>
          <Button onClick={downloadCSV}>Download Csv</Button>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default NotificationReport;
