import React from "react";
import styled from "styled-components";
import TextField from "./TextField";

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  label {
    font-size: 16px;
    margin-right: auto;
    flex: 1;

    &.right {
      text-align: right;
      width: 80%;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e8ecef !important;
    border-width: 1px !important;
  }
`;

const InlineSpaceText = ({ className, label, children, ...props }) => {
  return (
    <Div className={className}>
      {Boolean(label) && <label>{label}</label>}{" "}
      <label className="right">{children}</label>
    </Div>
  );
};

export default InlineSpaceText;
