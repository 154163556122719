import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";

import Grid from "@mui/material/Grid";

import { approveExamQuestion } from "redux/actions/library";

const Div = styled.div`
  padding: 20px;
  width: 600px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }
`;

const PopupApproveExamQuestion = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  obj,
}) => {
  const dispatch = useDispatch();

  console.log();

  useEffect(() => {}, []);

  const handleSubmit = () => {
    dispatch(approveExamQuestion(obj)).then(() => onClose());
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">คุณต้องการอนุมัติข้อสอบนี้หรือไม่?</div>
            <p className="text-center">จำนวนข้อสอบที่อนุมัติ {obj?.questions?.length} ข้อ</p>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก{" "}
          </Button>
          <Button onClick={() => handleSubmit()}>ยืนยัน </Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupApproveExamQuestion;
