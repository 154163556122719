import {
  GET_NOTIFICATIONS,
  GET_CONDITIONS,
  CREATE_NOTIFICATION,
  STATUS_NOTIFICATION,
  GET_USERS_NOTIFICATION,
  SET_USER_NOTIFICATION_SELECTED,
  GET_NOTIFICATION_BY_ID,
  EDIT_NOTIFICATION,
  UPDATE_EXTRA_CONDITION,
  GET_USERS_EXTRA_CONDITION,
  GET_MEMBER_TYPES,
  GET_CORPORATE_TYPES,
  DELETE_NOTIFICATION,
  SEND_IMMEDIATELY_NOTIFICATION,
} from "redux/actionTypes";
import {} from "utils/common";

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  totalUsers: 0,
  limitUsers: 10,
  skipUsers: 0,
  totalUsersExtra: 0,
  notifications: [],
  conditions: [],
  memberTypes: [],
  corporateTypes: [],
  usersNotification: [],
  usersNotificationExtraCondition: [],
  usersNotificationSelected: [],
  notificationInfo: {},
  report_notifications: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NOTIFICATIONS.REQUEST:
      return { ...state };
    case GET_NOTIFICATIONS.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return { ...state, total, limit, skip, notifications: data };
    }
    case GET_NOTIFICATIONS.FAILURE:
      return { ...state };
    case GET_CONDITIONS.REQUEST:
      return { ...state };
    case GET_CONDITIONS.SUCCESS: {
      const { data } = action.payload;
      return { ...state, conditions: data };
    }
    case GET_CONDITIONS.FAILURE:
      return { ...state };
    case GET_MEMBER_TYPES.REQUEST:
      return { ...state };
    case GET_MEMBER_TYPES.SUCCESS: {
      const { data } = action.payload;
      return { ...state, memberTypes: data };
    }
    case GET_MEMBER_TYPES.FAILURE:
      return { ...state };
    case GET_CORPORATE_TYPES.REQUEST:
      return { ...state };
    case GET_CORPORATE_TYPES.SUCCESS: {
      const { data } = action.payload;
      return { ...state, corporateTypes: data };
    }
    case GET_CORPORATE_TYPES.FAILURE:
      return { ...state };
    case CREATE_NOTIFICATION.REQUEST:
      return { ...state };
    case CREATE_NOTIFICATION.SUCCESS: {
      return { ...state };
    }
    case CREATE_NOTIFICATION.FAILURE:
      return { ...state };
    case EDIT_NOTIFICATION.REQUEST:
      return { ...state };
    case EDIT_NOTIFICATION.SUCCESS: {
      return { ...state };
    }
    case EDIT_NOTIFICATION.FAILURE:
      return { ...state };
    case STATUS_NOTIFICATION.REQUEST:
      return { ...state };
    case STATUS_NOTIFICATION.SUCCESS: {
      return { ...state };
    }
    case UPDATE_EXTRA_CONDITION.FAILURE:
      return { ...state };
    case UPDATE_EXTRA_CONDITION.REQUEST:
      return { ...state };
    case UPDATE_EXTRA_CONDITION.SUCCESS: {
      return { ...state };
    }
    case STATUS_NOTIFICATION.FAILURE:
      return { ...state };
    case GET_USERS_NOTIFICATION.REQUEST:
      return { ...state };
    case GET_USERS_NOTIFICATION.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        totalUsers: total,
        limitUsers: limit,
        skipUsers: skip,
        usersNotification: data,
      };
    }
    case GET_USERS_NOTIFICATION.FAILURE:
      return { ...state };
    case GET_USERS_EXTRA_CONDITION.REQUEST:
      return { ...state };
    case GET_USERS_EXTRA_CONDITION.SUCCESS: {
      const { total, data } = action.payload;
      return {
        ...state,
        totalUsersExtra: total,
        usersNotificationExtraCondition: data,
      };
    }
    case GET_USERS_EXTRA_CONDITION.FAILURE:
      return { ...state };
    case GET_NOTIFICATION_BY_ID.REQUEST:
      return { ...state };
    case GET_NOTIFICATION_BY_ID.SUCCESS: {
      const { data } = action.payload;
      return { ...state, notificationInfo: data };
    }
    case GET_NOTIFICATION_BY_ID.FAILURE:
      return { ...state };
    case DELETE_NOTIFICATION.REQUEST:
      return { ...state };
    case DELETE_NOTIFICATION.SUCCESS: {
      return { ...state };
    }
    case DELETE_NOTIFICATION.FAILURE:
      return { ...state };
    case SEND_IMMEDIATELY_NOTIFICATION.REQUEST:
      return { ...state };
    case SEND_IMMEDIATELY_NOTIFICATION.SUCCESS: {
      return { ...state };
    }
    case SEND_IMMEDIATELY_NOTIFICATION.FAILURE:
      return { ...state };
    case SET_USER_NOTIFICATION_SELECTED: {
      const { usersNotificationSelected } = action;
      return { ...state, usersNotificationSelected };
    }
    default:
      return state;
  }
};
