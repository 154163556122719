import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";

import styled from "styled-components";
import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Button from "components/form/Button";
import Select from "components/form/Select";

import {
  downloadMemberExpiredCsv,
  downloadCorporateExpiredCsv,
} from "redux/actions/callcenter";

const Div = styled.div`
  .welcome-wrapper {
    margin-top: 29px;
    height: 870px;
    padding: 5rem 5rem;
    font-family: "kelson_sansbold";
    background: #fff;
    border-radius: 8px;

    .box-container {
      margin-bottom: 2rem;
    }

    .sub-title {
      font-size: 1.2rem;
      color: #080808;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 56px;
      color: #a81e21;
      margin-top: 36px;
    }
  }

  .field-amount-day {
    width: 230px;
    margin-bottom: 1rem;
  }
`;

const initAmountDay = [
  {
    text: "ก่อนหมดอายุ 1 วัน",
    value: 1,
  },
  {
    text: "ก่อนหมดอายุ 3 วัน",
    value: 3,
  },
  {
    text: "ก่อนหมดอายุ 7 วัน",
    value: 7,
  },
];
const CallCenterAccount = () => {
  const dispatch = useDispatch();
  const { memberExpiredCsvData, corporateExpiredCsvData } = useSelector(
    (state) => state.callcenter
  );
  const [amountDay, setAmountDay] = useState(1);

  const DownloadCSV = (data, fileName) => {
    // Convert data to CSV
    const csvData = Papa.unparse(data);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a download link and set the Blob as its href
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append the download link to the DOM, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadMemberExpired = async () => {
    const dataCsv = await downloadMemberExpiredCsv(amountDay);
    DownloadCSV(dataCsv, "memberExpired.csv");
  };

  const downloadCorporateExpired = async () => {
    const dataCsv = await downloadCorporateExpiredCsv();
    DownloadCSV(dataCsv, "corporateExpired.csv");
  };

  return (
    <SidebarLayout title="Call Center">
      <Div>
        <SubMenu
          menu={[
            { path: "/call-center/ticket/all", text: "รายการ" },
            { path: "/call-center/account", text: "รายชื่อ" },
          ]}
        />
        <div className="welcome-wrapper">
          <div className="box-container">
            <div className="sub-title">
              ข้อมูลสมาชิกที่ใบอนุญาตกำลังจะหมดอายุ
            </div>
            <Select
              value={amountDay}
              items={initAmountDay}
              defaultValue="ระบุจำนวนวัน"
              className="field-amount-day"
              onChange={(e) => setAmountDay(e.target.value)}
            />
            <Button onClick={downloadMemberExpired}>
              Download ข้อมูลสมาชิกบุคคล
            </Button>
          </div>
          <div className="box-container">
            <div className="sub-title">
            ข้อมูลสมาชิกนิติบุคคล ที่ใบอนุญาตจะหมดอายุภายใน 30 วัน
            </div>
            <Button onClick={downloadCorporateExpired}>
              Download ข้อมูลสมาชิกนิติบุคคล
            </Button>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default CallCenterAccount;
