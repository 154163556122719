import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";

const Div = styled.div`
  padding: 20px;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: "kelson_sansbold";
    margin-bottom: 1rem;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 20px;

    .button-toggle-panel {
      display: flex;
      column-gap: 10px;
      align-items: center;

      > button {
        min-width: 150px;
      }
      &.end {
        justify-content: flex-end;
      }
    }
    .filter-panel {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
      .row {
        display: flex;
        column-gap: 10px;
        align-items: flex-end;
        .select {
          width: 20%;
        }
        > button {
          min-width: 150px;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .modal-deleted {
    display: flex;
    justify-content: center;
  }
`;

function PopupAlertReceiver({ open, onClose, message }) {
  return (
    <BaseModal open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Div>
        <div className="title">{message}</div>
        <div className="modal-deleted">
          <Button onClick={onClose}>ปิด</Button>
        </div>
      </Div>
    </BaseModal>
  );
}

export default PopupAlertReceiver;
