import {
  GET_NOTIFICATIONS,
  GET_CONDITIONS,
  CREATE_NOTIFICATION,
  STATUS_NOTIFICATION,
  GET_USERS_NOTIFICATION,
  SET_USER_NOTIFICATION_SELECTED,
  GET_NOTIFICATION_BY_ID,
  EDIT_NOTIFICATION,
  UPDATE_EXTRA_CONDITION,
  GET_USERS_EXTRA_CONDITION,
  GET_MEMBER_TYPES,
  GET_CORPORATE_TYPES,
  DELETE_NOTIFICATION,
  SEND_IMMEDIATELY_NOTIFICATION,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI, putAPI } from "utils/api";

const mapFilter = (v) => ({
  "date_ship[$gte]": v.fromDate || null,
  "date_ship[$lte]": v.toDate || null,
  member_no: v.id || null,
  first_name: v.firstName || null,
  last_name: v.lastName || null,
  email: v.email || null,
  member_type: v.type,
  segment: v.segment || null,
});

export const getNotifications =
  (
    skip = 0,
    limit = 10,
    filter = {
      notification_name: "",
      notification_type: "",
      condition_id: "",
      status: "",
      member_type: "",
      is_schedule: 1,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_NOTIFICATIONS.REQUEST });

    const {
      notification_name,
      notification_type,
      condition_id,
      status,
      is_schedule,
      member_type,
    } = filter;

    const params = {
      skip,
      limit,
      notification_name,
      notification_type,
      condition_id,
      status,
      is_schedule,
      member_type,
    };

    return getAPI(
      {
        url: "notification",
        params: params,
      },
      true
    )
      .then((data) =>
        dispatch({ type: GET_NOTIFICATIONS.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_NOTIFICATIONS.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getNotificationById =
  (notification_id) => (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_NOTIFICATION_BY_ID.REQUEST });

    return getAPI(
      {
        url: `notification/${notification_id}`,
      },
      true
    )
      .then((data) =>
        dispatch({ type: GET_NOTIFICATION_BY_ID.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_NOTIFICATION_BY_ID.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getConditions = () => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_CONDITIONS.REQUEST });

  return getAPI(
    {
      url: "condition",
    },
    true
  )
    .then((data) => dispatch({ type: GET_CONDITIONS.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: GET_CONDITIONS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getMemberTypes = () => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_MEMBER_TYPES.REQUEST });

  return getAPI(
    {
      url: "member_type",
    },
    true
  )
    .then((data) => dispatch({ type: GET_MEMBER_TYPES.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: GET_MEMBER_TYPES.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCorporateTypes = () => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_CORPORATE_TYPES.REQUEST });

  return getAPI(
    {
      url: "corporate_type",
    },
    true
  )
    .then((data) =>
      dispatch({ type: GET_CORPORATE_TYPES.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_CORPORATE_TYPES.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createNotification =
  (
    body,
    filter = {
      is_schedule: 1,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: CREATE_NOTIFICATION.REQUEST });

    return postAPI(
      {
        url: "create_notification",
        data: body,
      },
      true
    )
      .then((data) => {
        dispatch({ type: CREATE_NOTIFICATION.SUCCESS });
        dispatch(getNotifications(0, 10, filter));
      })
      .catch((error) => {
        dispatch({ type: CREATE_NOTIFICATION.FAILURE, error });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const editNotification =
  (
    body,
    filter = {
      is_schedule: 1,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: EDIT_NOTIFICATION.REQUEST });

    return putAPI(
      {
        url: "edit_notification",
        data: body,
      },
      true
    )
      .then((data) => {
        dispatch({ type: EDIT_NOTIFICATION.SUCCESS });
        dispatch(getNotifications(0, 10, filter));
      })
      .catch((error) => {
        dispatch({ type: EDIT_NOTIFICATION.FAILURE, error });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const statusNotification =
  (
    body,
    filter = {
      is_schedule: 1,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: STATUS_NOTIFICATION.REQUEST });

    const { notification_id, status, page, limit } = body;
    const request = {
      notification_id,
      status,
    };

    return putAPI(
      {
        url: "status_notification",
        data: request,
      },
      true
    )
      .then((data) => {
        dispatch({ type: STATUS_NOTIFICATION.SUCCESS });
        dispatch(getNotifications(page * limit, 10, filter));
      })
      .catch((error) => dispatch({ type: STATUS_NOTIFICATION.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const deleteNotification =
  (
    body,
    filter = {
      is_schedule: 0,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: DELETE_NOTIFICATION.REQUEST });

    const { notification_id, page, limit } = body;

    return patchAPI(
      {
        url: `delete_notification/${notification_id}`,
      },
      true
    )
      .then((data) => {
        dispatch({ type: DELETE_NOTIFICATION.SUCCESS });
        dispatch(getNotifications(page * limit, 10, filter));
      })
      .catch((error) => dispatch({ type: DELETE_NOTIFICATION.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const sendImmediatelyNotification =
  (
    body,
    filter = {
      is_schedule: 0,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: SEND_IMMEDIATELY_NOTIFICATION.REQUEST });

    const { notification_id, page, limit } = body;
    const request = {
      notification_id,
    };
    return postAPI(
      {
        url: `send_immediately_notification`,
        data: request,
      },
      true
    )
      .then((data) => {
        dispatch({
          type: SEND_IMMEDIATELY_NOTIFICATION.SUCCESS,
          showAlert: true,
          alertMessage: "send notification success!",
        });
        dispatch(getNotifications(page * limit, 10, filter));
      })
      .catch((error) =>
        dispatch({
          type: SEND_IMMEDIATELY_NOTIFICATION.FAILURE,
          showAlert: true,
          alertMessage: "send notification failed!",
          error,
        })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const updateExtraCondition =
  (
    body,
    filter = {
      is_schedule: 1,
    }
  ) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: UPDATE_EXTRA_CONDITION.REQUEST });

    const { notification_id, extra_condition, page, limit } = body;
    const request = {
      notification_id,
      extra_condition,
    };

    return putAPI(
      {
        url: "update_extra_condition",
        data: request,
      },
      true
    )
      .then((data) => {
        dispatch({ type: UPDATE_EXTRA_CONDITION.SUCCESS });
        dispatch(getNotifications(page * limit, 10, filter));
      })
      .catch((error) =>
        dispatch({ type: UPDATE_EXTRA_CONDITION.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getUsersNotification =
  (notification_id, skip = 0, limit = 10, filter) =>
  (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_USERS_NOTIFICATION.REQUEST });

    const {
      member_type_name,
      member_status,
      member_no,
      id_card_no,
      first_name,
      last_name,
      license,
      type_date,
      start_date,
      end_date,
      corporation_name,
      corporate_type_id,
    } = filter;

    const params = {
      skip,
      limit,
      member_type_name,
      member_status,
      member_no,
      id_card_no,
      first_name,
      last_name,
      license,
      type_date,
      start_date,
      end_date,
      corporation_name,
      corporate_type_id,
    };

    return getAPI(
      {
        url: `notification_users/${notification_id}`,
        params: params,
      },
      true
    )
      .then((data) =>
        dispatch({ type: GET_USERS_NOTIFICATION.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_USERS_NOTIFICATION.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getUsersNotificationExtraCondition =
  (notification_id, filter) => (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_USERS_EXTRA_CONDITION.REQUEST });

    const {
      extra_condition,
      member_type_name,
      member_status,
      member_no,
      id_card_no,
      first_name,
      last_name,
      license,
      type_date,
      start_date,
      end_date,
      corporation_name,
      corporate_type_id,
    } = filter;

    const params = {
      extra_condition,
      member_type_name,
      member_status,
      member_no,
      id_card_no,
      first_name,
      last_name,
      license,
      type_date,
      start_date,
      end_date,
      corporation_name,
      corporate_type_id,
    };

    return getAPI(
      {
        url: `notification_users_extra_condition/${notification_id}`,
        params: params,
      },
      true
    )
      .then((data) =>
        dispatch({ type: GET_USERS_EXTRA_CONDITION.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: GET_USERS_EXTRA_CONDITION.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const setUsersNotificationSelected =
  (usersNotificationSelected) => (dispatch, getState) => {
    dispatch({
      type: SET_USER_NOTIFICATION_SELECTED,
      usersNotificationSelected,
    });
  };

export const reportNotifications = async (params) => {
  try {
    const response = await getAPI(
      {
        url: "report_notification",
        params,
      },
      true
    );

    return response.data;
  } catch {
    return [];
  }
};
