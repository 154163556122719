import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";

import StepBar from "components/form/StepBar";
import TextField from "components/form/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextEditor from "components/form/TextEditor";
import TextFieldArea from "components/form/TextFieldArea";
import InlineSpaceText from "components/form/InlineSpaceText";
import Table from "components/table/Table";

import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import { getClientLibrary } from "redux/actions/library";
import PopupRandomExamADT from "./PopupRandomExamADT";
import PopupRandomExamShuffleOrNormal from "./PopupRandomExamShuffleOrNormal";
import PopupAddQuestion from "./PopupAddQuestion";

const Div = styled.div`
  padding: 20px;
  width: 1200px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
          margin-right: 16px;
        }

        > * {
          width: 250px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .invilid {
      color: red;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .score-container {
    margin-top: 15px;
  }

  .summary-score-container {
    display: flex;
    width: 100%;
    align-items: center;

    .summary-score-title {
      width: 33%;
    }

    .summary-score-box {
      margin: 5px 0;
      display: flex;
      align-items: center;

      .header {
        font-size: 14px;
        font-weight: bold;
        width: 55%;
        margin-right: 8px;
      }
      .score {
        width: 46%;
        border-right: 1px solid black;
        padding-right: 8px;
        margin-right: 8px;
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .button-table {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 10px;
    .btn {
      margin-right: 15px;
    }
  }
`;

const PopupClientLibrary = ({
  open,
  onClose,
  maxWidth = "xl",
  setClientLibrary,
}) => {
  const dispatch = useDispatch();
  const { clientLibraries } = useSelector((state) => state.library);
  const [clientLibraryList, setClientLibraryList] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "code",
      headerName: "ชื่อ",
      width: 200,
    },
    {
      field: "question",
      headerName: "Email",
      width: 200,
    },
    {
      field: "nameUpper",
      headerName: "เบอร์โทร",
      width: 200,
    },
  ];

  useEffect(() => {
    // dispatch(getClientLibrary());
  }, []);

  useEffect(() => {
    setClientLibraryList(clientLibraries);
  }, [clientLibraries]);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="table-wrapper">
                <Table
                  columns={columns}
                  rows={clientLibraryList}
                  // rowCount={total}
                  // pageSize={+limit}
                  // page={page}
                  disableSelectionOnClick={true}
                  checkboxSelection={false}
                  // onPageChange={handlePageChange}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupClientLibrary;
