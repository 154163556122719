import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const MOCK_DATA = [
  {
    id: 1,
    date: "1 ก.ย. 2565 - 15 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Download",
  },
  {
    id: 2,
    date: "16 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Download",
  },
  {
    id: 3,
    date: "17 ก.ย. 2565",
    amount: 15,
    total: "20,000 บาท",
    status: "Waiting",
  },
];

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .btn-status {
    width: 100px;
    height: 80%;
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const initFilter = {
  code: "",
  examOwner: "",
  subjectId: "",
  topicName: "",
  level: "",
  status: "",
  question: "",
};

const ITEM_HEIGHT = 48;

const DairyIncome = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.library);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
    },
    {
      field: "date",
      headerName: "ประจำวันที่",
      width: 400,
    },
    {
      field: "amount",
      headerName: "จำนวนรายการ",
    },
    { field: "total", headerName: "รายได้รวมประจำวัน", width: 200 },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ value }) => (
        <Button className="btn-status" disabled={value !== "Download"}>
          {value}
        </Button>
      ),
    },
    {
      field: "-",
      headerName: "",
      renderCell: () => (
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem onClick={handleClose}>Delete</MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
  };

  return (
    <SidebarLayout title="Accouting Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/accounting/dairy-income", text: "รับรู้รายได้ประจำวัน" },
            {
              path: "/accounting/upload-payment",
              text: "อัพโหลดไฟล์จากตัวแทนชำระ",
            },
            { path: "/accounting/verify-payment", text: "ตรวจสอบยอดเงิน" },
            { path: "/accounting/payment-cash", text: "ชำระเงินสด" },
            { path: "/accounting/payment-delivery", text: "ออกใบนำส่ง" },
            { path: "/accounting/reconcile", text: "กระทบยอด" },
            { path: "/accounting/post-gl", text: "Post GL" },
            { path: "/accounting/report", text: "รายงาน" },
            { path: "/accounting/refund", text: "ระบบคืนเงิน" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">รับรู้รายได้ประจำวัน</div>
              <div className="group-field">
                <TextField
                  label="รายได้ประจำวันตั้งแต่วันที่"
                  type="date"
                  value={filter.code}
                  placeholder="Start Date"
                />
                <TextField
                  label="ถึงวันที่"
                  type="date"
                  value={filter.code}
                  placeholder="End Date"
                />
                <Button onClick={() => handleSearch()}>ค้นหา</Button>
              </div>
            </div>
          </div>
          <br />

          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={MOCK_DATA}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default DairyIncome;
