import React, { useState, useEffect } from "react";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import report from "redux/reducers/report";

import {
  fetchExportReportMapping,
  fetchReportMapping,
  getBatchUpdate,
} from "redux/actions/report";
import { useDispatch, useSelector } from "react-redux";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const selectList = [{ text: "ข้อมูลนิติบุคคล", value: "" }];
const select2List = [
  { text: "ฝ่ายบริหาร", value: 1 },
  { text: "ฝ่ายการคลัง", value: 2 },
  { text: "ฝ่ายต่างประเทศ", value: 3 },
  { text: "ฝ่ายอาคารสถานที่", value: 4 },
  { text: "ฝ่ายสื่อสารองค์กร", value: 5 },
  { text: "สำนักงานสภาวิศวกร", value: 6 },
  { text: "ผู้บริหารสภาวิศวกร", value: 7 },
  { text: "ฝ่ายเทคโนโลยีสารสนเทศ", value: 8 },
  { text: "ฝ่ายทะเบียนและใบอนุญาต", value: 9 },
  { text: "สำนักกฎหมายและจรรยาบรรณ", value: 10 },
  { text: "ฝ่ายมาตรฐานการศึกษาและวิชาชีพ", value: 11 },
];

const reportList = [
  { value: 1, text: "เอกสารที่ 1 (รอบปกติ)" },
  { value: 2, text: "เอกสารที่ 1 (รอบสอบซ่อม)" },
  { value: 3, text: "เอกสารที่ 2 (รอบปกติ)" },
];

const ReportWelcome = () => {
  const dispatch = useDispatch();
  const { reportData, subReportData } = useSelector(
    (state) => state.masterData
  );

  const { reportList } = useSelector((state) => state.report);

  const [subReportDDL, setSubReportDDL] = useState();
  const [subReport, setSubReport] = useState("");
  const [select2, setSelect2] = useState("");
  const [reportType, setReportType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState();
  const [total, setTotal] = useState(3);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const result = Array.isArray(reportList);
    if (result) {
      setTableData(reportList);
    } else {
      let arr = [reportList];
      setTableData(arr);
    }
  }, [reportList]);

  useEffect(() => {
    if (reportType) {
      let newList;
      const subReportForTrain = [...subReportData].filter(
        (item) => +item.value <= 6
      );
      const subReportForExam = [...subReportData].filter(
        (item) => item.value >= 7
      );

      newList = +reportType === 1 ? subReportForTrain : subReportForExam;
      setSubReportDDL(newList);
      setSubReport("");
    }
  }, [subReportData, reportType]);

  const renderDownloadReportButton = ({ row }) => {
    if (row !== null)
      return (
        <Button
          disabled={row.request_status !== "complete"}
          onClick={() => handleGenerateReport(row)}
        >
          Download Report
        </Button>
      );
    return "-";
  };

  const handleGenerateReport = (row) => {
    dispatch(fetchExportReportMapping(+subReport, row?.id));
  };

  const getReport = () => {
    const filter = {
      fromdate: startDate,
      todate: endDate,
    };

    dispatch(fetchReportMapping(+subReport, filter));
  };

  const getBatch = () => {
    dispatch(getBatchUpdate());
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
    },
    {
      field: "report_type",
      headerName: "ประเภทรายงาน",
      renderCell: IsNullValue,
    },
    {
      field: "report_name",
      headerName: "Name",
      renderCell: IsNullValue,
    },
    {
      field: "fromdate",
      headerName: "Start Date",
    },
    {
      field: "todate",
      headerName: "End Date",
    },
    {
      field: "created_at",
      headerName: "วันที่สร้างรายงาน",
    },
    {
      field: "amount_download",
      headerName: "จำนวนที่ download",
      renderCell: IsNullValue,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: renderDownloadReportButton,
    },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <SidebarLayout title="Cluster">
      <Div>
        <div className="content-wrapper">
          <h3>Step 1 : เลือกรายงาน ฝ่ายบริหาร</h3>
          <div className="form-wrapper" style={{ minHeight: 0 }}>
            <div className="section-group">
              <div className="group-field">
                <Select
                  items={select2List}
                  value={select2}
                  defaultValue="ฝ่าย"
                  className="field-campaign"
                  onChange={(e) => setSelect2(e.target.value)}
                />
                <Select
                  items={reportData}
                  value={reportType}
                  defaultValue="ประเภทรายงาน"
                  className="field-train"
                  onChange={(e) => setReportType(e.target.value)}
                />
                <Select
                  items={subReportDDL}
                  value={subReport}
                  disabled={!reportType}
                  defaultValue="ชื่อรายงาน"
                  className="field-report"
                  onChange={(e) => setSubReport(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <h3>Step 2 : เลือกรายงาน</h3>
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-field">
                <TextField
                  type="date"
                  label="Start Date"
                  value={startDate}
                  name="search_firstname"
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                  type="date"
                  label="End Date"
                  value={endDate}
                  name="search_lastname"
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <TextField
                  label="Id"
                  value={id}
                  name="search_compatibilityLess"
                  onChange={(e) => setId(e.target.value)}
                />
                <Button
                  disabled={!startDate && !endDate}
                  onClick={() => getReport()}
                >
                  Apply
                </Button>
                {+subReport === 1 && +reportType === 1 ? (
                  <Button onClick={() => getBatch()}>Batch</Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={tableData}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ReportWelcome;
