import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Div = styled.div`
  a {
    font-size: 16px;
    font-weight: bold;
    color: #080808;
    text-decoration: none;
    margin-right: 40px;

    &.active,
    &:hover {
      color: #a32330;
    }
  }
`

export default ({ menu = [] }) => {
  return (
    <Div className="sub-menu">
      {menu.map(({ path, text }) => (
        <NavLink key={path} activeClassName="active" to={path} exact>
          {text}
        </NavLink>
      ))}
    </Div>
  )
}
