import { getAPI, postAPI } from "utils/api";
import { showLoading, hideLoading } from "redux/actions/loading";
import {} from "../actionTypes";

import { UPLOAD_IMAGE } from "../actionTypes";

export const uploadImage = (file) => (dispatch) => {
  const form_data = new FormData();
  form_data.append("file", file);

  dispatch({ type: UPLOAD_IMAGE.REQUEST });

  return postAPI({
    url: "upload_image",
    data: form_data,
  })
    .then(({ path }) => {
      return dispatch({ type: UPLOAD_IMAGE.SUCCESS, path });
    })
    .catch((error) => dispatch({ type: UPLOAD_IMAGE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
