import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import formatValue from "utils/formatValue";
import Table from "components/table/Table";
import Button from "components/form/Button";
import { getTicketsHistory } from "redux/actions/callcenter";
import moment from "moment";

const Div = styled.div`
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .table-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 20px;
    }

    .question-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .btn-history {
    padding: 6px !important;
    margin-right: 10px;
  }
`;

const PopupTicketHistoryCallCenter = ({
  open,
  onClose,
  label,
  maxWidth = "lg",
  ticketId,
}) => {
  const dispatch = useDispatch();
  const { limit, total, tickethistory } = useSelector(
    (state) => state.callcenter
  );
  const [page, setPage] = useState(0);
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [dataList, setDataList] = useState(false);

  // useEffect
  useEffect(() => {
    requestTicketEditHistory();
  }, [ticketId]);

  useEffect(() => {
    setPage(0);
  }, [isRefreshData]);

  useEffect(() => {
    setDataList(tickethistory);
  }, [tickethistory]);

  // handle
  const requestTicketEditHistory = async () => {
    dispatch(getTicketsHistory({ id: ticketId }, page * limit, 10));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getTicketsHistory({ id: ticketId }, nextPage * limit));
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const DateTimeDisplay = ({ value }) => {
    return `${formatValue("DateTime", value)}`;
  };

  const formatBuddhistYearWithTime = ({value},format="DD/MM/YYYY HH:mm:ss") => {
    let momentDate = moment(value);
    var christianYear = momentDate.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    
    return momentDate
        .format(
          format
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
  }

  const formatBuddhistYear = ({value},format="DD/MM/YYYY") => {
    let momentDate = moment(value);
    var christianYear = momentDate.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    
    return momentDate
        .format(
          format
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
  }

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ",
    //   width: 100,
    // },
    {
      field: "created_at",
      headerName: "วันที่ทำรายการ",
      width: 200,
      renderCell: formatBuddhistYearWithTime,
    },
    {
      field: "member_no",
      headerName: "เลขที่สมาชิก",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "first_name",
      headerName: "ชื่อจริง",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "last_name",
      headerName: "นามสกุล",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "phone_no",
      headerName: "เบอร์โทร",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "ticket_date",
      headerName: "วันที่โทรเข้ามา",
      width: 200,
      renderCell: formatBuddhistYear,
    },
    {
      field: "ticket_time",
      headerName: "เวลา",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "ticket_type",
      headerName: "หัวข้อ",
      width: 500,
      renderCell: IsNullValue,
    },

    { field: "description", headerName: "เนื่อหา", width: 500 },

    {
      field: "full_name_updated_by",
      headerName: "แก้ไขโดย",
      width: 200,
    },
  ];

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
    >
      <Div>
        <div className="content-wrapper">
          <div className="table-wrapper">
            <div className="group-field end" style={{ textAlign: "end" }}>
              <Button onClick={onClose} outlined="true" className="btn-history">
                ปิดหน้าต่าง
              </Button>
            </div>
            {/* <div className="group-title" style={{ fontSize: "large" }}>รายการทั้งหมด {total} รายการ</div> */}
            <div className="table-text">ประวัติการแก้ไข</div>
            <Table
              columns={columns}
              rows={dataList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              showRunningNumber={true}
            />
          </div>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupTicketHistoryCallCenter;
