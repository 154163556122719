import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";

const StyledFormControl = styled.div`
  * {
    font-family: "Sukhumvit";
  }

  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  textarea {
    padding: 14px 16px;
    font-size: 16px;
    border-radius: 4px;
    border-color: #e8ecef;
    outline: none;
    max-height: 300px;
  }

  textarea:disabled {
    background-color: #eeeeee;
    color: #939393;
  }

  .MuiStepIcon-root {
    width: 2em;
    height: 2em;
  }

  .MuiStepConnector-root {
    top: 25px;
    left: calc(-50% + 24px);
    right: calc(50% + 24px);
  }

  pre {
    font-size: 16px;
  }

  pre::first-line {
    font-size: 20px;
    font-weight: bold;
  }
`;

const VerticalStepBar = ({ steps = [], activeStep = 0, ...props }) => {
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const useStyles = makeStyles(() => ({
    root: {
      color: "red",
    },
    active: {
      color: "#0CA789",
    },
    completed: {
      color: "#e8e8e8",
    },
  }));

  const CustomStepIcon = (props) => {
    console.log("props", props);
    const classes = useStyles();
    const { open, completed, active } = props;
    const stepIcons = {
      1: <CheckCircleIcon />,
      2: <CheckCircleIcon />,
      3: <CheckCircleIcon />,
      4: <CheckCircleIcon />,
      5: <CheckCircleIcon />,
      6: <CheckCircleIcon />,
      7: <CheckCircleIcon />,
      8: <CheckCircleIcon />,
      9: <CheckCircleIcon />,
      10: <CheckCircleIcon />,
      11: <CheckCircleIcon />,
      12: <CheckCircleIcon />,
      13: <CheckCircleIcon />,
      14: <CheckCircleIcon />,
      15: <CheckCircleIcon />,
      16: <CheckCircleIcon />,
      17: <CheckCircleIcon />,
      18: <CheckCircleIcon />,
      19: <CheckCircleIcon />,
      20: <CheckCircleIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.open]: open,
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcons[props.icon]}
      </div>
    );

    // return (
    //   <div
    //     className={clsx(classes.root, {
    //       [classes.active]: active,
    //       [classes.completed]: completed,
    //     })}
    //   >
    //     {stepIcons[String(props.icon)]}
    //   </div>
    // );
  };

  return (
    <StyledFormControl>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            console.log("label", label);
            const stepProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            if (label.includes("ปิดงาน")) stepProps.close = true;

            console.log("stepProps", stepProps);
            return (
              <Step
                key={index}
                {...stepProps}
                active
                completed={label.includes("ปิดงาน") ? true : false}
              >
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <pre>{label}</pre>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </StyledFormControl>
  );
};

export default VerticalStepBar;
