import {
  ADD_QUOTATION,
  GET_PRODUCT_LIST,
  GET_QUOTATION_LIST,
  GET_INVOICE_LIST,
  GET_QUOTATION_BY_ID,
  GET_INVOICE_BY_ID,
  GET_RECEIPT_BY_ID,
  GET_DELIVERY_BY_ID,
  PATCH_INVOICE_STATUS,
} from "redux/actionTypes";
import {} from "utils/common";

const mapProductItems = (v) => ({
  id: v.id,
  tempCode: v.temp_code,
  productCode: v.product_code,
  glCode: v.gl_code,
  productName: v.product_name,
  productAtt: v.product_att,
  productDetailTh: v.product_detail_th,
  productDetailEn: v.product_detail_en,
  productType: v.product_type,
  priceBeforeVat: v.price_before_vat,
  priceVat: v.price_vat,
  priceTax: v.price_tax,
  total: v.total,
  tax1Percent: v.tax_1_percent,
  tax3Percent: v.tax_3_percent,
  tax7Percent: v.tax_7_percent,
  credit: v.credit,
  debit: v.debit,
  branch: v.branch,
  project: v.project,
  program: v.program,
  year: v.year,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
});

const mapQuotationItems = (v) => ({
  id: v.id,
  quotationNo: v.quotation_no,
  quotationType: v.quotation_type,
  customerId: v.customer_id,
  sellerId: v.seller_id,
  quotationStatus: v.quotation_status,
  remark: v.remark,
  totalPrice: v.total_price,
  totalNet: v.total_net,
  vat: v.vat,
  projectNo: v.project_no,
  programNo: v.program_no,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
});

const mapInvoiceItems = (v) => ({
  id: v.id,
  invoiceNo: v.invoice_no,
  invoiceStatus: v.invoice_status,
  quotationId: v.quotation_id,
  quotationNo: v.quotation_no,
  memberId: v.member_id,
  sellerId: v.seller_id,
  paymentTerm: v.payment_term,
  remark: v.remark,
  totalPrice: v.total_price,
  totalNet: v.total_net,
  totalTax: v.total_tax,
  createdAt: v.createdAt,
  updatedAt: v.updatedAt,
  slipImageUrl: v.slip_image_url,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  items: [],
  quotationById: {},
  invoiceById: {},
  receiptById: {},
  deliveryById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PRODUCT_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_PRODUCT_LIST.SUCCESS: {
      const { total, limit, skip } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: action.payload.map(mapProductItems),
      };
    }
    case GET_PRODUCT_LIST.FAILURE:
      return {
        ...state,
      };

    case GET_QUOTATION_BY_ID.SUCCESS:
      return { ...state, quotationById: action.payload };
    case GET_INVOICE_BY_ID.SUCCESS:
      return { ...state, invoiceById: action.payload };
    case GET_RECEIPT_BY_ID.SUCCESS:
      return { ...state, receiptById: action.payload };
    case GET_DELIVERY_BY_ID.SUCCESS:
      return { ...state, deliveryById: action.payload };

    case ADD_QUOTATION.REQUEST:
      return { ...state };
    case ADD_QUOTATION.SUCCESS:
      return { ...state };
    case ADD_QUOTATION.FAILURE:
      return { ...state };
    case GET_QUOTATION_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_QUOTATION_LIST.SUCCESS: {
      const { total, limit, skip, rows } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: rows.map(mapQuotationItems),
      };
    }
    case GET_QUOTATION_LIST.FAILURE:
      return {
        ...state,
      };
    case GET_INVOICE_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_INVOICE_LIST.SUCCESS: {
      const { total, limit, skip, rows } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        items: rows.map(mapInvoiceItems),
      };
    }
    case GET_INVOICE_LIST.FAILURE:
      return {
        ...state,
      };
    case PATCH_INVOICE_STATUS.REQUEST:
      return {
        ...state,
      };
    case PATCH_INVOICE_STATUS.SUCCESS:
      return {
        ...state,
      };
    case PATCH_INVOICE_STATUS.FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
