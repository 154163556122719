import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";

import ReactHtmlParser from "react-html-parser";

import formatValue from "utils/formatValue";

import {
  getExamQuestionList,
  getAllDataExamQuestion,
} from "redux/actions/library";
import { getTickets, getAllTicketType } from "redux/actions/callcenter";

import PopupCreateTicketCallCenter from "containers/popup/PopupCreateTicketCallCenter";
import PopupSearchCustomerCallCenter from "containers/popup/PopupSearchCustomerCallCenter";
import PopupSelectStatus from "containers/popup/PopupSelectStatus";

import moment from "moment";
import TextFieldDateTH from "components/form/TextFieldDateTH";
import PopupTicketConfirmDelete from "containers/popup/PopupTicketConfirmDelete";

import DeleteIcon from '@material-ui/icons/Delete';

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 15px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .title-header {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .group-btn {
      display: flex;
      align-items: center;
      column-gap: 10px;

      button {
        width: 160px;
      }
    }
  }

  .group-field {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 -10px;

    &.end {
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    > * {
      width: 200px;
      min-width: 200px;
      margin-top: 16px;
      padding: 0 10px;
    }

    > button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }

  .div-group-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .div-group-field-left {
    display: flex;
    align-items: center;
    column-gap: 10px;

    > * {
      width: 200px;
      min-width: 200px;
      margin-top: 16px;
      padding: 0 10px;
    }
  }

  .div-group-field-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;

    width: 100%;
    button {
      width: 150px;
    }
  }

  .iconTicketDelete {
    font-size: 30px;
  }
`;

const initFilter = {
  member_no: "",
  phone_no: "",
  name: "",
  ticket_no: "",
  start_date: moment(),
  end_date: moment(),
  ticket_type: "",
  ticket_status: "",
  description: "",
  ticket_owner_type: "",
};

// const ticketType = [
//   { value: "ติดตามใบอนุญาต", text: "ติดตามใบอนุญาต" },
//   { value: "สอบถามขั้นตอนการต่ออายุใบอนุญาต", text: "สอบถามขั้นตอนการต่ออายุใบอนุญาต" },
//   { value: "สอบถามขั้นตอนการสอบภาคี", text: "สอบถามขั้นตอนการสอบภาคี" },
//   { value: "สอบถามขั้นตอนการสอบอบรม", text: "สอบถามขั้นตอนการสอบอบรม" },
//   { value: "ขอใบอนุญาตนิติบุคคล", text: "ขอใบอนุญาตนิติบุคคล" },
//   { value: "ต่อใบอนุญาตนิติบุคคล", text: "ต่อใบอนุญาตนิติบุคคล" },
//   { value: "สอบถามเรื่องการขอวุฒิบัตร", text: "สอบถามเรื่องการขอวุฒิบัตร" },
//   { value: "สอบถามขั้นตอนการขอหนังสือรับรอง", text: "สอบถามขั้นตอนการขอหนังสือรับรอง" },
//   { value: "สอบถามขั้นตอนการขอเลื่อนระดับ", text: "สอบถามขั้นตอนการขอเลื่อนระดับ" },
//   { value: "สอบถามการรับรองปริญญา", text: "สอบถามการรับรองปริญญา" },
//   { value: "ติดต่อสำนักกฎหมายและจรรยาบรรณ", text: "ติดต่อสำนักกฎหมายและจรรยาบรรณ" },
//   { value: "สอบถามขั้นตอนการขอใบแทนใบอนุญาต", text: "สอบถามขั้นตอนการขอใบแทนใบอนุญาต" },
//   { value: "อื่นๆ", text: "อื่นๆ" },
// ];
const statusList = [
  { value: "open", text: "เปิด" },
  { value: "inprogress", text: "ดำเนินการ" },
  { value: "close", text: "ปิด" },
  { value: "inprogressnotify", text: "ดำเนินงานต้องติดตามและแจ้งผล" },
  { value: "openclose", text: "เปิดและปิด" },
];

const ownerList = [
  { value: "สมาชิก", text: "สมาชิก" },
  { value: "นิติบุคคล", text: "นิติบุคคล" },
  { value: "อื่นๆ", text: "อื่นๆ" },
];

const TicketAll = () => {
  const dispatch = useDispatch();
  const { total, tickets, tickettypes } = useSelector(
    (state) => state.callcenter
  );

  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(initFilter);
  const [dataList, setDataList] = useState(false);
  const [isOpenPopupTicketChangeStatus, setIsOpenPopupTicketChangeStatus] =
    useState(false);
  const [popupTicketStatusID, setPopupTicketStatusID] = useState(false);
  const [id, setId] = useState(false);
  const [ticketType, setTicketType] = useState();
  const [memberNoSearch, setMemberNoSearch] = useState("");
  const [isMember, setIsMember] = useState(1);

  const [isOpenPopupCreateTicket, setIsOpenPopupCreateTicket] = useState(false);
  const [isOpenPopupSearchUser, setIsOpenPopupSearchUser] = useState(false);

  const { first_name: accountName } = JSON.parse(localStorage.getItem("user"));

  console.log("page", page);
  const [isOpenPopupTicketConfirmDelete, setIsOpenPopupTicketConfirmDelete] =
    useState(false);

  const filterMapfetch = useMemo(() => {
    return {
      ...filter,
      start_date: filter?.start_date?.format("YYYY-MM-DD") || null,
      end_date: filter?.end_date?.format("YYYY-MM-DD") || null,
      first_name_create_by: accountName,
    };
  }, [filter]);

  useEffect(() => {
    requestAllTicketsType();
    requestAllTickets();
  }, []);

  useEffect(() => {
    setDataList(tickets);
  }, [tickets]);

  useEffect(() => {
    console.log("omgggg", filter);
  }, [filter]);

  useEffect(() => {
    if (tickettypes?.length > 0) {
      const ticket_types = tickettypes.map((v) => ({
        value: v.id,
        text: v.type_name,
      }));
      setTicketType(ticket_types);
    }
  }, [tickettypes]);

  // handle method
  const requestAllTicketsType = async () => {
    dispatch(getAllTicketType());
  };

  const requestAllTickets = async () => {
    dispatch(getTickets(filterMapfetch, page * 10));
  };

  const handleSearchTicket = () => {
    dispatch(getTickets(filterMapfetch, page * 10));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    setPage(0);

    const filterMapfetchClear = {
      ...initFilter,
      start_date: initFilter?.start_date?.format("YYYY-MM-DD") || null,
      end_date: initFilter?.end_date?.format("YYYY-MM-DD") || null,
    };
    dispatch(getTickets(filterMapfetchClear, page * 10));
  };

  const handleFilterChange = (key, value) => {
    console.log("key:", key);
    console.log("value", value);
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/call-center/ticket/detail/${row?.id}`}>
        {value}
      </RouterLinkCell>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const DateTimeDisplay = ({ value }) => {
    return `${formatValue("DateTime", value)}`;
  };

  const columns = [
    {
      field: "ticket_no",
      headerName: "เลขที่รายการ",
      renderCell: LinkDetailCell,
      width: 200,
    },
    {
      field: "full_name_create_by",
      headerName: "บันทึกรายการโดย",
      width: 180,
    },
    {
      field: "ticket_status_name",
      headerName: "สถานะ",
      width: 100,
    },
    {
      field: "first_name",
      headerName: "ชื่อ/ชื่อบริษัท",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "last_name",
      headerName: "นามสกุล",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "ticket_member_type",
      headerName: "ประเภทผู้แจ้ง",
      renderCell: IsNullValue,
      width: 120,
    },
    {
      field: "ticket_type",
      headerName: "ประเภทรายการ",
      width: 300,
    },
    { field: "description", headerName: "รายละเอียด", width: 300 },

    {
      field: "phone_no",
      headerName: "เบอร์โทร",
      width: 160,
      renderCell: IsNullValue,
    },
    {
      field: "created_at",
      headerName: "วันที่เปิดเรื่อง",
      width: 160,
      renderCell: DateTimeDisplay,
    },
    {
      field: "id",
      headerName: "",
      renderCell: ({ value }) => (
        <DeleteIcon className="iconTicketDelete" onClick={() => handleOpenTicketConfirmDelete(value)} />
      ),
      width: 100,
    }
    // <Button onClick={() => handleOpenTicketConfirmDelete(value)}>ลบ</Button>
  ];

  const handlePageChange = (nextPage) => {
    console.log("nextPage", nextPage);
    setPage(nextPage);
    dispatch(getTickets(filterMapfetch, nextPage * 10));
  };

  const handleOpenPopup = () => {
    setMemberNoSearch("");
    setIsOpenPopupCreateTicket(true);
  };

  const handleClosePopup = (v) => {
    console.log("handleClosePopup", v);
    handleSearchTicket();
    setIsOpenPopupSearchUser(false);
    setIsOpenPopupCreateTicket(false);
  };

  const handleOpenPopupSearchUser = () => {
    setIsOpenPopupSearchUser(true);
  };

  const handleClosePopupSearchUser = () => {
    setIsOpenPopupSearchUser(false);
  };
  const handleChangeTicketStatus = (data) => {
    console.log("handleChangeTicketStatus:", data);
    setPopupTicketStatusID(data?.ticket_status);
    setId(data?.id);
    setIsOpenPopupTicketChangeStatus(true);
  };
  const handleCloseTicketChangeStatus = () => {
    console.log("handleCloseTicketChangeStatus");
    // requestTicket(id);
    setIsOpenPopupTicketChangeStatus(false);
  };
  const handleOpenTicketConfirmDelete = (row) => {
    setIsOpenPopupTicketConfirmDelete(true);
    console.log(row);
    setId(row);
    //setPopupTicketStatusID(ticketInfoById?.ticket_status);
  };
  const handleCloseTicketConfirmDelete = () => {
    console.log("handleCloseTicketConfirmDelete");
    setIsOpenPopupTicketConfirmDelete(false);
    requestAllTickets();
  };
  return (
    <SidebarLayout title="Call Center">
      {Boolean(isOpenPopupCreateTicket) && (
        <PopupCreateTicketCallCenter
          open
          onClose={() => handleClosePopup()}
          memberNo={memberNoSearch}
          isMemberRef={isMember}
        />
      )}
      {Boolean(isOpenPopupSearchUser) && (
        <PopupSearchCustomerCallCenter
          open
          onClose={(v) => {
            //setMemberNoSearch("");
            //setIsMember(1)
            //handleSearchTicket();

            setIsOpenPopupSearchUser(false);
            //setIsOpenPopupCreateTicket(true);
            //handleClosePopup(v);
          }}
          linkCreate={(v) => {
            console.log("linkCreate:", v);
            setMemberNoSearch(v.member_no);
            setIsMember(v.isMember);
            setIsOpenPopupSearchUser(false);
            setIsOpenPopupCreateTicket(true);
          }}
        />
      )}
      {Boolean(isOpenPopupTicketChangeStatus) && (
        <PopupSelectStatus
          open
          onClose={handleCloseTicketChangeStatus}
          ticketId={id}
          popupTicketStatusID={popupTicketStatusID}
        />
      )}
      {Boolean(isOpenPopupTicketConfirmDelete) && (
        <PopupTicketConfirmDelete
          open
          onClose={handleCloseTicketConfirmDelete}
          ticketId={id}
          //popupTicketStatusID={popupTicketStatusID}
        />
      )}

      <Div>
        <SubMenu
          menu={[
            { path: "/call-center/ticket/all", text: "รายการ" },
            { path: "/call-center/account", text: "รายชื่อ" },
          ]}
        />

        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">ค้นหาข้อมูล</div>
              <div className="group-field">
                <TextField
                  label="เลขที่สมาชิก/เลขทะเบียนนิติบุคคล"
                  value={filter.member_no}
                  onChange={(e) =>
                    handleFilterChange("member_no", e.target.value)
                  }
                  name="search_member_no"
                />
                <TextField
                  label="ค้นหาเบอร์โทร"
                  value={filter.phone_no}
                  onChange={(e) =>
                    handleFilterChange("phone_no", e.target.value)
                  }
                  name="search_phone_no"
                />
                <TextField
                  label="ค้นหา ชื่อ/ชื่อบริษัท"
                  value={filter.name}
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                  name="search_name"
                />
                <TextField
                  label="ค้นหารายละเอียด"
                  value={filter.description}
                  onChange={(e) =>
                    handleFilterChange("description", e.target.value)
                  }
                  name="search_details"
                />
                <TextField
                  label="เลขที่รายการ"
                  value={filter.ticket_no}
                  name="search_ticket_no"
                  onChange={(e) =>
                    handleFilterChange("ticket_no", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="section-group">
              {/* <div className="group-title">ค้นหารายการ</div> */}
              <div className="div-group-field">
                <div className="div-group-field-left">
                  {/* <TextField
                  type="date"
                  label="Start Date"
                  value={filter.start_date}
                  name="search_startdate"
                  onChange={(e) =>
                    handleFilterChange("start_date", e.target.value)
                  }
                /> */}

                  <TextFieldDateTH
                    label={"Start Date"}
                    functionSet={(e) => handleFilterChange("start_date", e)}
                    valueControl={filter.start_date}
                  />

                  {/* <TextField
                  type="date"
                  label="End Date"
                  value={filter.end_date}
                  name="search_enddate"
                  onChange={(e) =>
                    handleFilterChange("end_date", e.target.value)
                  }
                /> */}

                  <TextFieldDateTH
                    label={"End Date"}
                    functionSet={(e) => handleFilterChange("end_date", e)}
                    valueControl={filter.end_date}
                  />

                  <Select
                    label="ประเภทรายการ"
                    name="search_ticket_type"
                    value={filter.ticket_type}
                    items={ticketType}
                    defaultValue="Ticket Type"
                    className="field-examinerType"
                    onChange={(e) =>
                      handleFilterChange("ticket_type", e.target.value)
                    }
                  />

                  <Select
                    label="สถานะรายการ"
                    name="search_ticket_status"
                    value={filter.ticket_status}
                    items={statusList}
                    defaultValue="Ticket Status"
                    className="field-examinerType"
                    onChange={(e) =>
                      handleFilterChange("ticket_status", e.target.value)
                    }
                  />

                  <Select
                    label="ประเภทผู้แจ้ง"
                    name="search_ticket_owner_type"
                    value={filter.ticket_owner_type}
                    items={ownerList}
                    defaultValue="ประเภทผู้แจ้ง"
                    className="field-examinerType"
                    onChange={(e) =>
                      handleFilterChange("ticket_owner_type", e.target.value)
                    }
                  />
                </div>

                <div className=" div-group-field-right">
                  <Button onClick={() => handleSearchTicket()}>ค้นหา</Button>
                  <Button outlined onClick={() => handleClearFilter()}>
                    ล้างการค้นหา
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="section-group" style={{ paddingLeft: "40%" }}>
              
            </div> */}
          </div>
          <div className="table-wrapper">
            <div className="table-header">
              <div className="title-header" style={{ fontSize: "large" }}>
                รายการทั้งหมด {total} รายการ
              </div>
              <div className="group-btn">
                <Button onClick={() => handleOpenPopupSearchUser()}>
                  ค้นหารายชื่อสมาชิก
                </Button>
                <Button onClick={() => handleOpenPopup()}>
                  บันทึกการให้บริการ
                </Button>
              </div>
            </div>

            <Table
              columns={columns}
              rows={dataList}
              rowCount={total}
              pageSize={10}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              // onDeleteRow={handleChangeTicketStatus}
              showRunningNumber={true}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default TicketAll;
