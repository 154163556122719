import React from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 500px;
  }

  .modal-content {
    padding: 24px;
  }
`;

const BaseModal = ({
  open,
  onClose,
  children,
  maxWidth = "md",
  fullWidth = false,
  disableBackdropClick = true,
  ...props
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      // disableEscapeKeyDown={disabledSelfClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...props}
    >
      <div className="modal-content">{children}</div>
    </StyledDialog>
  );
};

export default BaseModal;
