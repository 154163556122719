import React from "react";
import styled from "styled-components";
import { Box as BoxMui } from "@mui/material";

const StyleBox = styled(BoxMui)``;

const Box = ({ classname, ...props }) => {
  return <StyleBox className={classname} {...props} />;
};

export default Box;
