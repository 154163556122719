import React from "react";
import styled from "styled-components";
import { Button as ButtonMui, IconButton } from "@material-ui/core";
import { RemoveCircle as DeleteCircle} from "@material-ui/icons";

const StyledButton = styled(IconButton)`
  padding: 0;
  :hover {
    background-color: inherit;
    cursor: pointer;
  }
`;

const ButtonIconDeleteCircle = ({
  className,
  color = "primary",
  size = "large",
  ...props
}) => {
  return (
    <StyledButton
      aria-label="delete"
      className={className}
      color={color}
      size={size}
      {...props}
    >
      <DeleteCircle fontSize={size} />
    </StyledButton>
  );
};

export default ButtonIconDeleteCircle;
