import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Button from "components/form/Button";
import ButtonIcon from "components/form/ButtonIcon";
import ButtonBlack from "components/form/ButtonBlack";
import VerticalStepBar from "components/form/VerticalStepBar";
import { Edit as EditIcon } from "@material-ui/icons";

import TextFieldArea from "components/form/TextFieldArea";
import PopupSelectStatus from "containers/popup/PopupSelectStatus";
import PopupEditTicketCallCenter from "containers/popup/PopupEditTicketCallCenter";
import PopupTicketHistoryCallCenter from "containers/popup/PopupTicketHistoryCallCenter";

import { getTicketInfoById, addTicketStatus } from "redux/actions/callcenter";
import { checkValue } from "utils/common";
import moment from "moment";

const Div = styled.div`
  h1 {
    margin-top: 0;
  }

  .content-wrapper {
    margin-top: 30px;
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          /* min-width: 260px; */
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .btn-status {
    width: 100px;
    height: 80%;
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }

  .header-ticket-detail {
    display: flex;
    justify-content: space-between;

    .header-ticket-item {
      display: flex;
      align-items: center;

      &.left {
        align-items: flex-start;

        flex-direction: column;

        p {
          margin-top: 0;
        }
      }
      h1 {
        margin: 0;
      }
    }
  }

  .ticket-detail-container,
  .user-detail-container {
    display: flex;
    justify-content: space-between;
  }

  .user-detail-left {
    display: flex;
    align-items: center;

    .user-detail-item {
      margin: 0 18px;
    }
  }

  .user-detail-right {
    display: flex;
    align-items: center;
  }

  .note-detail-container {
    margin: 8px 22px;
  }

  .note-detail-container {
    background-color: #eeeeee;
  }

  .note-container {
    margin-bottom: 8px;
  }

  .btn-form {
    text-align: right;
  }

  .note-form {
    margin-bottom: 15px;
  }

  .line-bar {
    border-left: 1px solid black;
    height: 55px;
  }

  .status-bar-item {
    margin-left: 15px;
  }
  .coe-sty-color {
    background: #a81e21 !important;
  }
  .btn-history {
    padding: 6px !important;
    margin-right: 10px;
  }
  .front-member-detail {
    font-weight: bold;
    width: 150px;
  }

  .space-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  table {
    td {
      padding: 5px 0;
    }
  }
`;
//const steps = ["กรอกข้อมูลข้อสอบ", "สร้างโจทย์"];

const TicketDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ticketInfoById, ticketInfoStatusList } = useSelector(
    (state) => state.callcenter
  );
  const [ticketStatusList, setTicketStatusList] = useState(ticketInfoById);
  const [ticketNoteList, setTicketNoteList] = useState(ticketInfoById);
  const [currentStep, setCurrentStep] = useState(0);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [steps, setSteps] = useState([]);

  const [isOpenPopupTicketChangeStatus, setIsOpenPopupTicketChangeStatus] =
    useState(false);
  const [popupTicketStatusID, setPopupTicketStatusID] = useState(false);
  const [isOpenPopupEditTicket, setIsOpenPopupEditTicket] = useState(false);
  const [isOpenPopupEditHistory, setIsOpenPopupEditHistory] = useState(false);

  // useEffect
  useEffect(() => {
    requestTicket(id);
  }, [id]);

  useEffect(() => {
    let stepInfo = [];
    if (ticketInfoStatusList && ticketInfoStatusList.length > 0) {
      for (const tStatus of ticketInfoStatusList) {
        let statusInfo = "";
        if (tStatus.status === "open") {
          statusInfo = "เปิดแล้ว" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "openclose") {
          statusInfo = "เปิดและปิด" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "inprogress") {
          statusInfo = "กำลังดำเนินการ" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "waiting") {
          statusInfo = "รอติดต่อกลับ" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "forward") {
          statusInfo = "ส่งงานต่อ" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
          statusInfo += "\n" + " สาเหตุของการส่งงานต่อ : " + tStatus.comment;
        } else if (tStatus.status === "note") {
          statusInfo = "โน๊ต" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "close") {
          statusInfo = "ปิดงาน" + " \n ";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "reopen") {
          statusInfo = "เปิดใหม่" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "cancel") {
          statusInfo = "ยกเลิก" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
          statusInfo += "\n" + " สาเหตุของการยกเลิก : " + tStatus.comment;
        } else if (tStatus.status === "inprogressnotify") {
          statusInfo = "ดําเนินงานต้องติดตามและแจ้งผล" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else if (tStatus.status === "editdata") {
          statusInfo = "แก้ไขข้อมูล" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        } else {
          statusInfo = "-" + " \n";
          statusInfo +=
            "โดย " +
            tStatus.full_name_create_by +
            " \n" +
            formatBuddhistYear(tStatus.created_at, "DD/MM/YYYY HH:mm:ss");
        }

        stepInfo.push(statusInfo);
        //stepInfo.push(tStatus.status)
      }
    }

    setSteps(stepInfo);
    setCurrentStep(stepInfo.length + 1);
  }, [ticketInfoStatusList]);

  // handle method
  const requestTicket = async (id) => {
    dispatch(getTicketInfoById(id)).then((res) => {
      //const { payload } = res;
      //console.log(res);
    });

    //console.log(ticketInfoStatusList);
  };

  const handleChangeTicketStatus = (row) => {
    setIsOpenPopupTicketChangeStatus(true);
    console.log(row);
    setPopupTicketStatusID(ticketInfoById?.ticket_status);
  };

  const handleTicketNote = () => {
    const obj = {
      ticket_id: id,
      status: "note",
      comment: description,
    };

    if (validate()) {
      dispatch(addTicketStatus(obj)).then((res) => {
        const { payload } = res;
        if (payload) {
          console.log(payload);
          requestTicket(id);
          setDescription("");
        }
      });
    }
  };

  const handleCloseTicketChangeStatus = () => {
    console.log("handleCloseTicketChangeStatus");
    requestTicket(id);
    setIsOpenPopupTicketChangeStatus(false);
  };

  const handleNoteList = (ticketInfoById) => {
    const noteList = ticketInfoById.note_list;
    setTicketNoteList(noteList);
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const handleOpenPopupEditTicket = () => {
    console.log("handleOpenPopupEditTicket");
    setIsOpenPopupEditTicket(true);
  };
  const handleClosePopupEditTicket = () => {
    console.log("handleClosePopupEditTicket");
    requestTicket(id);
    setIsOpenPopupEditTicket(false);
  };

  const handleOpenEditHistory = () => {
    console.log("handleOpenEditHistory");
    setIsOpenPopupEditHistory(true);
  };

  const handleCloseEditHistory = () => {
    console.log("handleCloseEditHistory");
    setIsOpenPopupEditHistory(false);
  };

  const validate = (
    fieldValues = {
      description,
    }
  ) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกเนื้อหา";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const formatBuddhistYear = (value, format = "DD/MM/YYYY") => {
    let momentDate = moment(value);
    var christianYear = momentDate.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();

    return momentDate
      .format(
        format
          .replace("YYYY", buddhishYear)
          .replace("YY", buddhishYear.substring(2, 4))
      )
      .replace(christianYear, buddhishYear);
  };

  return (
    <SidebarLayout title="Call Center">
      {Boolean(isOpenPopupTicketChangeStatus) && (
        <PopupSelectStatus
          open
          onClose={handleCloseTicketChangeStatus}
          ticketId={id}
          popupTicketStatusID={popupTicketStatusID}
        />
      )}
      {Boolean(isOpenPopupEditTicket) && (
        <PopupEditTicketCallCenter
          open
          onClose={handleClosePopupEditTicket}
          ticketId={id}
        />
      )}
      {Boolean(isOpenPopupEditHistory) && (
        <PopupTicketHistoryCallCenter
          open
          onClose={handleCloseEditHistory}
          ticketId={id}
        />
      )}

      <Div>
        <SubMenu
          menu={[
            { path: "/call-center/ticket/all", text: "รายการ" },
            { path: "/call-center/account", text: "รายชื่อ" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="header-ticket-detail">
              <div className="header-ticket-item left">
                <h1>{checkValue(ticketInfoById?.ticket_no)}</h1>
                <p>
                  วันและเวลาที่สร้าง{" "}
                  {formatBuddhistYear(checkValue(ticketInfoById?.ticket_date))}{" "}
                  , {checkValue(ticketInfoById?.ticket_time)} น.
                </p>
              </div>

              <div className="header-ticket-item">
                <Button
                  onClick={() => handleOpenEditHistory()}
                  outlined="true"
                  className="btn-history"
                >
                  ประวัติการแก้ไข
                </Button>
                <ButtonBlack
                  className="coe-sty-color"
                  onClick={() => handleChangeTicketStatus()}
                >
                  ปรับสถานะ
                </ButtonBlack>
              </div>
            </div>
          </div>
        </div>

        <div className="ticket-detail-container">
          {/* Detail and note */}
          <div
            className="ticket-detail-item"
            style={{ width: "100%", marginRight: "11px" }}
          >
            {/* Detail */}
            <div className="content-wrapper">
              <h1>รายละเอียด</h1>

              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className="front-member-detail">
                      เลขที่สมาชิก/เลขทะเบียนนิติบุคคล:{" "}
                    </td>
                    <td>{checkValue(ticketInfoById?.member_no)}</td>
                    <td className="front-member-detail">
                      เจ้าหน้าที่ผู้รับเรื่อง:{" "}
                    </td>
                    <td>{checkValue(ticketInfoById?.full_name_create_by)}</td>
                  </tr>
                  <tr>
                    <td className="front-member-detail">ชื่อ/ชื่อบริษัท: </td>
                    <td>{checkValue(ticketInfoById?.first_name)}</td>
                    <td className="front-member-detail">วันที่โทรเข้ามา: </td>
                    <td>
                      {formatBuddhistYear(
                        checkValue(ticketInfoById?.ticket_date)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="front-member-detail">นามสกุล : </td>
                    <td>{checkValue(ticketInfoById?.last_name)}</td>
                    <td className="front-member-detail">เวลาที่โทรเข้ามา : </td>
                    <td>{checkValue(ticketInfoById?.ticket_time)} น.</td>
                  </tr>
                  <tr>
                    <td className="front-member-detail">เบอร์โทร : </td>
                    <td>{checkValue(ticketInfoById?.phone_no)}</td>
                    <td className="front-member-detail">เวลาที่วางสาย : </td>
                    <td>{checkValue(ticketInfoById?.ticket_end_time)} น.</td>
                  </tr>
                  <tr>
                    <td className="front-member-detail"></td>
                    <td></td>
                    <td className="front-member-detail">ระยะเวลาที่คุย : </td>
                    <td>{checkValue(ticketInfoById?.time_call)} นาที</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <p className="space-text">
                <span>
                  <b>หัวข้อเรื่อง :</b>{" "}
                  {checkValue(ticketInfoById?.ticket_type)}
                </span>
                <span>
                  <Button outlined onClick={() => handleOpenPopupEditTicket()}>
                    แก้ไขข้อมูล
                    <EditIcon
                      className="
                 clickable"
                    />
                  </Button>
                </span>
              </p>
              <p>
                <b>เนื้อหา : </b>
                {checkValue(ticketInfoById?.description)}
              </p>
            </div>

            {/* note */}
            <div className="content-wrapper">
              <h1>บันทึก</h1>
              {ticketInfoById &&
                ticketInfoById.note_list.length > 0 &&
                ticketInfoById.note_list.map((r) => (
                  <div className="note-container">
                    <div className="user-detail-container">
                      <div className="user-detail-left">
                        <div className="user-detail-item">
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#eeeeee",
                              borderRadius: "50px",
                            }}
                          ></div>
                        </div>
                        <div className="user-detail-item">
                          <div>{checkValue(r?.full_name_create_by)}</div>
                          <div>Call Center</div>
                        </div>
                      </div>
                      <div className="user-detail-right">
                        <span>
                          {formatBuddhistYear(
                            checkValue(r?.created_at),
                            "DD/MM/YYYY HH:mm:ss"
                          )}{" "}
                          น.
                        </span>
                      </div>
                    </div>
                    <div className="note-detail-container">
                      {checkValue(r?.comment)}
                    </div>
                  </div>
                ))}

              <div className="note-form">
                <TextFieldArea
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    validate({ ["description"]: e.target.value });
                  }}
                  error={errors.description}
                  placeholder="ข้อความ"
                  maxRows={6}
                  minRows={6}
                />
              </div>
              <div className="btn-form">
                <ButtonBlack
                  className="coe-sty-color"
                  onClick={() => handleTicketNote()}
                >
                  บันทึก
                </ButtonBlack>
              </div>
            </div>
          </div>

          {/* Status */}
          <div className="ticket-detail-item">
            <div className="content-wrapper">
              <h1>สถานะ</h1>
              <div className="status-bar-container">
                <VerticalStepBar steps={steps} activeStep={currentStep} />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default TicketDetail;
