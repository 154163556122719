import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import { login } from "redux/actions/auth";
import UploadImage from "../UploadImage";

import { addMember } from "redux/actions/member";

import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
  selectMasterDataBank,
  selectMasterDataNationality,
} from "../../redux/selectors";

const STATUS = {
  loading: "loading",
  success: "success",
  error: "error",
};

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const PopupAddBusinessMember = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
}) => {
  const dispatch = useDispatch();
  const masterDataProvince = useSelector(selectMasterDataProvince);
  const masterDataDistrict = useSelector(selectMasterDataDistrict);
  const masterDataSubDistrict = useSelector(selectMasterDataSubDistrict);
  const masterDataBank = useSelector(selectMasterDataBank);
  const masterDataNationality = useSelector(selectMasterDataNationality);

  const [dataProvince, setDataProvince] = useState(masterDataProvince);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubdistrict, setDataSubDistrict] = useState([]);

  const [dataProvinceTax, setDataProvinceTax] = useState(masterDataProvince);
  const [dataDistrictTax, setDataDistrictTax] = useState([]);
  const [dataSubdistrictTax, setDataSubDistrictTax] = useState([]);

  const [dataProvinceDoc, setDataProvinceDoc] = useState(masterDataProvince);
  const [dataDistrictDoc, setDataDistrictDoc] = useState([]);
  const [dataSubdistrictDoc, setDataSubDistrictDoc] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");
  const [idCardNumber, setIdCardNumber] = useState("");

  const [address, setAddress] = useState("");
  const [road, setRoad] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subdistrict, setSubdistrict] = useState("");
  const [postcode, setPostcode] = useState("");

  const [taxAddress, setTaxAddress] = useState("");
  const [taxRoad, setTaxRoad] = useState("");
  const [taxProvince, setTaxProvince] = useState("");
  const [taxDistrict, setTaxDistrict] = useState("");
  const [taxSubdistrict, setTaxSubdistrict] = useState("");
  const [taxPostcode, setTaxPostcode] = useState("");

  const [documentAddress, setDocumentAddress] = useState("");
  const [documentRoad, setDocumentRoad] = useState("");
  const [documentProvince, setDocumentProvince] = useState("");
  const [documentDistrict, setDocumentDistrict] = useState("");
  const [documentSubdistrict, setDocumentSubdistrict] = useState("");
  const [documentPostcode, setDocumentPostcode] = useState("");

  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");

  const [idCardImgUrl, setIdCardImgUrl] = useState("");
  const [idBankAccountImgUrl, setIdBankAccountImgUrl] = useState("");

  const [errors, setErrors] = useState({});

  const handleProvinceChange = (value) => {
    console.log(value);
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id === +value);
    setDataDistrict(districts);
    setProvince(value);
    validate({ province: value });
  };
  const handleDistrictChange = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id === +value);
    setDataSubDistrict(subDistricts);
    setDistrict(value);
    validate({ district: value });
  };
  const handleSubDistrictChange = (value) => {
    setSubdistrict(value);
    handlePostcodeChange(value);
    validate({ subdistrict: value });
  };

  const handlePostcodeChange = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value === +value)
      .map((z) => z.postcode)[0];
    setPostcode(postcode);
    validate({ postcode: value });
  };

  const handleProvinceChangeTax = (value) => {
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id === +value);
    setDataDistrictTax(districts);
    setTaxProvince(value);
    validate({ taxProvince: value });
  };
  const handleDistrictChangeTax = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id === +value);
    setDataSubDistrictTax(subDistricts);
    setTaxDistrict(value);
    validate({ taxDistrict: value });
  };
  const handleSubDistrictChangeTax = (value) => {
    setTaxSubdistrict(value);
    handlePostcodeChangeTax(value);
    validate({ taxSubdistrict: value });
  };

  const handlePostcodeChangeTax = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value === +value)
      .map((z) => z.postcode)[0];
    setTaxPostcode(postcode);
    validate({ taxPostcode: value });
  };

  const handleProvinceChangeDoc = (value) => {
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id === +value);
    setDataDistrictDoc(districts);
    setDocumentProvince(value);
    validate({ documentProvince: value });
  };
  const handleDistrictChangeDoc = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id === +value);
    setDataSubDistrictDoc(subDistricts);
    setDocumentDistrict(value);
    validate({ documentDistrict: value });
  };
  const handleSubDistrictChangeDoc = (value) => {
    setDocumentSubdistrict(value);
    handlePostcodeChangeDoc(value);
    validate({ documentSubdistrict: value });
  };

  const handlePostcodeChangeDoc = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value === +value)
      .map((z) => z.postcode)[0];
    setDocumentPostcode(postcode);
    validate({ documentPostcode: value });
  };

  const submitAddMember = () => {
    if (validate()) {
      const obj = {
        id_card: idCardNumber,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        nationality: nationality || "ไทย",
        member_type: 2,
        address: [
          {
            address_type: 1,
            address: address,
            road: road,
            province_id: province,
            district_id: district,
            sub_district_id: subdistrict,
            zip_code: postcode,
          },
          {
            address_type: 2,
            address: taxAddress,
            road: taxRoad,
            province_id: taxProvince,
            district_id: taxDistrict,
            sub_district_id: taxSubdistrict,
            zip_code: taxPostcode,
          },
          {
            address_type: 3,
            address: documentAddress,
            road: taxRoad,
            province_id: documentProvince,
            district_id: documentDistrict,
            sub_district_id: documentSubdistrict,
            zip_code: documentPostcode,
          },
        ],
        id_card_img_url: "",
        bank_account_img_url: "",
        bank_account: bankName,
        bank_account_no: bankAccountNumber,
        bank_account_name: bankAccountName,
        memberType: "Business",
        id_card_img_url: idCardImgUrl,
        bank_account_img_url: idBankAccountImgUrl,
      };
      dispatch(addMember(obj));
      onClose();
    }
  };

  const validate = (
    fieldValues = {
      firstName,
      lastName,
      email,
      phone,
      // nationality,
      idCardNumber,
      address,
      road,
      province,
      district,
      subdistrict,
      postcode,
      taxAddress,
      taxRoad,
      taxProvince,
      taxDistrict,
      taxSubdistrict,
      taxPostcode,
      documentAddress,
      documentRoad,
      documentProvince,
      documentDistrict,
      documentSubdistrict,
      documentPostcode,
      bankName,
      bankAccountNumber,
      bankAccountName,
    }
  ) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName !== "" ? "" : "กรุณากรอกชื่อ";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName !== "" ? "" : "กรุณากรอกนามสกุล";
    if ("email" in fieldValues)
      temp.email = fieldValues.email !== "" ? "" : "กรุณากรอก Email";
    if ("phone" in fieldValues)
      temp.phone = fieldValues.phone !== "" ? "" : "กรุณากรอกเบอร์โทร";
    // if ("nationality" in fieldValues)
    //   temp.nationality =
    //     fieldValues.nationality !== "" ? "" : "กรุณาเลือกสัญชาติ";
    if ("idCardNumber" in fieldValues)
      temp.idCardNumber =
        fieldValues.idCardNumber !== "" ? "" : "กรุณากรอกเลขบัตรประชาชน";
    if ("address" in fieldValues)
      temp.address =
        fieldValues.address !== "" ? "" : "กรุณากรอกที่อยู่ปัจจุบัน";
    if ("road" in fieldValues)
      temp.road = fieldValues.road !== "" ? "" : "กรุณาเลือกถนน";
    if ("province" in fieldValues)
      temp.province = fieldValues.province !== "" ? "" : "กรุณาเลือกจังหวัด";
    if ("district" in fieldValues)
      temp.district = fieldValues.district !== "" ? "" : "กรุณาเลือกอำเภอ";
    if ("subdistrict" in fieldValues)
      temp.subdistrict = fieldValues.subdistrict !== "" ? "" : "กรุณาเลือกตำบล";
    if ("postcode" in fieldValues)
      temp.postcode =
        fieldValues.postcode !== "" ? "" : "กรุณากรอกรหัสไปรษณีย์";
    if ("taxAddress" in fieldValues)
      temp.taxAddress =
        fieldValues.taxAddress !== "" ? "" : "กรุณากรอกที่อยู่ใบกำกับภาษี";
    if ("taxRoad" in fieldValues)
      temp.taxRoad = fieldValues.taxRoad !== "" ? "" : "กรุณากรอกถนน";
    if ("taxProvince" in fieldValues)
      temp.taxProvince =
        fieldValues.taxProvince !== "" ? "" : "กรุณาเลือกจังหวัด";
    if ("taxDistrict" in fieldValues)
      temp.taxDistrict =
        fieldValues.taxDistrict !== "" ? "" : "กรุณาเลือกอำเภอ";
    if ("taxSubdistrict" in fieldValues)
      temp.taxSubdistrict =
        fieldValues.taxSubdistrict !== "" ? "" : "กรุณาเลือกตำบล";
    if ("taxPostcode" in fieldValues)
      temp.taxPostcode =
        fieldValues.taxPostcode !== "" ? "" : "กรุณากรอกรหัสไปรษณีย์";
    if ("documentAddress" in fieldValues)
      temp.documentAddress =
        fieldValues.documentAddress !== "" ? "" : "กรุณากรอกที่อยู่ส่งเอกสาร";
    if ("documentRoad" in fieldValues)
      temp.documentRoad = fieldValues.documentRoad !== "" ? "" : "กรุณากรอกถนน";
    if ("documentProvince" in fieldValues)
      temp.documentProvince =
        fieldValues.documentProvince !== "" ? "" : "กรุณาเลือกจังหวัด";
    if ("documentDistrict" in fieldValues)
      temp.documentDistrict =
        fieldValues.documentDistrict !== "" ? "" : "กรุณาเลือกอำเภอ";
    if ("documentSubdistrict" in fieldValues)
      temp.documentSubdistrict =
        fieldValues.documentSubdistrict !== "" ? "" : "กรุณาเลือกตำบล";
    if ("documentPostcode" in fieldValues)
      temp.documentPostcode =
        fieldValues.documentPostcode !== "" ? "" : "กรุณากรอกรหัสไปรษณีย์";
    if ("bankName" in fieldValues)
      temp.bankName = fieldValues.bankName !== "" ? "" : "กรุณาเลือกธนาคาร";
    if ("bankAccountNumber" in fieldValues)
      temp.bankAccountNumber =
        fieldValues.bankAccountNumber !== ""
          ? ""
          : "กรุณากรอกเลขที่บัญชีธนาคาร";
    if ("bankAccountName" in fieldValues)
      temp.bankAccountName =
        fieldValues.bankAccountName !== "" ? "" : "กรุณากรอกชื่อบัญชี";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Add Business Member</div>

          <p className="form-topic">ข้อมูล</p>
          <InlineSpaceText label="ชื่อจริง">
            <TextField
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                validate({ ["firstName"]: e.target.value });
              }}
              className="field-firstName"
              error={errors.firstName}
            />
          </InlineSpaceText>
          <InlineSpaceText label="นามสกุล">
            <TextField
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                validate({ ["lastName"]: e.target.value });
              }}
              className="field-lastName"
              error={errors.lastName}
            />
          </InlineSpaceText>
          <InlineSpaceText label="Email">
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validate({ ["email"]: e.target.value });
              }}
              className="field-email"
              error={errors.email}
            />
          </InlineSpaceText>
          <InlineSpaceText label="เบอร์โทร">
            <TextField
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                validate({ ["phone"]: e.target.value });
              }}
              className="field-phone"
              error={errors.phone}
            />
          </InlineSpaceText>
          <InlineSpaceText label="สัญชาติ">
            <Select
              items={masterDataNationality}
              value={nationality}
              onChange={(e) => {
                setNationality(e.target.value);
                validate({ ["nationality"]: e.target.value });
              }}
              defaultValue="เลือกสัญชาติ"
              className="field-nationality"
              id="field-nationality"
              errorText={errors.nationality}
              isError={errors.nationality}
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสบัตรประชาชน">
            <TextField
              value={idCardNumber}
              onChange={(e) => {
                setIdCardNumber(e.target.value);
                validate({ ["idCardNumber"]: e.target.value });
              }}
              className="field-idCardNumber"
              error={errors.idCardNumber}
            />
          </InlineSpaceText>

          <p className="form-topic">ที่อยู่</p>
          <InlineSpaceText label="ที่อยู่ปัจุบัน">
            <TextField
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                validate({ ["address"]: e.target.value });
              }}
              className="field-address"
              id="address-address"
              error={errors.address}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ถนน">
            <TextField
              value={road}
              onChange={(e) => {
                setRoad(e.target.value);
                validate({ ["road"]: e.target.value });
              }}
              className="field-road"
              id="address-road"
              error={errors.road}
            />
          </InlineSpaceText>
          <InlineSpaceText label="จังหวัด">
            <Select
              items={dataProvince}
              value={province}
              onChange={(e) => handleProvinceChange(e.target.value)}
              defaultValue="เลือกจังหวัด"
              className="field-province"
              id="address-province"
              errorText={errors.province}
              isError={errors.province}
            />
          </InlineSpaceText>
          <InlineSpaceText label="อำเภอ">
            <Select
              items={dataDistrict}
              value={district}
              onChange={(e) => handleDistrictChange(e.target.value)}
              defaultValue="เลือกอำเภอ"
              className="field-district"
              id="address-district"
              errorText={errors.district}
              isError={errors.district}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ตำบล">
            <Select
              items={dataSubdistrict}
              value={subdistrict}
              onChange={(e) => handleSubDistrictChange(e.target.value)}
              defaultValue="เลือกตำบล"
              className="field-subdistrict"
              id="address-subdistrict"
              errorText={errors.subdistrict}
              isError={errors.subdistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสไปรษณีย์">
            <TextField
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
                validate({ ["postcode"]: e.target.value });
              }}
              className="field-postcode"
              id="address-postcode"
              error={errors.postcode}
            />
          </InlineSpaceText>

          <p className="form-topic">ที่อยู่ในการออกใบกำกับภาษี</p>
          <InlineSpaceText label="ที่อยู่ใบกำกับภาษี">
            <TextField
              value={taxAddress}
              onChange={(e) => {
                setTaxAddress(e.target.value);
                validate({ ["taxAddress"]: e.target.value });
              }}
              className="field-taxaAddress"
              id="address-taxaAddress"
              error={errors.taxAddress}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ถนน">
            <TextField
              value={taxRoad}
              onChange={(e) => {
                setTaxRoad(e.target.value);
                validate({ ["taxRoad"]: e.target.value });
              }}
              className="field-taxRoad"
              id="address-taxRoad"
              error={errors.taxRoad}
            />
          </InlineSpaceText>
          <InlineSpaceText label="จังหวัด">
            <Select
              items={dataProvinceTax}
              value={taxProvince}
              onChange={(e) => handleProvinceChangeTax(e.target.value)}
              defaultValue="เลือกจังหวัด"
              className="field-taxProvince"
              id="address-taxProvince"
              errorText={errors.taxProvince}
              isError={errors.taxProvince}
            />
          </InlineSpaceText>
          <InlineSpaceText label="อำเภอ">
            <Select
              items={dataDistrictTax}
              value={taxDistrict}
              onChange={(e) => handleDistrictChangeTax(e.target.value)}
              defaultValue="เลือกอำเภอ"
              className="field-taxDistrict"
              id="address-taxDistrict"
              errorText={errors.taxDistrict}
              isError={errors.taxDistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ตำบล">
            <Select
              items={dataSubdistrictTax}
              value={taxSubdistrict}
              onChange={(e) => handleSubDistrictChangeTax(e.target.value)}
              defaultValue="เลือกตำบล"
              className="field-taxSubdistrict"
              id="address-taxSubdistrict"
              errorText={errors.taxSubdistrict}
              isError={errors.taxSubdistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสไปรษณีย์">
            <TextField
              value={taxPostcode}
              className="field-taxPostcode"
              id="address-taxPostcode"
              error={errors.taxPostcode}
              onChange={(e) => {
                setTaxPostcode(e.target.value);
                validate({ ["taxPostcode"]: e.target.value });
              }}
            />
          </InlineSpaceText>

          <p className="form-topic">ที่อยู่สำหรับจัดส่งเอกสาร</p>
          <InlineSpaceText label="ที่อยู่ส่งเอกสาร">
            <TextField
              value={documentAddress}
              onChange={(e) => {
                setDocumentAddress(e.target.value);
                validate({ ["documentAddress"]: e.target.value });
              }}
              className="field-docAddress"
              id="address-docAddress"
              error={errors.documentAddress}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ถนน">
            <TextField
              value={documentRoad}
              onChange={(e) => {
                setDocumentRoad(e.target.value);
                validate({ ["documentRoad"]: e.target.value });
              }}
              className="field-docRoad"
              id="address-docRoad"
              error={errors.documentRoad}
            />
          </InlineSpaceText>
          <InlineSpaceText label="จังหวัด">
            <Select
              items={dataProvinceDoc}
              value={documentProvince}
              onChange={(e) => handleProvinceChangeDoc(e.target.value)}
              defaultValue="เลือกจังหวัด"
              className="field-docProvince"
              id="address-docProvince"
              errorText={errors.documentProvince}
              isError={errors.documentProvince}
            />
          </InlineSpaceText>
          <InlineSpaceText label="อำเภอ">
            <Select
              items={dataDistrictDoc}
              value={documentDistrict}
              onChange={(e) => handleDistrictChangeDoc(e.target.value)}
              defaultValue="เลือกอำเภอ"
              className="field-docDistrict"
              id="address-docDistrict"
              errorText={errors.documentDistrict}
              isError={errors.documentDistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ตำบล">
            <Select
              items={dataSubdistrictDoc}
              value={documentSubdistrict}
              onChange={(e) => handleSubDistrictChangeDoc(e.target.value)}
              defaultValue="เลือกตำบล"
              className="field-docSubdistrict"
              id="address-docSubdistrict"
              errorText={errors.documentSubdistrict}
              isError={errors.documentSubdistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสไปรษณีย์">
            <TextField
              value={documentPostcode}
              className="field-docPostcode"
              id="address-docPostcode"
              error={errors.documentPostcode}
              onChange={(e) => {
                setDocumentPostcode(e.target.value);
                validate({ ["documentPostcode"]: e.target.value });
              }}
            />
          </InlineSpaceText>

          <p className="form-topic">ธนาคาร</p>
          <InlineSpaceText label="ชื่อธนาคาร">
            <Select
              items={masterDataBank}
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
                validate({ ["bankName"]: e.target.value });
              }}
              className="field-bankName"
              id="field-bankName"
              defaultValue="เลือกธนาคาร"
              errorText={errors.bankName}
              isError={errors.bankName}
            />
          </InlineSpaceText>
          <InlineSpaceText label="เลขที่บัญชีธนาคาร">
            <TextField
              value={bankAccountNumber}
              onChange={(e) => {
                setBankAccountNumber(e.target.value);
                validate({ ["bankAccountNumber"]: e.target.value });
              }}
              className="field-bankAccountNumber"
              error={errors.bankAccountNumber}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ชื่อบัญชี">
            <TextField
              value={bankAccountName}
              onChange={(e) => {
                setBankAccountName(e.target.value);
                validate({ ["bankAccountName"]: e.target.value });
              }}
              className="field-bankAccountName"
              error={errors.bankAccountName}
            />
          </InlineSpaceText>
          <UploadImage
            label={"อัพโหลดรูปบัตรประชาชน"}
            inputID={"id_card_img_url"}
            setURLImage={setIdCardImgUrl}
          />
          <UploadImage
            inputID={"bank_account_img_url"}
            label={"อัพโหลดรูปสมุดบัญชีธนาคาร"}
            setURLImage={setIdBankAccountImgUrl}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitAddMember()}>
            Add
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupAddBusinessMember;
