import React from "react";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";

import exampleEmail from "../../assets/images/example-email.PNG";
import exampleSms from "../../assets/images/example-sms.jpg";

const Div = styled.div`
  padding: 20px;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 20px;

    .button-toggle-panel {
      display: flex;
      column-gap: 10px;
      align-items: center;

      > button {
        min-width: 150px;
      }
      &.end {
        justify-content: flex-end;
      }
    }
    .filter-panel {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
      .row {
        display: flex;
        column-gap: 10px;
        align-items: flex-end;
        .select {
          width: 20%;
        }
        > button {
          min-width: 150px;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .example-notification-content {
    text-align: center;

    img {
      width: 100%;
      height: auto;
    }

    .email-content {
      margin: auto;
      width: 800px;

      .header-content {
        width: 100%;
        text-align: center;

        img {
          margin: 0 auto;
          margin-top: 18px;
          width: 130px;
        }

        .st1 {
          margin: 18px auto;
        }

        .st2 {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
        }

        .st3 {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
        }

        .st4 {
          margin: 18px auto;

          .st4_1 {
            font-size: 16px;
            text-align: center;
            color: #3f3f42;
          }
          .st4_2 {
            font-size: 1rem;
            text-align: center;
            color: #3f3f42;
          }
          .st4_3 {
            display: inline-block;
            margin: 30px 0;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            background-color: #a81e21;
            cursor: pointer;
            text-decoration: none;
          }
          .st4_4 {
            margin: 0;
            font-size: 18px;
            text-align: center;
            color: red;
          }
        }
      }

      .st5 {
        color: #000;
        border: 0;
        border-bottom: 2px solid #000;
        margin: 20px 0;
      }

      .st6 {
        padding: 10px;
        background-color: #a81e21;
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }

      .st7 {
        padding-bottom: 10px;
        background-color: #a81e21;
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }
`;

const PopupExampleNotification = ({ open, onClose, channel, form }) => {
  return (
    <BaseModal open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Div>
        <div className="example-notification-content">
          <h1>
            {channel === "email"
              ? "ตัวอย่างการส่ง Email"
              : "ตัวอย่างการส่ง SMS"}
          </h1>
          {channel === "email" ? (
            // <img src={exampleEmail} />
            <div className="email-content">
              <div className="header-content">
                <img src="https://uatcoeapi.codenation.me/assets/images/coe.png" />
                <div className="st1">
                  <h1 className="st2">ระบบแจ้งเตือนการทำรายการ</h1>
                  <p className="st3">ระบบบริการสมาชิก COE Services</p>
                </div>
                <div className="st4">
                  <p className="st4_1">สมชาย ประเสริฐ</p>
                  <p className="st4_1">{form.name}</p>
                  <br />
                  <p className="st4_2">
                    {form.message === "" ? (
                      <>
                        {form.name} ใบอนุญาตเลขที่ ภย.12345
                        ของท่านกำลังจะหมดอายุในวันที่ 03/02/2565 <br />
                        กรุณาต่ออายุก่อนหมดอายุ เพื่อป้องกันใบอนุญาตขาดสภาพ{" "}
                        <br />
                        หากต่ออายุใบอนุญาตหลังวันที่หมดอายุ
                        จะมีค่าธรรมเนียมเพิ่มเติม
                      </>
                    ) : (
                      <>{form.message}</>
                    )}
                  </p>

                  <a className="st4_3">ต่ออายุใบอนุญาต</a>
                  <p className="st4_4">
                    * ระบบนี้เป็นระบบแจ้งเตือนอัตโนมัติ กรุณาอย่าตอบกลับ <br />
                    หากมีคำถามและข้อสงสัยเพิ่มเติม ติดต่อเจ้าหน้าที่สภาวิศวกร
                    Call Center 1303
                  </p>
                </div>
              </div>
              <hr className="st5" />
              <div className="st6">
                สภาวิศวกร 1616/1 ถนนลาดพร้าว แขวงวังทองหลาง เขตวังทองหลาง
                กรุงเทพมหานคร 10310
              </div>
              <div className="st7">
                © 2022 Copy Right : The Councile of Engineers Thailand
              </div>
            </div>
          ) : (
            // <img src={exampleSms} />
            <div>
              {form.message === "" ? (
                <span>
                  ใบอนุญาตเลขที่ ภย.12345 ของท่านกำลังจะหมดอายุในวันที่
                  03/02/2565 กรุณาต่ออายุก่อนหมดอายุ
                  หากเกินกำหนดจะมีค่าธรรมเนียมเพิ่มเติม
                </span>
              ) : (
                <span>{form.message}</span>
              )}
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Close
          </Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupExampleNotification;
