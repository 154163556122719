import React from "react";
import styled from "styled-components";
import { NativeSelect } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const Div = styled.div`
  display: inline-flex;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
  > label {
    font-size: 16px;
    margin-bottom: 13px;
  }
`;

const StyledSelect = styled(NativeSelect)`
  .MuiNativeSelect-root {
    border: 1px solid #e8ecef;
    background: #fff;
    border-radius: 4px;
    font-size: 16px;
    color: #080808;
    padding: 13px 46px 13px 16px;
    width: 100%;
  }

  .MuiSvgIcon-root {
    right: 14px;
    color: #b6b8c1;
  }

  .Mui-disabled {
    background-color: #eeeeee;
  }
  &:before,
  &:after {
    visibility: hidden;
  }
`;

const Select = ({
  label,
  items = [],
  defaultValue,
  errorText = "",
  isError = null,
  ...rest
}) => {
  return (
    <Div>
      {Boolean(label) && <label>{label}</label>}

      <FormControl error={isError}>
        <StyledSelect
          style={
            isError ? { border: "1px solid red", borderRadius: "4px" } : {}
          }
          IconComponent={KeyboardArrowDownIcon}
          {...rest}
        >
          <option value="" disabled>
            {defaultValue}
          </option>
          {items.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </StyledSelect>
      </FormControl>
      {isError && (
        <FormHelperText style={{ color: "red" }}>{errorText}</FormHelperText>
      )}
    </Div>
  );
};

export default Select;
